import React from "react";
import { Link } from "react-router-dom";

import OfficeSpaces from "../featureshome/OfficeSpaces";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import PublicSpaces from "../featureshome/PublicSpaces";
import ResidentialSpaces from "../featureshome/ResidentialSpaces";
import CommercialSpaces from "../featureshome/CommercialSpaces";
const AddedToday = () => {
  return (
    <>
      <section className="all-section">
        <Container>
          <Tab.Container defaultActiveKey="OfficeSpaces">
            <Row>
              <div className="property-home title-head-main">
                <div className="pro-d-flex">
                  <div>
                    <h2 className="text-head-title">Added today</h2>
                  </div>
                  <div className="tabs-nav">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="OfficeSpaces">
                          Office Spaces
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="PublicSpaces">
                          Public Spaces
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="ResidentialSpaces">
                          Residential Spaces
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="CommercialSpaces">
                          Commercial Spaces
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <Link to="#" className="btn btn-primary">
                  View all
                  <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                </Link>
              </div>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="OfficeSpaces">
                <OfficeSpaces />
              </Tab.Pane>
              <Tab.Pane eventKey="PublicSpaces">
                <PublicSpaces />
              </Tab.Pane>
              <Tab.Pane eventKey="ResidentialSpaces">
                <ResidentialSpaces />
              </Tab.Pane>
              <Tab.Pane eventKey="CommercialSpaces">
                <CommercialSpaces />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default AddedToday;
