// src/utils/api.js
import axios from "axios";
// import { showToast } from "../utils/toast";

export const apiCall = async (url, method, data = null, token) => {
  try {
    const config = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (data) {
      config.data = data;
    }

    const response = await axios(config);
    if (response.data.statusType === "SUCCESS") {
      return response.data;
    } else {
      console.log("some error occured");
      //   showToast(`Failed to ${method} data`, { type: "error" });
    }
  } catch (error) {
    console.error(`Error during ${method} request:`, error);
    // showToast(`Failed to ${method} data`, { type: "error" });
  }
};
