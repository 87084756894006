import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Select from "react-select";
import Tr from "../../components/tr/Tr";
const PropertySubType = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const [selectedCountry, setSelectedCity] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [userIdCreatedBy, setUserIdCreatedBy] = useState();
  console.log(selectedState);
  const cityApi = Api({
    url: `property/sub/types/${selectedPropertyType?.value}`,
  });
  const propertyTypeApi = Api({
    url: `property/types`,
  });
  const deleteQueryApi = Api({
    url: `property/sub/type/delete`,
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/property/sub/type/delete/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Sub Type deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const [statedata, setStateData] = useState([]);
  const [propertyTypeData, setPropertyTypeData] = useState([]);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Name", key: "name" },
  ];
  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const responseData = await propertyTypeApi.fetchData();
        console.log("your state data is", responseData);

        setData(responseData.data.propertyTypes);
        setTotalPages(responseData.data.totalPage);
        const propertyTypeData = responseData.data.propertyTypes.map(
          (propertyType) => ({
            label: propertyType.name,
            value: propertyType.id,
          })
        );

        setPropertyTypeData(propertyTypeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStateData();
  }, []);
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      const responseData = await cityApi.fetchData(pageNumber);

      setData(responseData.data.propertySubTypes);
      setTotalPages(responseData.data.totalPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPropertyData(currentPage);
  }, [selectedPropertyType, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Cities", link: "/city", active: true },
  ];
  return (
    <>
      <Notification />
      <div className="content-body">
        <Container>
          {/* Delete Alert */}

          <BreadCrumb items={breadcrumbItems} />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>

            <Col lg={9} md={8} sm={12} style={{ height: "100vh" }}>
              <Container>
                <Row>
                  <Col lg={9} md={8} sm={12}>
                    <div className="mb-3">
                      <label htmlFor="wish" className="form-label">
                        Select Property Type
                      </label>
                      <Select
                        options={propertyTypeData}
                        onChange={setSelectedPropertyType}
                        value={selectedPropertyType}
                        placeholder="Select Property Type"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 999999999,
                          }),
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
              {selectedPropertyType === null ? (
                <Col>
                  <Container>
                    <p>Please Select The Type To Show The Sub Type...</p>
                  </Container>
                </Col>
              ) : (
                <>
                  {data?.length === 0 ? (
                    <>
                      <Tr
                        columns={columns}
                        initialValues={initialValues}
                        title="No Sub Type Found"
                        buttonName="Create"
                        btnLink="/property-subtype/add-propertysubtype"
                        titleHeader="Property Sub Types"
                      />
                      {/* <Row>
                        <Col>
                     
                          <AllMessage title="No City Found" />
                        </Col>
                      </Row>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Link to={"/city/add-city"} className="btn btn-primary">
                          Add City
                        </Link>
                      </Col> */}
                    </>
                  ) : (
                    <div className="mb-5">
                      <List
                        title="Property Sub Type"
                        data={data}
                        columns={columns}
                        button="Create"
                        route={`property-subtype`}
                        to="add-propertysubtype"
                        handleDelete={handleDelete}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PropertySubType;
