import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Alert,
  Badge,
  Dropdown,
  DropdownButton,
  Card,
  Carousel,
} from "react-bootstrap";
import AdminHeader from "../../common/Header";
import Button from "../../../components/common/button/Button";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import List from "../../components/listing/List";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import { FaEllipsisV, FaComment, FaCheckCircle } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";
import parse from "html-react-parser";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Tr from "../../components/tr/Tr";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./PropertyList.css";
import PropertySingleCard from "../../components/Property/PropertySingleCard";
import Pagination from "../../components/pagination/Pagination";
const PropertyListingForm = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  let endpoint;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [assetUrl, setAssetUrl] = useState("");

  const endPointFinalize = () => {
    if (userType === "SUPER_ADMIN" || userType === "ADMIN") {
      return `${baseUrl}/property/list`;
    } else {
      return `${baseUrl}/property/listByUser/${userId}`;
    }
  };

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/property/deleteProperty/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Property deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Property Name", key: "name" },
    { displayName: "Location", key: "address" },
  ];
  const fetchPropertyData = async (pageNumber, pageSize = 10) => {
    const fetchData = async () => {
      try {
        endpoint = endPointFinalize();
        const response = await axios.post(
          endpoint,
          {
            page_number: pageNumber,
            page_size: pageSize,
            order_by: "ASC",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.properties);
        setData(response.data.properties);
        setAssetUrl(response?.data?.assetUrl);
        setTotalPages(response.data.totalPage);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };
  useEffect(() => {
    endPointFinalize();

    fetchPropertyData(currentPage);
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleHideData = async (item) => {
    console.log("hello from handle publish");
    console.log(item);
    try {
      const response = await axios.put(
        `${baseUrl}/property/publish/status`,
        {
          lead_id: item.lead_id,
          is_publish: !item.published,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.statusType === "SUCCESS") {
        showToast("Property Updated successfully", {
          type: "success",
        });
      }
      console.log("your response for updating status", response);
    } catch (error) {
      console.error("Error 'updating ' item:", error);
    }
  };
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Properties", link: "/property", active: true },
  ];
  return (
    <>
      <Notification />
      <div className="bread-crumb">
        <Container>
          <Col md={8}>
            <BreadCrumb items={breadcrumbItems} />
          </Col>
        </Container>
      </div>
      <Container>
        <div className="">
          {/* Delete Alert */}
          {/* <BreadCrumb data="Properties" /> */}

          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            {/* <Col md={2}>
              <AdminHeader />
            </Col> */}
            {/* <Col md={12}>
              <BreadCrumb items={breadcrumbItems} />
            </Col> */}
            <Col md={12} className="mb-5">
              {data?.length === 0 ? (
                <>
                  <Tr
                    columns={columns}
                    initialValues={initialValues}
                    title="No Property Found"
                    buttonName="Create"
                    btnLink="/property/add-property"
                    titleHeader="Properties"
                    btn={true}
                  />
                </>
              ) : (
                <>
                  {/* <List
                    title="Properties"
                    data={data}
                    columns={columns}
                    button="Create"
                    to="/add-property"
                    route="property"
                    handleDelete={handleDelete}
                    handleHide={handleHideData}
                  /> */}
                  <Row className="mt-3">
                    {/* <Row> */}
                    <Col md={12}>
                      <Row>
                        <Col md={10}>
                          <h2>Properties</h2>
                        </Col>
                        <Col md={2} className="text-lg-end">
                          {/* {console.log(userType)} */}
                          {/* {userType === "SUPER_ADMIN" ||
                          userType === "ALL_HOME_DESIRE" ? (
                            <></>
                          ) : (
                            <Link
                              className="btn btn-primary"
                              to={"/post/add-post"}
                            >
                              Create
                            </Link>
                          )} */}
                          <Link
                            className="btn btn-primary"
                            to={"/property/add-property"}
                          >
                            Create
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                    {/* </Row> */}

                    {/* {data.map((singleData) => (
                      <Col lg={6} md={12} sm={12}>
                        <Card className="property-card">
                          <Card.Header className="d-flex justify-content-start align-items-center">
                            {singleData?.purpose?.name === "Buy" ? (
                              <Badge bg="success" style={{ fontSize: "14px" }}>
                                {singleData?.purpose?.name}
                              </Badge>
                            ) : singleData?.purpose?.name === "Sell" ? (
                              <Badge bg="danger" style={{ fontSize: "14px" }}>
                                {singleData?.purpose?.name}
                              </Badge>
                            ) : singleData?.purpose?.name === "Rent" ? (
                              <Badge
                                bg="secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {singleData?.purpose?.name}
                              </Badge>
                            ) : singleData?.purpose?.name === "Investment" ? (
                              <Badge bg="primary" style={{ fontSize: "14px" }}>
                                {singleData?.purpose?.name}
                              </Badge>
                            ) : (
                              <></>
                            )}
                            <Badge
                              bg="success"
                              style={{
                                fontSize: "14px",
                                marginLeft: "1rem",
                              }}
                            >
                              {" "}
                              {singleData?.type?.name}
                            </Badge>
                            <Badge
                              bg="danger"
                              style={{ fontSize: "14px", marginLeft: "1rem" }}
                            >
                              {" "}
                              {singleData?.status?.name}
                            </Badge>
                          </Card.Header>
                          <Card.Body className="d-flex cardBody">
                            <Carousel className="property-image">
                              {singleData?.images?.map((singleImage) => (
                                <Carousel.Item>
                                  <img
                                    className="d-block w-100 carousel-img"
                                    src={`${assetUrl}${singleImage.image_url}`}
                                    style={{
                                      height: "15rem",
                                      borderRadius: "0.35rem",
                                    }}
                                    alt="Property 1"
                                  />
                                </Carousel.Item>
                              ))}
                            </Carousel>
                            <div className="property-details flex-grow-1 ms-3">
                              <div className="property-location">
                                <div className="badge-like ">
                                  <span className="location-label">State:</span>
                                  <span className="location-value">
                                    {singleData?.area?.city?.state?.name}
                                  </span>
                                </div>
                                <div className="badge-like middleBadge">
                                  <span className="location-label">City:</span>
                                  <span className="location-value">
                                    {singleData?.area?.city?.name}
                                  </span>
                                </div>
                                <div className="badge-like">
                                  <span className="location-label">Area:</span>
                                  <span className="location-value">
                                    {singleData?.area?.name}
                                  </span>
                                </div>
                              </div>
                              <h4 className="property-title">
                                {singleData?.name}
                              </h4>
                              <p className="property-description">
                                {singleData?.description.length > 100 ? (
                                  <>
                                    {" "}
                                    {parse(
                                      `${singleData?.description?.substring(
                                        0,
                                        200
                                      )}
                                  
                                  `
                                    )}
                                  </>
                                ) : (
                                  parse(singleData?.description)
                                )}
                                {singleData?.description.length > 100 && (
                                  <Link
                                    variant="link"
                                    className="p-0 text-primary fw-bold"
                                    to={`/property/${singleData?.id}`}
                                  >
                                    More
                                  </Link>
                                )}
                              </p>
                            </div>
                            <div className="property-actions">
                              {(singleData?.user?.id === userId ||
                                userType === "SUPER_ADMIN") && (
                                <>
                                  <Link
                                    variant="outline-primary"
                                    className="btn btn-primary mb-2"
                                    to={`/property/${singleData?.id}`}
                                  >
                                    <i
                                      className="fa fa-eye pr-0"
                                      aria-hidden="true"
                                      style={{ paddingRight: "0rem" }}
                                    ></i>
                                  </Link>
                                </>
                              )}

                              <Link
                                variant="outline-secondary"
                                className="btn btn-warning mb-2"
                                to={`/property/${singleData?.id}/edit`}
                              >
                                <i
                                  className="fa fa-edit"
                                  style={{ paddingRight: "0rem" }}
                                ></i>
                              </Link>
                              <Link
                                variant="outline-success"
                                className="btn btn-success"
                              >
                                <i class="fa-solid fa-user-plus"></i>
                              </Link>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))} */}
                    <PropertySingleCard
                      data={data}
                      assetUrl={assetUrl}
                      // handleHideData={handleHideData}
                      fetchPropertyData={fetchPropertyData}
                      // handleDelete={() => handleDeleteAlertShow()}
                    />
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
        <div className="mt-5">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
    </>
  );
};

export default PropertyListingForm;
