import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import axios from "axios";
import AdminHeader from "../../common/Header";
const PropertySubTypeItem = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const navigate = useNavigate();
  const { propertySubTypeId } = useParams(); // Assuming you're passing postId as a parameter
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedUserType, setSetelectedUserType] = useState("");
  const getUserTypeApi = Api({
    url: `admin/city/id/${propertySubTypeId}`,
  });
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        // const response = await getUserTypeApi.fetchData();
        // console.log(response);
        const response = await axios.get(
          `${baseUrl}/property/sub/type/${propertySubTypeId}`,

          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response?.data?.statusType === "SUCCESS") {
          console.log(response);
          const postData = response.data.propertySubType;
          console.log(postData);
          setFormData({
            name: postData.name,
            description: postData.description,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, [selectedUserType]);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Sub Types", link: "/property-subtype" },
    { label: "Property Sub Type", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Property Sub Type Details"
              description="property sub type  details section"
            />

            <Row>
              <Col>
                <div className="mb-3">
                  <SingleDetail label="Name" userDetail={formData?.name} />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <SingleDetail
                    label="Description"
                    userDetail={formData?.description}
                  />
                </div>
              </Col>
            </Row>
            <div>
              <Button className={`btn btn-primary mb-3 mt-3 ml-3`}>
                <Link
                  className="text-white"
                  to={`/property-subtype/${propertySubTypeId}/edit`}
                >
                  Edit
                </Link>
              </Button>
              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-3 mt-3 ml-3"
                to={"/property-subtype"}
              >
                Back
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PropertySubTypeItem;
