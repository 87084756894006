// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer, { loadAuthState } from "../features/auth/authSlice";
import formReducer from "../features/formData/formSlice";
import mySpaceReducer from "../features/myspace/myspaceSlice";
import partnerReducer from "../features/partnerRegistration/partnerSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    form: formReducer,
    partner: partnerReducer,
    mySpace: mySpaceReducer,
  },
});
