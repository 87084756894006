import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import AdminHeader from "../../common/Header";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/common/spinner/Spinner";
import Error from "../../../components/common/error/Error";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import FileUploadField from "../../../components/fileUpload/FIleUploadField";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../components/api/Api";
import secureLocalStorage from "react-secure-storage";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AddBasicPropertyForm from "./AddBasicPropertyForm";
import AddPropertyDetails from "./AddPropertyDetails";
import AddPropertyAmenities from "./AddPropertyAmenities";
import PreviewPropertyDetails from "./PreviewPropertyDetails";
import PropTypes from "prop-types";
import AddPropertyInformation from "./AddPropertyInformation";
import AddPropertyDetail from "./AddPropertyDetail";
import AddPropertyDescription from "./AddPropertyDescription";
import AddPropertyImageUpload from "./AddPropertyImageUpload";
import AddPropertyFileUpload from "./AddPropertyFileUpload";
import ProgressBar from "./PropertyProgressBar";
import {
  reset,
  resetForm,
  resetStep,
} from "../../../features/formData/formSlice";

const AddProperty = ({
  showThings,
  isPropertyFromDashboard,
  hideData,
  callSubmitFunc,
  isPost,
  isEditProperty,
  isEditPost,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.auth.user);
  const formData = useSelector((state) => state.form.formData);
  const currentStep = useSelector((state) => state.form.currentStep);
  const location = useLocation();
  const [formKey, setFormKey] = useState(Date.now());

  // const token = loggedInUser?.token;
  // const userId = loggedInUser?.userDetails?.id;

  // const [countries, setCountries] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState("");
  // const [propertyPurposes, setPropertyPurposes] = useState([]);
  // const [purpose, setPurpose] = useState("");
  // const [propertyTypes, setPropertyTypes] = useState([]);
  // const [statusList, setStatusList] = useState([]);
  // const [areaSuggestions, setAreaSuggestions] = useState([]);
  // const [areaInput, setAreaInput] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [isError, setIsError] = useState(false);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [selectedState, setSelectedState] = useState("");
  // const [textData, setTextData] = useState("Property Description");
  // const [isImageUploaded, setIsImageUploaded] = useState(false);
  // const [isAttachmentUploaded, setIsAttachmentUploaded] = useState(false);
  // const [uploadedImageNames, setUploadedImageNames] = useState([]);
  // const [uploadedFilesNames, setUploadedFilesNames] = useState([]);

  const initialValues = {
    type: "", // Default to Rent
    name: "",
    status: "",
    country: "",
    state: "",
    city: "",
    area: "",
    pin_code: "",
    featured_property: "",
    address: "",
    bed_rooms: "",
    bath_rooms: "",
    rooms: "",
    description: "",
    price: "",
    swimming_pool: false,
    gas_connection: false,
    ro: false,
    club_house: false,
    basketball_court: false,
    tennis_court: false,
    gym: false,
    indoor_games: false,
    child_play_area: false,
    hospital_near_by: false,
    mall_near_by: false,
    schoold_near_by: false,
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    pin_code: Yup.string().required("Pin Code is required"),
    address: Yup.string().required("Address is required"),
  });
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const lead_id = secureLocalStorage.getItem("leadId");

  const show = () => {
    showToast("Property added successfully", {
      type: "success",
    });
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Properties", link: "/property" },
    { label: "Add Property", link: "", active: true },
  ];
  const steps = [
    { title: "New" },
    { title: "Details" },

    { title: "Medias" },

    { title: "Features" },

    { title: "Preview " },
    // Add more steps if needed
  ];

  const hideSteps = [
    { title: "New" },
    { title: "Details" },

    { title: "Medias" },

    { title: "Preview " },
  ];
  console.log(isEditPost);
  return (
    <>
      <Notification />
      <Container className="mt-3">
        {showThings && (
          <>
            <BreadCrumb items={breadcrumbItems} />

            <h3>Add Property</h3>
          </>
        )}

        <Col md={12} className="mb-5 ProgressBar-tab">
          <div className="progressBar-card">
            {hideData ? (
              <>
                <ProgressBar currentStep={currentStep} steps={hideSteps} />
                <hr />
              </>
            ) : (
              <>
                <ProgressBar currentStep={currentStep} steps={steps} />
                <hr />
              </>
            )}

            <Col md={12}>
              <div className="card">
                {/* {currentStep === 2 && <AddPropertyDetails />} */}

                {/* {currentStep === 1 && <AddBasicPropertyForm />} */}

                {hideData ? (
                  <>
                    {currentStep === 1 && (
                      <AddPropertyInformation isPost={isPost} />
                    )}
                    {currentStep === 2 && <AddPropertyDetail />}
                    {/* {currentStep === 3 && <AddPropertyDescription />} */}
                    {currentStep === 3 && (
                      <AddPropertyImageUpload isPost={isPost} />
                    )}
                    {currentStep === 4 && (
                      <PreviewPropertyDetails
                        isPropertyFromDashboard={isPropertyFromDashboard}
                        callSubmitFunc={callSubmitFunc}
                        isPost={isPost}
                        isEditPost={isEditPost}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {currentStep === 1 && <AddPropertyInformation />}
                    {currentStep === 2 && <AddPropertyDetail />}
                    {/* {currentStep === 3 && <AddPropertyDescription />} */}
                    {currentStep === 3 && (
                      <AddPropertyImageUpload isPost={isPost} />
                    )}
                    {/* {currentStep === 5 && <AddPropertyFileUpload />} */}
                    {currentStep === 4 && <AddPropertyDetails />}
                    {/* {currentStep === 7 && <AddPropertyAmenities />} */}
                    {currentStep === 5 && (
                      <PreviewPropertyDetails
                        isPropertyFromDashboard={isPropertyFromDashboard}
                        isEditProperty={isEditProperty}
                      />
                    )}
                  </>
                )}
              </div>
            </Col>
          </div>
        </Col>
      </Container>
    </>
  );
};
AddProperty.propTypes = {
  showThings: PropTypes.bool,
  isPropertyFromDashboard: PropTypes.bool,
  hideData: PropTypes.bool,
  callSubmitFunc: PropTypes.bool,
  isPost: PropTypes.bool,
  isEditProperty: PropTypes.bool,
  isEditPost: PropTypes.bool,
};

AddProperty.defaultProps = {
  showThings: true,
  isPropertyFromDashboard: false,
  hideData: false,
  callSubmitFunc: false,
  isPost: false,
  isEditProperty: false,
  isEditPost: false,
};

export default AddProperty;
