import { NavLink, React, Link } from "react-router-dom";
import HeroSection from "../components/homepage/HeroSection";
import PropertyByCity from "../components/homepage/PropertyByCity";
import Offer from "../components/homepage/Offer";
import Type from "../components/homepage/Type";
import AboutUs from "../components/homepage/AboutUs";
import AboutUsTwo from "../components/homepage/AboutUs2";
import AddedToday from "../components/homepage/AddedToday";
import HowItWorks from "../components/homepage/HowItWorks";
import InfoBar from "../components/homepage/InfoBar";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

function HomePage() {
  return (
    <div className="home-page">
      <Helmet>
        <title>
          Find Your Dream Home – Buy or Rent with All Home Desire | Properties
          Online
        </title>
        <meta
          name="description"
          content="Discover Your Dream Property in India with All Home Desire - The Best Site for Buying, Selling, and Renting Residential and Commercial Real Estate"
        />
        <meta
          name="keywords"
          content="real estate, property in India, properties, property sites India, apartments near me, apartments for rent, houses for sale near me, homes for sale near me, apartments, apartments for rent near me, sell my house, houses for sale, we buy houses, houses for rent near me, sell my house fast, homes for sale"
        />
      </Helmet>
      <Link
        variant="primary"
        size="lg"
        className="btn btn-primary btn-lg float-left"
        style={{
          position: "fixed",
          bottom: "10px",

          right: "10px",
          zIndex: 100,
        }}
        to={"/partnerreg"}
      >
        Become A Partner
      </Link>
      <HeroSection />
      <PropertyByCity />
      <Type />
      <Offer />
      <AboutUs />
      <AddedToday />
      <AboutUsTwo />
      <HowItWorks />
      <InfoBar />
    </div>
  );
}

export default HomePage;
