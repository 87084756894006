import React, { useState, useEffect } from "react";
import Spinner from "../../../components/common/spinner/Spinner";
import { Col, Container, Row, Button, Form } from "react-bootstrap";

import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import { useSelector } from "react-redux";
import UserProfile from "./UserProfile";
import Profile from "./Profile";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
const EditUser = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const userId = loggedInUser?.userDetails?.id;

  const getDetailsApi = Api({
    url: `admin/user/id/${userId}`,
  });
  const editDetailsApi = Api({
    url: `admin/editUser`,
  });
  const [formData, setFormData] = useState({
    id: userId,
    first_name: "",
    last_name: "",
    email_address: "",
    mobile_number: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getDetailsApi.fetchData();
        console.log(response);
        if (response.data.statusType === "SUCCESS") {
          const userData = await response.data.user;
          console.log(userData);
          setFormData({
            ...formData,

            first_name: userData.first_name,
            last_name: userData.last_name,
            email_address: userData.email_address,
            mobile_number: userData.mobile_number,
          });
        }
      } catch (error) {
        setIsError(true);
        console.log(error);
        showToast("Error Occurred", {
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(formData);
      const responseData = await editDetailsApi.editData(formData);
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("User Edited Successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/user");
        }, 3000);
      }
    } catch (error) {
      setIsError(true);
      showToast("Error Occurred", {
        type: "error",
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Profile", link: "/user/profile" },
    { label: "Edit User Profile", link: "", active: true },
  ];

  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <SingleDetailHeader
          header="Edit User Profile"
          description="Edit user profile details"
        />
        <Row>
          <Col md={3}>
            <UserProfile />
          </Col>
          <Col md={9}>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  {/* <Form.Group controlId="userId">
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="id"
                      value={formData.id}
                      disabled
                    />
                  </Form.Group> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="first_name">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="last_name">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="email_address">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email_address"
                      value={formData.email_address}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="mobile_number">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="primary" type="submit" className="mt-5 mb-5">
                Edit User
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditUser;
