import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
// import Api from "../admin/components/api/Api";
import Notification, {
  showToast,
} from "../components/common/notification/Notification";
import axios from "axios";
function ConatactUsFront() {
  const [formData, setFormData] = useState({
    name: "",
    email_address: "",
    mobile_number: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        `${baseUrl}/all/addContactUs`,
        formData
      );
      console.log("Success:", response.data);

      showToast("Query Sent successfully", {
        type: "success",
      });

      setFormData({
        name: "",
        email_address: "",
        mobile_number: "",
        message: "",
      });
    } catch (error) {
      console.log(error);
      showToast("Some error occured", {
        type: "error",
      });
    }
  };

  return (
    <>
      <Notification />
      <section className="page-header parallaxie">
        <Container>
          <Row>
            <Col>
              <div className="page-header-box">
                <h1 className="text-anime">Contact us</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link href="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact us
                    </li>
                  </ol>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div class="contact-details">
        <Container>
          <Row>
            <Col md={12}>
              <div class="section-title">
                <h3 className="">Contact Us</h3>
                <h2 className="text-anime">Contact Details</h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="contact-detail-item">
                <div className="icon-box">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                </div>

                <h3>Address</h3>
                <p>7th Avenue Gaur City 1, Noida Extension</p>
              </div>
            </Col>

            <Col md={4}>
              <div className="contact-detail-item">
                <div class="icon-box">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>

                <h3>Phone</h3>
                <p>+91 9818961783</p>
              </div>
            </Col>

            <Col md={4}>
              <div className="contact-detail-item ">
                <div className="icon-box">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </div>

                <h3>Email</h3>
                <p>allhomedesire@gmail.com</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="contact-inquiry-box">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="contact-form">
                <form
                  id="contactForm"
                  onSubmit={handleSubmit}
                  // data-toggle="validator"
                  // noValidate
                >
                  <div className="row">
                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        required="required"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <div className="help-block with-errors"></div>
                    </div>

                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="email"
                        name="email_address"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required
                        value={formData.email_address}
                        onChange={handleChange}
                      />
                      <div className="help-block with-errors"></div>
                    </div>

                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="number"
                        name="mobile_number"
                        className="form-control"
                        id="phone"
                        placeholder="Phone"
                        required
                        value={formData.mobile_number}
                        onChange={handleChange}
                      />
                      <div className="help-block with-errors"></div>
                    </div>

                    <div className="form-group col-md-12 mb-4">
                      <textarea
                        name="message"
                        className="form-control"
                        id="message"
                        rows="4"
                        placeholder="Write a Message"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <div className="help-block with-errors"></div>
                    </div>

                    <div className="col-md-12 text-center">
                      <button type="submit" className="btn-default disabled">
                        Submit Now
                      </button>
                      <div id="msgSubmit" className="h3 text-left hidden"></div>
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ConatactUsFront;
