import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Container,
  Row,
  Col,
  Badge,
  Dropdown,
  DropdownButton,
  Modal,
  Button,
} from "react-bootstrap";
import PdfIcon from "./pdf (1).png";
import ImgIcon from "./image (1).png";
import Api from "../../components/api/Api";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Style from "../../UserStyle.module.css";
import parse from "html-react-parser";

import TimeAgo from "../../components/timeAgo/TimeAgo";
import { useSelector } from "react-redux";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import {
  FaEllipsisV,
  FaComment,
  FaCheckCircle,
  FaBed,
  FaBath,
  FaRestroom,
  FaFileDownload,
  FaCheck,
  FaCross,
  FaXingSquare,
} from "react-icons/fa";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import axios from "axios";
import TabMenu from "../homepage/TabMenu";
import AdminHeader from "../../common/Header";
const PartnerItem = () => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [singleData, setsingleData] = useState({});
  const [assetUrl, setAssetUrl] = useState("");
  const { partnerId } = useParams();
  const [documents, setUniqueDocuments] = useState();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const singlePropertyData = Api({
    url: `admin/user/id/${partnerId}`,
  });
  const [leadId, setLeadId] = useState(null);
  const getUniqueDocument = (documents) => {
    const seen = new Set();
    return documents.filter((doc) => {
      const duplicate = seen.has(doc.name);
      seen.add(doc.name);
      return !duplicate;
    });
  };
  useEffect(() => {
    try {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const responseData = await singlePropertyData.fetchDataBySingleId();
          console.log("your response data is", responseData);
          setPropertyDetails(responseData?.data?.user);
          let uniqueDocuments = await getUniqueDocument(
            responseData?.data?.user?.documents
          );
          setUniqueDocuments(uniqueDocuments);
          setAssetUrl(responseData?.data?.assetUrl);

          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleModalOpen = (action) => {
    setModalAction(action);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const [isZoomed, setIsZoomed] = useState(false);

  // Toggle zoom state
  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/partners" },
    { label: "Partner", link: "/partners", active: true },
  ];
  const handleApprove = async () => {
    try {
      const data = {
        id: +partnerId,
        first_name: propertyDetails?.first_name,
        last_name: propertyDetails?.last_name,
        status: modalAction,
      };
      const response = await axios.put(`${baseUrl}/admin/editUser`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusType === "SUCCESS") {
        showToast(`User ${modalAction.toLowerCase()} successfully`, {
          type: "success",
        });
        setTimeout(() => {
          navigate("/partners");
        }, 3000);
      } else {
        showToast(`Error 'approving' item. Server responded with status:`, {
          type: "success",
        });
        console.error(
          "Error 'approving' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      showToast("Some Error Occurred!", { type: "error" });
    } finally {
      handleModalClose();
    }
  };
  return (
    <>
      <Notification />
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="main-wrapper">
            {/* <Col md={12} className="p-3"></Col> */}
            <Container
              fluid
              style={{ marginTop: "2rem", padding: "0rem 4rem" }}
            >
              <BreadCrumb items={breadcrumbItems} />

              <Row>
                <Col lg={3} md={4} sm={12}>
                  <AdminHeader />
                </Col>
                <Col lg={9} md={8} sm={12} className="mainCardAreaInside">
                  <div className="card">
                    <Row className="">
                      <Col md={10} sm={8}>
                        <Col md={12}>
                          <div className="cardTop">
                            <div className="nameSection">
                              <p>
                                {propertyDetails?.first_name}{" "}
                                {propertyDetails?.last_name}
                              </p>
                            </div>

                            <div className="badgebootstrap">
                              <Badge
                                bg="secondary"
                                style={{ fontSize: "14px" }}
                              >
                                Success
                              </Badge>
                            </div>
                            <div className="badgebootstrap">
                              <Badge
                                bg="secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {propertyDetails?.service_name}
                              </Badge>
                            </div>

                            <div className="badgebootstrap">
                              <Badge
                                bg="secondary"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                Upi
                              </Badge>
                            </div>

                            <div className="timeAgo">
                              <TimeAgo timestamp="05-08-2024 13:50:14" />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <span>Email: {propertyDetails?.email_address}</span>
                            <br />
                            <span>
                              Mobile Number: {propertyDetails?.mobile_number}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="slider-container">
                            <Swiper
                              spaceBetween={150}
                              // slidesPerView={4}
                              breakpoints={{
                                576: {
                                  width: 576,
                                  slidesPerView: 2,
                                },
                                768: {
                                  width: 768,
                                  slidesPerView: 3,
                                },
                                1100: {
                                  width: 768,
                                  slidesPerView: 2,
                                },
                              }}
                              pagination={{ clickable: true }}
                              scrollbar={{ draggable: true }}
                              onSlideChange={() => console.log("slide change")}
                              onSwiper={(swiper) => console.log(swiper)}
                            >
                              {documents &&
                                documents.map((singleDetails, index) =>
                                  !singleDetails?.document_url?.includes(
                                    ".pdf"
                                  ) ? (
                                    <>
                                      <SwiperSlide key={index + 1}>
                                        <div className="pdf-card">
                                          <div className="pdf-thumbnail">
                                            <div className="pdf-icon">
                                              <Zoom>
                                                <img
                                                  src={`${assetUrl}/${singleDetails?.document_url}`}
                                                  alt="Image Icon"
                                                />
                                              </Zoom>
                                            </div>
                                          </div>
                                          <div className="pdf-info">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <img
                                                className="pdf-info-icon"
                                                src={ImgIcon}
                                                alt="Img Icon"
                                              />
                                              <span className="pdf-filename">
                                                {singleDetails?.name?.toUpperCase()}
                                              </span>
                                            </div>

                                            {/* <div className="pdf-ribbon"></div> */}
                                            <a
                                              href={`${assetUrl}/${singleDetails?.document_url}`}
                                              download={singleDetails?.name?.toUpperCase()}
                                              className="pdf-download-button "
                                              style={{
                                                fontSize: "1.5rem",
                                                color: "grey",
                                              }}
                                            >
                                              <FaFileDownload />
                                            </a>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    </>
                                  ) : (
                                    <>
                                      <SwiperSlide key={index + 1}>
                                        <div className="pdf-card">
                                          <div className="pdf-thumbnail">
                                            <img src={PdfIcon} alt="Pdf Icon" />
                                          </div>
                                          <div className="pdf-info">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <img
                                                className="pdf-info-icon"
                                                src={PdfIcon}
                                                alt="PDF Icon"
                                              />
                                              <span className="pdf-filename">
                                                {singleDetails?.name?.toUpperCase()}
                                              </span>
                                            </div>

                                            {/* <div className="pdf-ribbon"></div> */}
                                            <a
                                              href={`${assetUrl}/${singleDetails?.document_url}`}
                                              download={singleDetails?.name?.toUpperCase()}
                                              className="pdf-download-button"
                                              style={{
                                                fontSize: "1.5rem",
                                                color: "grey",
                                              }}
                                            >
                                              <FaFileDownload />
                                            </a>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    </>
                                  )
                                )}
                            </Swiper>
                          </div>
                        </Col>
                        <Col>
                          <Row>
                            {propertyDetails?.payments?.map((singlePayment) => (
                              <>
                                <Col md={6}>
                                  <img
                                    src={`${assetUrl}/${singlePayment?.document_url}`}
                                    style={{
                                      width: "30rem",
                                      height: "20rem",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Col>
                                <Col md={6}>
                                  <div
                                    style={{
                                      margin: "3rem auto",
                                      padding: "20px",
                                      borderRadius: "8px",
                                      backgroundColor: "#f7f7f7",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="mb-0">Payment Status: </p>
                                      {singlePayment.status === "SUCCESS" ? (
                                        <span className="successFullPayment">
                                          <FaCheck className="mx-1" />
                                          {singlePayment?.status}
                                        </span>
                                      ) : (
                                        <span className="dangerFullPayment text-danger">
                                           X
                                          {" "}{singlePayment?.status}
                                        </span>
                                      )}
                                    </span>
                                    <hr />
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="mb-0">Mode: </p>

                                      <span>{singlePayment?.mode}</span>
                                    </span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="mb-0">Gateway:</p>

                                      <span>{singlePayment?.gateway}</span>
                                    </span>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="mb-0">Provider</p>

                                      <span>{singlePayment?.provider}</span>
                                    </span>
                                    <hr />
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="mb-0">Amount</p>

                                      <span>₹{singlePayment?.amount}</span>
                                    </span>
                                  </div>
                                </Col>
                              </>
                            ))}
                          </Row>
                        </Col>
                        <Col className="mt-5">
                          <Row>
                            <Col md={3}>
                              <button
                                className="btn btn-outline-warning btn-lg"
                                onClick={() => handleModalOpen("HOLD")}
                              >
                                Hold
                              </button>
                            </Col>
                            <Col md={3}>
                              <button
                                className="btn btn-outline-danger btn-lg"
                                onClick={() => handleModalOpen("REJECT")}
                              >
                                Reject
                              </button>
                            </Col>
                            <Col md={3}>
                              {propertyDetails?.active ? (
                                <></>
                              ) : (
                                <button
                                  className="btn btn-outline-success btn-lg"
                                  onClick={() => handleModalOpen("APPROVE")}
                                >
                                  Approve
                                </button>
                              )}
                            </Col>

                            <Col lg={12} md={12} sm={12}>
                              <Row style={{ marginTop: "2rem" }}>
                                <div>
                                  {/* <Button className={`btn btn-primary`}>Edit</Button> */}
                                  {userType === "PARTNER" ||
                                  userType === "CUSTOMER" ? (
                                    <Link
                                      className="btn btn-primary"
                                      to={"/partners"}
                                    >
                                      Back
                                    </Link>
                                  ) : (
                                    <Link
                                      // style={{ marginLeft: "1rem" }}
                                      className="btn btn-primary"
                                      // to={"/post"}
                                      to={"/partners"}
                                    >
                                      Back
                                    </Link>
                                  )}
                                </div>
                              </Row>
                            </Col>
                            {/* <Col md={3}>
                              <button className="btn btn-outline-success btn-lg">
                               Delete
                              </button>
                            </Col>       */}
                          </Row>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Modal */}
          <Modal show={isModalOpen} onHide={handleModalClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {modalAction === "APPROVE"
                  ? "Approve User"
                  : modalAction === "HOLD"
                  ? "Hold User"
                  : "Disable User"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to {modalAction.toLowerCase()} this user?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => handleApprove(modalAction)}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default PartnerItem;
