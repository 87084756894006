import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Container } from "react-bootstrap";
import Select from "react-select";
import PropertySingleCard from "../../components/Property/PropertySingleCard";
import PostSingleCard from "../../components/post/PostSingleCard";
import { useSelector } from "react-redux";
import axios from "axios";
import TabMenu from "./TabMenu";
import Pagination from "../../components/pagination/Pagination";
// import PropertyLikePostCard from "../../components/post/PropertyLikePostCard";

const PromotionalProperty = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const fetchOptions = useSelector((state) => state.mySpace.option);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log();
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(fetchOptions || "all");
  const [assetUrl, setAssetUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchData = async (pageNumber, pageSize = 10) => {
    setIsLoading(true);
    const postData = {
      search_type: capitalizeFirstLetter(selectedValue),
      page_number: pageNumber,
      page_size: pageSize,
      order_by: "DSC",
    };
    try {
      const response = await axios.post(`${baseUrl}/property/list`, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.properties);
      const responseData = response.data.properties.filter(
        (singleProperty) => singleProperty.purpose.name === "Promotional"
      );
      setData(responseData);

      setAssetUrl(response.data.assetUrl);
      setTotalPages(response.data.totalPage);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  return (
    <Container>
      <TabMenu />
      <Row>
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {data.length > 0 ? (
              <PropertySingleCard data={data} assetUrl={assetUrl} />
            ) : (
              <p className="d-flex align-items-center justify-content-center mt-5">
                No Data To Show
              </p>
            )}
          </>
        )}
      </Row>
      <div className="mt-5">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </Container>
  );
};

export default PromotionalProperty;
