import React from "react";
import { Row } from "react-bootstrap";
const SingleDetailHeader = ({ header, description }) => {
  return (
    <>
      <Row>
        <h2>{header}</h2>
        <p>{description}</p>
        <hr />
      </Row>
    </>
  );
};

export default SingleDetailHeader;
