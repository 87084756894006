import React, { useEffect } from "react";
import AddProperty from "../property/AddProperty";
import TabMenu from "./TabMenu";
import { Container } from "react-bootstrap";
import { resetForm } from "../../../features/formData/formSlice";
import { useDispatch } from "react-redux";

const Tab5 = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetForm());
  }, []);
  return (
    <>
      <Container>
        <TabMenu />
      </Container>

      <AddProperty
        showThings={false}
        isPropertyFromDashboard={true}
        hideData={false}
      />
    </>
  );
};

export default Tab5;
