import React from "react";
// import { Col } from "react-bootstrap";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
};
const InputField = ({ placeholder, onChange, className, value, type }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <input
      type={type}
      className={`form-control ${className}`}
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
    />
  );
};

export default InputField;
