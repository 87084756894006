import React from "react";
import { Link } from "react-router-dom";
import { Nav, Accordion } from "react-bootstrap";
import { FaNetworkWired, FaPersonBooth, FaUser, FaUsers } from "react-icons/fa";

const UserMenu = ({ userRoles }) => {
  console.log(userRoles);
  const rolePages = {
    admin: [
      { to: "/contact", label: "Customer Management", icon: <FaPersonBooth /> },
      { to: "/network", label: "Network Management", icon: <FaNetworkWired /> },
      { to: "/user", label: "Team Management", icon: <FaUsers /> },
    ],
    lead: [{ to: "/contact", label: "Contact" }],
    "team-member": [{ to: "/contact", label: "Contact" }],
    "front-desk": [{ to: "/contact", label: "Contact" }],
  };

  const userRole = userRoles?.includes("ROLE_ADMIN")
    ? "admin"
    : "user" || userRoles?.includes("CUSTOMER")
    ? "lead"
    : "user";

  return (
    <ul
      className="nav"
      style={{
        listStyle: "none",
        padding: 0,
        margin: 0,
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {rolePages[userRole] &&
        rolePages[userRole].map((page, index) => (
          <li
            className="nav-item"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            key={index}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {page.icon}
            </span>
            <Link
              className="nav-link"
              to={page.to}
              style={{ textDecoration: "none", color: "black" }}
            >
              {page.label}
            </Link>
          </li>
        ))}
      {/* Add more user menu items as needed */}
    </ul>
  );
};

export default UserMenu;
