import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import PropertyImg4 from "../../assets/images/property-4.jpg";
import Button from "../common/button/Button";

const AboutUs = () => {
  return (
    <>
      <section className="why-choose-us">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} orderLg={2}>
              <div className="why-choose-us-image">
                <figure className="reveal image-anime">
                  <img src={PropertyImg4} alt="" />
                </figure>
              </div>
            </Col>
            <Col lg={6} orderLg={1}>
              <div className="why-choose-us-content">
                <div className="section-title">
                  <h3 className="wow fadeInUp">About Company</h3>
                  <h2 className="text-anime">
                    Let Us Help You Find Your Dream Property
                  </h2>
                </div>

                <div className="why-choose-us-body">
                  <p>
                    <strong>All Home Desire</strong> All Home Desire is a
                    trusted real estate platform specializing in providing
                    customers with the latest construction and market
                    information. We also offer comprehensive interior design
                    solutions, all at an affordable price.
                  </p>
                  <p>
                    We are designed to meet all your needs and are conveniently
                    located near international schools, transportation options,
                    shopping centers, and gardens. We also assist with every
                    aspect of property research, loans, investments, and EMI
                    calculations, ensuring a smooth and satisfying experience.
                  </p>

                  <Link to={"/about-us"} className="btn btn-default">
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutUs;
