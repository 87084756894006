// features/form/partnerSlice.js

import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const getPartnerId = secureLocalStorage.getItem("partner_id");
const initialState = {
  formData: {
    partner_id: getPartnerId,
  },
  isImageUploaded: false,
  isAttachmentUploaded: false,
  currentStep: 1,
  lead_id: 0,
  // Add other form-related state here
};
const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    setFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    saveLeadId(state, action) {
      state.lead_id = action.payload;
    },
    setCurrenStep(state, action) {
      state.currentStep = action.payload;
    },
    nextStep(state) {
      state.currentStep += 1;
    },
    prevStep(state) {
      state.currentStep -= 1;
    },
    resetStep(state) {
      state.currentStep = 1;
    },
    resetForm(state) {
      return initialState;
    },

    // Add other reducers as needed
  },
});

export const {
  setFormData,
  nextStep,
  prevStep,
  resetStep,
  resetForm,
  saveLeadId,
  reset,
  setCurrenStep,
} = partnerSlice.actions;

export default partnerSlice.reducer;
