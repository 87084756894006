import React from "react";
import { Outlet } from "react-router-dom";

const DashboardRoot = () => {
  return (
    <div className="property-card-admin">
      <Outlet />
    </div>
  );
};

export default DashboardRoot;
