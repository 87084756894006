import React, { useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormData,
  nextStep,
  prevStep,
} from "../../../features/formData/formSlice";
import { FaUpload } from "react-icons/fa";
import Spinner from "../../../components/common/spinner/Spinner";
import FileUploadField from "../../../components/fileUpload/FIleUploadField";
import Error from "../../../components/common/error/Error";

const AddPropertyImageUpload = ({ isPost }) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const formData = useSelector((state) => state.form.formData);
  const token = loggedInUser?.token;
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isAttachmentUploaded, setIsAttachmentUploaded] = useState(false);

  const [uploadedImagePaths, setUploadedImagePaths] = useState(
    formData?.images || []
  );
  const [uploadedAttachmentPaths, setUploadedAttachmentPaths] = useState(
    formData?.files || []
  );
  const uploadImages = async (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${baseUrl}/all/upload/files/images`,
        formData
      );

      if (response.data.statusType === "SUCCESS") {
        const imagePaths = response.data.propertyImages.map((image) => image);
        setUploadedImagePaths(imagePaths);
        setIsImageUploaded(true);
        return imagePaths;
      }
    } catch (error) {
      console.log(error);
      alert("Please select one or more images to upload.");
      return [];
    }
  };

  const uploadAttachments = async (files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    try {
      const response = await axios.post(
        `     ${baseUrl}/all/upload/files/attachments`,
        formData
      );

      if (response.data.statusType === "SUCCESS") {
        const attachmentPaths = response.data.propertyAttachments.map(
          (attachment) => attachment
        );
        setUploadedAttachmentPaths(attachmentPaths);
        setIsAttachmentUploaded(true);
        return attachmentPaths;
      }
    } catch (error) {
      console.log(error);
      alert("Please select one or more attachments to upload.");
      return [];
    }
  };

  const handleSubmit = async (values) => {
    const imageAndVideoFiles = values.files.filter(
      (file) => file.type.startsWith("image/") || file.type.startsWith("video/")
    );

    const attachmentFiles = values.files.filter((file) =>
      file.type.startsWith("application/")
    );

    let uploadedImages = [];
    let uploadedAttachments = [];

    if (isPost) {
      uploadedImages = imageAndVideoFiles.length
        ? await uploadImages(imageAndVideoFiles)
        : [];
    } else {
      uploadedImages = imageAndVideoFiles.length
        ? await uploadImages(imageAndVideoFiles)
        : [];

      uploadedAttachments = attachmentFiles.length
        ? await uploadAttachments(attachmentFiles)
        : [];
    }
    const formDataInside = {
      ...formData,
      images: uploadedImages.length ? uploadedImages : formData.images,
      files: uploadedAttachments.length ? uploadedAttachments : formData.files,
    };

    dispatch(setFormData(formDataInside));
    dispatch(nextStep());
  };

  const handlePrevious = async (values) => {
    const imageAndVideoFiles = values.files.filter(
      (file) => file.type.startsWith("image/") || file.type.startsWith("video/")
    );

    const attachmentFiles = values.files.filter(
      (file) => !file.type.startsWith("image/")
    );

    const uploadedImages = imageAndVideoFiles.length
      ? await uploadImages(imageAndVideoFiles)
      : [];

    const uploadedAttachments = attachmentFiles.length
      ? await uploadAttachments(attachmentFiles)
      : [];

    const combinedFormData = {
      ...formData,
      images: uploadedImages.length ? uploadedImages : formData.images,

      files: uploadedAttachments.length ? uploadedAttachments : formData.files,
    };

    dispatch(setFormData(combinedFormData));
    dispatch(prevStep());
  };

  const getMediaUrl = (url) => {
    return url.includes("https://images.allhomedesire.in")
      ? url
      : `https://images.allhomedesire.in/${url}`;
  };

  return (
    <>
      <div>
        <Formik
          initialValues={{
            files: [],
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              {!uploadedAttachmentPaths.length > 0 && (
                <>
                  <h6>Upload Attachments and Images</h6>
                  <Row>
                    <Col md={12}>
                      <div className="">
                        <Field name="files" component={FileUploadField} />
                        <ErrorMessage
                          name="files"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {/* {uploadedImagePaths.length > 0 && (
                <div>
                  <h6>Uploaded Images</h6>
                  <Row>
                    {uploadedImagePaths.map((image, index) => (
                      <Col key={index} md={4}>
                        <img src={image.image_url} />
                      </Col>
                    ))}
                  </Row>
                </div>
              )} */}
              {uploadedImagePaths.length > 0 && (
                <div>
                  <h6>Uploaded Images</h6>
                  <Row>
                    {uploadedImagePaths.map((media, index) => (
                      <Col key={index} md={4}>
                        {/* Check if the URL ends with an image extension */}
                        {media.image_url.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                          <img
                            src={getMediaUrl(media.image_url)}
                            alt={`Media-${index}`}
                            style={{ width: "100%", height: "auto" }} // Optional styling for responsive images
                          />
                        ) : media.image_url.match(/\.(mp4)$/i) ? ( // Check if the URL ends with .mp4 for videos
                          <video
                            controls // Video controls
                            style={{ width: "100%", height: "auto" }} // Optional styling for responsive videos
                          >
                            <source
                              src={getMediaUrl(media.image_url)}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <p>Unsupported media type</p> // Handle unsupported file types gracefully
                        )}
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              {uploadedAttachmentPaths.length > 0 && (
                <div>
                  <h6>Uploaded Attachments</h6>
                  <ul>
                    {uploadedAttachmentPaths.map((attachment, index) => (
                      <li key={index}>
                        <a
                          href={attachment.attachment_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {attachment.attachmentPath}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div style={{ padding: "10px 0" }}>
                <div className="d-flex justify-content-between">
                  <Button
                    variant="primary"
                    onClick={() => handlePrevious(values)}
                    disabled={isSubmitting}
                  >
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary btn-xxl"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner loading={true} /> : "Next"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {isImageUploaded === true ? (
          <Error
            variant="success"
            message="Images uploaded successfully"
            show={true}
          />
        ) : (
          <h1></h1>
        )}
        {isAttachmentUploaded === true ? (
          <Error
            variant="success"
            message="Attachment uploaded successfully"
            show={true}
          />
        ) : (
          <h1></h1>
        )}
      </div>
    </>
  );
};

export default AddPropertyImageUpload;
