import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ThankYou from "../components/afterPartnerRegistration/ThankYou";
import UploadDocument from "../components/afterPartnerRegistration/UploadDocument";

const PaymentMessage = () => {
  return (
    <>
      <Container fluid style={{ padding: "0rem 4rem" }}>
        <Row>
          {/* <Col md={12}>
            <ThankYou />
          </Col> */}
          <Col md={12}>
            <UploadDocument />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentMessage;
