import React from "react";
import { useSelector } from "react-redux"; // Import useSelector

const AdminAndPartnerProtected = ({ children }) => {
  // Access user details from Redux store
  const user = useSelector((state) => state.auth.user);
  console.log(user);

  // Ensure user object is defined and check user roles
  if (user && user.userDetails && user.statusType === "SUCCESS") {
    const { userType, roles } = user.userDetails;

    if (
      userType === "SUPER_ADMIN" ||
      userType === "ALL_HOME_DESIRE" ||
      (userType === "PARTNER" && roles.includes("ROLE_ADMIN"))
    ) {
      return children; // Render children if user has the right roles
    }
  }

  return null; // Access denied; return null or fallback UI
};

export default AdminAndPartnerProtected;
