import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";

import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import EditPropertyImages from "./EditPropertyImages";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AddProperty from "./AddProperty";
import { setFormData } from "../../../features/formData/formSlice";
import TabMenu from "../homepage/TabMenu";

const EditProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const loggedInUser = useSelector((state) => state.auth.user);
  const authToken = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const { propertyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [propertyDetails, setPropertyDetails] = useState([]);

  const getPropertyApi = Api({
    url: `property/propertyDetail/${propertyId}`,
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Properties", link: "/property" },
    { label: "Edit Property", link: "", active: true },
  ];
  useEffect(() => {
    const fetchPostData = async () => {
      // const findCity =
      try {
        setIsLoading(true);
        const response = await getPropertyApi.fetchDataBySingleId(true);

        if (response.data.statusType === "SUCCESS") {
          const postData = await response.data.propertyInfo;

          const formDatas = {
            propertyPurpose: postData?.purpose_id,
            propertyType: postData?.property_type_id,
            city: postData?.city?.id,
            state: postData?.state?.id,
            area: postData?.area_name,
            structure_id: +postData?.structure?.id,
            feature_id: +postData?.feature?.id,
            swimming_pool: postData?.feature?.swimming_pool,
            gas_connection: postData?.feature?.gas_connection,
            ro: postData?.feature?.ro,
            club_house: postData?.feature?.club_house,
            basketball_court: postData?.feature?.basketball_court,
            tennis_court: postData?.feature?.tennis_court,
            gym: postData?.feature?.gym,
            indoor_games: postData?.feature?.indoor_games,
            child_play_area: postData?.feature?.child_play_area,
            hospital_near_by: postData?.feature?.hospital_near_by,
            mall_near_by: postData?.feature?.mall_near_by,
            market_near_by: postData?.feature?.market_near_by,
            school_near_by: postData?.feature?.school_near_by,
            featured_property: postData?.featured_property,
            bedrooms: +postData?.structure?.bed_rooms,
            bathrooms: +postData?.structure?.bath_rooms,
            rooms: +postData?.structure?.rooms,
            tags: postData?.tags,
            pin_code: postData?.pin_code,
            address: postData?.address,
            id: postData?.id,
            name: postData?.name,
            price: postData?.price,
            description: postData?.description,
            country_name: 1,
            propertySubtype: +postData?.sub_type_id,
            featured_property: postData?.featured_property,
            images: postData?.images,
          };

          setData(postData);
          dispatch(setFormData(formDatas));
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <Spinner animation="border" />
          </div>
        </>
      ) : (
        <>
          <Notification />
          <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
            {/* <BreadCrumb items={breadcrumbItems} /> */}
            <TabMenu />
            <div className="mt-2">
              <SingleDetailHeader
                header="Edit Property Details"
                description="Edit Property details section"
              />
            </div>
          </Container>
          <div>
            {data && (
              <AddProperty
                showThings={false}
                isPropertyFromDashboard={false}
                hideData={false}
                isEditProperty={true}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default EditProperty;
