import React,{useState} from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react"; // Import the QRCode component
import { useDispatch } from "react-redux";
import { nextStep,prevStep } from "../features/partnerRegistration/partnerSlice";
import { FaCheckCircle, FaEnvelope, FaRegTrashAlt, FaUpload, FaWhatsapp } from "react-icons/fa";
const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screenshotFile, setScreenshotFile] = useState(null);

  const handleGoBack = () => {
    dispatch(nextStep());
  };

  // UPI ID and other details
  const upiId = "MSUSHMAINFOTECHPRIVATELIMITED.eazypay@icici";
  const amount = "12000";
  const payeeName = "M/S.USHMA INFOTECH PRIVATE LIMITED";

  // UPI payment URL format
  const upiPaymentUrl = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
    payeeName
  )}&am=${amount}&cu=INR`;
  const handlePrevious = () => {
    dispatch(prevStep())
  }


  const handleShareWhatsApp = () => {
    const whatsappText = `Please find my payment screenshot attached.`;
    const whatsappURL = `https://wa.me/9818961783?text=${encodeURIComponent(
      whatsappText
    )}`;

    if (screenshotFile) {
     
      const attachmentLink = `${whatsappURL}%0A%0A${screenshotFile}`;
      window.open(attachmentLink, "_blank");
    } else {
      window.open(whatsappURL, "_blank");
    }
  };

  // Function to share via Email
  const handleShareEmail = () => {
    const subject = encodeURIComponent("Payment Screenshot");
    const body = encodeURIComponent("Attached is my payment screenshot.");
    const mailtoLink = `mailto:info@ushmainfotech.com?cc=allhomedesire@gmail.com&subject=${subject}&body=${body}`;

    if (screenshotFile) {
      // Attach the image as a link in the body (limited support in some email clients)
      const attachmentLink = `<a href="${screenshotFile}" download="payment-screenshot.png">Download Screenshot</a>`;
      window.location.href = `${mailtoLink}&body=${body}%0A%0A${attachmentLink}`;
    } else {
      window.location.href = mailtoLink;
    }
  };
  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={8}>
          <Card>
            <Card.Header className="text-center">
              <h3>Complete Your Payment</h3>
            </Card.Header>
            <Card.Body>
              <div className="mb-4">
                <h5>Step 1: Complete Your Payment</h5>
                <p>
                  Once you've completed your registration, kindly proceed with
                  making your payment.
                </p>
              </div>
              <div
                style={{
                  margin: "3rem auto",
                  padding: "20px",
                  borderRadius: "8px",
                  backgroundColor: "#f7f7f7",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="mb-0">GST Number: </p>

                  <span>09AADCU5871B1ZA</span>
                </span>
                <hr />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="mb-0">Registration fee: </p>

                  <span>₹200</span>
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="mb-0">Annual Fee:</p>

                  <span>₹10000</span>
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="mb-0">GST</p>

                  <span>₹1800</span>
                </span>
                <hr />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="mb-0">Total</p>

                  <span>₹12000</span>
                </span>
              </div>
              <div className="text-center mb-4">
                <h5>Scan this QR Code to Pay</h5>
                <QRCode
                  value={upiPaymentUrl} // Pass the UPI payment URL here
                  size={250} // Size of the QR code
                  bgColor={"#ffffff"} // Background color
                  fgColor={"#000000"} // Foreground color
                  level={"H"} // Error correction level (L, M, Q, H)
                  includeMargin={true} // Include margin in the QR code
                />
              </div>

              <div className="mb-4">
                <h5>Step 2: Share Payment Confirmation</h5>
                <p>
                  To confirm your payment, please share the payment confirmation
                  details with us.
                </p>
              </div>
                      <div className="mb-4">
                <h5>Option 1: WhatsApp</h5>
                <p>
                  Send the screenshot to our dedicated number:
                  <strong> 9818961783</strong>
                </p>
                <Button
                  variant="success"
                  onClick={handleShareWhatsApp}
                  className="mr-3"
                >
                  <FaWhatsapp size={20} style={{ marginRight: "5px" }} />
                  Share via WhatsApp
                </Button>
              </div>

              <div className="mb-4">
                <h5>Option 2: Email</h5>
                <p>
                  You can also email the screenshot to{" "}
                  <a href="mailto:info@ushmainfotech.com">
                    info@ushmainfotech.com
                  </a>{" "}
                  with a CC to{" "}
                  <a href="mailto:allhomedesire@gmail.com">
                    allhomedesire@gmail.com
                  </a>
                  .
                </p>
                <Button
                  variant="info"
                  onClick={handleShareEmail}
                  className="ml-3"
                >
                  <FaEnvelope size={20} style={{ marginRight: "5px" }} />
                  Share via Email
                </Button>

                <p
                style={{ color: "red", fontStyle: "italic", fontSize: "14px",marginTop:'1rem' }}
              >
                Note: Please also share the payment details on the next page.
              </p>
              </div>
              <div className="d-flex justify-content-between">
                <Button
                  type="submit"
                  className="btn btn-primary"
            
                  onClick={handlePrevious}
                >
                 Previous
                </Button>
                <Button
                  variant="primary"
                  // className="w-100"
                  onClick={handleGoBack}
                >
                  Next
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Payment;
