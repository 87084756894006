import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const AddCity = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const addCityApi = Api({
    url: `admin/addCity`,
  });

  const initialValues = {
    name: "",
    description: "",
    state: {
      id: "",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;
        const data = {
          page_number: 1,
          page_size: 20,
          order_by: "ASC",
        };
        const countryResponse = await axios.post(
          `${baseUrl}/admin/states/${1}`,
          data,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (countryResponse.data.statusType === "SUCCESS") {
          console.log(countryResponse);
          setStates(countryResponse.data.states);
        } else {
          setStates([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const data = {
        name: values.name.toUpperCase(),
        description: values.description,
        state: { id: +values.state },
      };
      console.log(data);
      const responseData = await addCityApi.postData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("City  added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/city");
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      console.log(error);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Cities", link: "/city" },
    { label: "Add City", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Add City"
              description="Add the city details section"
            />

            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Col md={12}>
                    <div className="">
                      <label htmlFor="title" className="form-label">
                        States
                      </label>
                      <Field
                        as="select"
                        className="form-control"
                        name="state"
                        required=""
                      >
                        <option value="">-- Select State --</option>
                        {states &&
                          states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </Field>
                    </div>
                  </Col>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                      name="name"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="description"
                      aria-describedby="emailHelp"
                      placeholder="Enter Description"
                      name="description"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    style={{ marginBottom: "10rem" }}
                    disabled={isSubmitting}
                    // disabled={true}
                  >
                    {isSubmitting ? <Spinner loading={true} /> : "Add State"}
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCity;
