import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AddProperty from "../property/AddProperty";
import { setFormData } from "../../../features/formData/formSlice";
import TabMenu from "../homepage/TabMenu";

const EditPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postId } = useParams();
  const getPostApi = Api({ url: `property/lead/${postId}` });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(false);
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await getPostApi.fetchDataBySingleId(true);
        if (response.data.statusType === "SUCCESS") {
          const postData = response.data.lead;
          const formDatas = {
            propertyPurpose: postData?.purpose_id,
            propertyType: postData?.type_id,
            city: postData?.city_id,
            state: postData?.state_id,
            area: postData?.area_name,
            lead_id: postData?.id,
            name: postData?.title,
            price: postData?.budget,
            description: postData?.description,
            country_name: 1,
            propertySubtype: +postData?.sub_type_id,
            featured_property: postData?.featured_property,
            images: postData?.images,
          };
          dispatch(setFormData(formDatas));
          setData(postData);
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, []);

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Posts", link: "/post" },
    { label: "Edit Post", link: "", active: true },
  ];

  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        {/* <BreadCrumb items={breadcrumbItems} /> */}
        <TabMenu />
        <SingleDetailHeader
          header="Edit Post Details"
          description="Edit Post details section"
        />
      </Container>
      {data && (
        <AddProperty
          showThings={false}
          isPropertyFromDashboard={false}
          hideData={true}
          isEditPost={true}
        />
      )}
    </>
  );
};

export default EditPost;
