import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Alert,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import parse from "html-react-parser";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Tr from "../../components/tr/Tr";
import { FaEllipsisV, FaComment } from "react-icons/fa";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import Pagination from "../../components/pagination/Pagination";

const Query = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;

  const propertyApi = Api({
    url: "admin/desiredQueries",
  });
  const deleteQueryApi = Api({
    url: "admin/deleteDesiredQuery",
  });
  const serviceApi = Api({
    url: "admin/services",
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/admin/deleteDesiredQuery/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Query deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Name", key: "name" },
    { displayName: "Mobile Number", key: "mobile_number" },
    { displayName: "Email Address", key: "email_address" },
    { displayName: "Area", key: "area.name" },
    { displayName: "Service", key: "service.name" },
  ];
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      const responseData = await propertyApi.fetchData(pageNumber);

      setData(responseData.data.desiredQueries);
      setTotalPages(responseData.data.totalPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const responseData = await serviceApi.fetchData();

        const selectedData = responseData.data.services.map((purpose) => ({
          label: purpose.name,
          value: purpose.id,
        }));

        setServices(selectedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPropertyData(currentPage);
    fetchServicesData();
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const initialValues = {};
  useEffect(() => {
    if (selectedServices) {
      const filtered = data.filter(
        (singleFilter) =>
          singleFilter?.service?.name === selectedServices?.label
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [selectedServices, data]);

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Queries", link: "/query", active: true },
  ];
  return (
    <>
      <Notification />
      <div className="bread-crumb">
        <Container>
          <Col md={8}>
            <BreadCrumb items={breadcrumbItems} />
          </Col>
        </Container>
      </div>
      <div className="content-body">
        <Container fluid>
          {/* Delete Alert */}
          {/* <BreadCrumb items={breadcrumbItems} /> */}

          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            {/* <Col md={2}>
              <AdminHeader />
            </Col> */}
            <Col md={12} className="mb-5">
              {data.length === 0 ? (
                // <AllMessage title="No Query Found" />

                <Tr
                  columns={columns}
                  initialValues={initialValues}
                  title="No Query Found"
                  // buttonName="Add Property Type"
                  // btnLink="property-type/add-propertyType"
                  titleHeader="Queries"
                  btn={false}
                />
              ) : (
                <>
                  {/* <Select
                    options={services}
                    onChange={setSelectedServices}
                    value={selectedServices}
                    placeholder="Select User Type"
                    isClearable={true}
                    style={{ height: "auto", zIndex: 88998 }}
                  /> */}
                  <div className="mt-3">
                    {/* <List
                      title="Queries"
                      data={data}
                      columns={columns}
                      route="query"
                      handleDelete={handleDelete}
                    /> */}

                    <Row style={{ width: "100%" }}>
                      <Col md={8} style={{ width: "100%" }}>
                        <Row
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Col
                            md={10}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <h2>Queries</h2>
                          </Col>
                          <Col
                            md={2}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            {console.log(userType)}
                            {userType === "SUPER_ADMIN" ||
                            userType === "ALL_HOME_DESIRE" ? (
                              <></>
                            ) : (
                              // <Link
                              //   className="btn btn-primary"
                              //   to={"/post/add-post"}
                              // >
                              //   Create
                              // </Link>
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {data &&
                      data.map((singleData) => (
                        <Row className="mainCard mt-3 ">
                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            className="mainCardAreaInside"
                          >
                            <div className="card mb-5 ">
                              <Row className="p-3">
                                <Col md={1} sm={2}>
                                  <div className="profilePic">
                                    <img src="https://plus.unsplash.com/premium_photo-1661964217492-70800dc09cac?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzM3x8fGVufDB8fHx8fA%3D%3D" />
                                  </div>
                                </Col>
                                <Col md={10} sm={8}>
                                  <Col md={12}>
                                    <div
                                      className="cardTop"
                                      style={{
                                        display: "flex",
                                        // justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="nameSection">
                                        <p>{singleData?.name}</p>
                                      </div>
                                      <div className="badgebootstrap ">
                                        {singleData?.service?.name === "Buy" ? (
                                          <Badge bg="success">
                                            {singleData?.service?.name}
                                          </Badge>
                                        ) : singleData?.service?.name ===
                                          "Sell" ? (
                                          <Badge bg="danger">
                                            {singleData?.service?.name}
                                          </Badge>
                                        ) : singleData?.service?.name ===
                                          "Rent" ? (
                                          <Badge bg="secondary">
                                            {singleData?.service?.name}
                                          </Badge>
                                        ) : singleData?.service?.name ===
                                          "Interior Design" ? (
                                          <Badge bg="primary">
                                            {singleData?.service?.name}
                                          </Badge>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="timeAgo ">
                                        <TimeAgo
                                          timestamp={singleData?.created_on}
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col md={12}>
                                    <Row>
                                      <Col md={12} className="mt-1">
                                        <div className="allCard">
                                          <Row>
                                            <Col md={4} sm={6} className="mt-2">
                                              <div className="smallCard">
                                                <p>
                                                  State:{" "}
                                                  {
                                                    singleData?.city?.state
                                                      ?.name
                                                  }{" "}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col md={4} sm={6} className="mt-2">
                                              <div className="smallCard">
                                                <p>
                                                  City: {singleData?.city?.name}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col md={4} sm={6} className="mt-2">
                                              <div className="smallCard">
                                                <p>
                                                  Area: {singleData?.area?.name}{" "}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col md={4} sm={6} className="mt-2">
                                              <div className="smallCard extraSmall">
                                                <p>
                                                  {" "}
                                                  Email:{" "}
                                                  {
                                                    singleData?.email_address
                                                  }{" "}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col md={4} sm={6} className="mt-2">
                                              <div className="smallCard extraSmall">
                                                <p>
                                                  {" "}
                                                  Mobile Number:{" "}
                                                  {
                                                    singleData?.mobile_number
                                                  }{" "}
                                                </p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col md={12} className="mt-3">
                                        <p> {parse(`${singleData?.query}`)}</p>
                                      </Col>
                                      <Col md={12} className="mt-3">
                                        <div className="comment-section">
                                          {" "}
                                          <Link to={`/query/${singleData?.id}`}>
                                            <FaComment
                                              className="faComment"
                                              style={{
                                                backgroundColor: "#f5f5f5",
                                              }}
                                            />
                                            <span className="comment-count">
                                              10
                                            </span>
                                          </Link>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Col>
                                <Col md={1} sm={2}>
                                  <div className="menu">
                                    {/* <FaEllipsisV /> */}
                                    <DropdownButton
                                      id="dropdown-basic-button"
                                      title={<FaEllipsisV />}
                                      variant="Secondary"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* <Dropdown.Item>
                                      <Link to={`/post/${singleData?.id}`}>
                                        View
                                      </Link>
                                    </Dropdown.Item> */}
                                      {console.log(
                                        "singleData id is ",
                                        singleData?.user?.id
                                      )}
                                      {(singleData?.user?.id === userId ||
                                        userType === "SUPER_ADMIN") && (
                                        <>
                                          {/* <Dropdown.Item>
                                            <Link
                                              to={`/query/${singleData?.id}/edit`}
                                            >
                                              Edit
                                            </Link>
                                          </Dropdown.Item> */}
                                          <Dropdown.Item>
                                            <Link
                                              to={``}
                                              onClick={() =>
                                                handleDelete(singleData?.id)
                                              }
                                            >
                                              Delete
                                            </Link>
                                          </Dropdown.Item>
                                        </>
                                      )}
                                    </DropdownButton>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      ))}
                  </div>

                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Query;
