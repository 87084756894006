import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import "swiper/css";
import Col from "react-bootstrap/Col";
import badroom from "../../assets/images/icon-badroom-dark.svg";
import bathroom from "../../assets/images/icon-bathroom.svg";
import area from "../../assets/images/icon-area.svg";

import OfferCard from "./cards/offerCard/OfferCard";

import axios from "axios";
import countryIcon from "../../assets/images/country.png";
import rupeeIcon from "../../assets/images/rupee-indian.png";
const Offer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allProperty, setAllProperty] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${baseUrl}/all/property/latestList`);
      console.log(response);

      if (response.data.statusType === "SUCCESS") {
        setAllProperty(response.data.properties);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    try {
      fetchData();
      setIsLoading(true);

      setIsLoading(false);
    } catch (error) {}
  }, []);
  return (
    <>
      <section className="all-section">
        <Container>
          <Row>
            {/* <div className="d-flex align-items-center justify-content-between mb-3 title-head-main">
              <h2 className="text-head-title">Top offers</h2>
              <Link to="#" className="btn btn-link fw-normal ms-md-3 pb-0">
                View all
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
              </Link>
            </div> */}
          </Row>
          <Row>
            {allProperty.slice(0, 4).map((singleProperty) => (
              <Col lg={3} md={6} className="p-2">
                {/* <OfferCard
                  title={singleProperty?.name}
                  description={singleProperty?.description}
                  // imageUrl={singleProperty?.images[0]?.image_url}
                  btnText="See"
                  type={singleProperty?.type?.name}
                  badroom={badroom}
                  bedroomsSize={singleProperty?.structure?.bed_rooms}
                  bathroom={bathroom}
                  bathroomSize={singleProperty?.structure?.bath_rooms}
                  area={area}
                  areaSize={singleProperty.area.city.name}
                  countryIcon={countryIcon}
                  country={singleProperty?.area?.city?.state?.country?.name}
                  price={singleProperty.price}
                  rupee={rupeeIcon}
                  to={"property-details"}
                  id={singleProperty.id}
                /> */}
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Offer;
