import React from "react";
import { Col, Container, Row, Pagination } from "react-bootstrap";

const AllMessage = ({ title }) => {
  return (
    <>
      <div>
        <Container>
          <Row>
            <Col>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  zIndex: 1,
                }}
              >
                {title}...
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AllMessage;
