// Template Name:preferred
import React, { useState, useEffect } from "react";
import TabMenu from "./TabMenu";
import { Col, Container, Row, Button, Spinner } from "react-bootstrap";

import axios from "axios";
import Api from "../../components/api/Api";
import Slider from "rc-slider";
import { useSelector } from "react-redux";
import Select from "react-select";
import PropertySingleCard from "../../components/Property/PropertySingleCard";
import Pagination from "../../components/pagination/Pagination";
import { Formik, Form, Field } from "formik";

const Tab2 = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const [isOpen, setIsOpen] = useState(false);
  const [allProperty, setAllProperty] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Property Type");
  const [areaInput, setAreaInput] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");

  const [propertyPurposes, setPropertyPurposes] = useState([]);

  const [selectedPurpose, setSelectedPurpose] = useState("buy");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [sortBy, setSortBy] = useState("Price(low to high)");
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedState, setSelectedState] = useState("");
  const [selectedArea, setSelectedArea] = useState([]);
  const [localPrice, setLocalPrice] = useState([0, 1000000000]);
  const [propertyType, setPropertyType] = useState([]);

  const [assetUrl, setAssetUrl] = useState("");

  const [showAreaInput, setShowAreaInput] = useState(false);
  const [newArea, setNewArea] = useState("");
  const authToken = loggedInUser?.token;
  const [propertySubtypes, setPropertySubtypes] = useState([]);
  const [selectedPropertySubtypes, setSelectedPropertySubtypes] = useState([]);

  const handleSliderChange = (value) => {
    setMinValue(value[0]);
    setMaxValue(value[1]);
  };

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [isPurposeOpen, setIsPurposeOpen] = useState(false);
  const [selectedPurposeOption, setSelectedPurposeOption] =
    useState("Property Purpose");

  const handlePurposeOptionClick = (value) => {
    setSelectedPurposeOption(value);
    setIsPurposeOpen(false);
    setIsButtonDisable(true);
  };

  const [value, setValue] = useState(50);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const getPurposeApi = Api({
    url: "property/purposes",
  });
  const getTypesApi = Api({
    url: "property/types",
  });

  const getTypes = async () => {
    try {
      const responseData = await getTypesApi.fetchData();

      if (responseData.data.statusType === "SUCCESS") {
        setPropertyTypes(responseData?.data?.propertyTypes);
      } else {
        setPropertyTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPurpose = async () => {
    try {
      const responseData = await getPurposeApi.fetchData();

      if (responseData.data.statusType === "SUCCESS") {
        setPropertyPurposes(responseData?.data?.propertyPurposes);
      } else {
        setPropertyPurposes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      // fetchData();
      setIsLoading(true);
      getPurpose();
      getTypes();

      setIsLoading(false);
    } catch (error) {}
  }, []);

  useEffect(() => {
    const sortedArray = sortPropertyByPrice(allProperty, sortBy);
    setAllProperty(sortedArray);
  }, [sortBy]);

  useEffect(() => {
    if (query === "") {
      setResults([]);
    } else {
      const filteredResults = allCities?.filter((city) =>
        city.name.toLowerCase().includes(query.toLowerCase())
      );
      setResults(filteredResults);
    }
  }, [query, allCities]);
  useEffect(() => {
    searchFetchData();
  }, [currentPage]);
  useEffect(() => {}, [allProperty]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/states/${countryId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/cities/${stateId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        const fetchArea = async (cityId) => {
          if (!cityId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/areas/${cityId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              const areaData = response.data.areas.map((area) => ({
                label: area.name,
                value: area.name,
              }));
              setAreaInput([{ label: "Other", value: "other" }, ...areaData]);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(1);
        fetchCities(selectedState);
        fetchArea(selectedCity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedState, selectedCity, selectedArea, loggedInUser?.token]);
  const postData = {
    page_number: 1,
    page_size: 20,
    order_by: "ASC",
  };

  useEffect(() => {
    const fetchSubtypes = async (propertyTypeId) => {
      if (!propertyTypeId) {
        setPropertySubtypes([]);
        return;
      }
      try {
        const response = await axios.post(
          `${baseUrl}/property/sub/types/${propertyTypeId}`,
          postData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(response);
        if (response.data.statusType === "SUCCESS") {
          setPropertySubtypes(response.data.propertySubTypes);
        } else {
          setPropertySubtypes([]);
        }
      } catch (error) {
        console.error("Error fetching property subtypes:", error);
        setPropertySubtypes([]);
      }
    };

    fetchSubtypes(selectedPropertyType);
  }, [selectedPropertyType, token]);
  const sortPropertyByPrice = (properties, sortBy) => {
    let sortedArray = [];
    switch (sortBy) {
      case "Price(low to high)":
        sortedArray = [...properties].sort((a, b) => a.price - b.price);
        break;
      case "Price(high to low)":
        sortedArray = [...properties].sort((a, b) => b.price - a.price);
        break;
      default:
        sortedArray = properties;
        break;
    }
    return sortedArray;
  };

  const sortedProperty = sortPropertyByPrice("Price(low to high)");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const searchFetchData = async (values, pageNumber = 1, pageSize = 10) => {
    try {
      setIsLoading(true);
      const findPropertyTypeeById = propertyTypes.find(
        (singlePropertyType) => singlePropertyType.id === +values.propertyType
      );
      const findState = states.find((state) => state.id === +values.user_state);
      const findCity = cities.find((city) => city.id === +values.user_cities);

      const purposeData =
        values.propertyPurpose === "Buy"
          ? "Sell"
          : values.propertyPurpose === "Sell"
          ? "Buy"
          : values.propertyPurpose === "Rent"
          ? "Rent"
          : values.propertyPurpose === "Promotional"
          ? "Promotional"
          : "";
      const response = await axios.post(
        `${baseUrl}/property/list/preferred`,
        {
          type: findPropertyTypeeById?.name,
          purpose: purposeData,
          price: values.price[1], // Assuming maxValue is part of the price range
          state: findState?.name,
          city: findCity?.name,
          area: selectedArea.map((area) => area.label).join(", "),
          page_number: pageNumber,
          page_size: pageSize,
          order_by: "ASC",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.statusType === "SUCCESS") {
        setAllProperty(response.data.properties);
        setAssetUrl(response.data.assetUrl);
        setTotalPages(response.data.totalPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearch = () => {
    const filteredResults = allCities?.filter((city) =>
      city.name.toLowerCase().includes(query.toLowerCase())
    );

    setResults(filteredResults);
  };
  const handleSearchFormSubmit = (e) => {
    e.preventDefault();

    searchFetchData();
  };

  const handleAddArea = async () => {};
  const [formValues, setFormValues] = useState({
    propertyPurpose: "Buy",
    user_state: "",
    user_cities: "",
    selectedArea: [],
    propertyType: "",
    propertySubtype: "",
    price: [1000, 100000],
  });

  const formatPrice = (price) => {
    if (price < 100000) {
      // If price is less than 1 lakh, return it as is
      return `₹${price.toLocaleString()}`; // Format with comma
    } else if (price < 10000000) {
      // If price is 1 lakh or more, convert to lakhs
      const lakhs = (price / 100000).toFixed(2); // Show up to 2 decimal places
      return `₹${lakhs}L`; // Append 'L' for lakh
    } else {
      // If price is 1 lakh or more, convert to lakhs
      const lakhs = (price / 10000000).toFixed(2); // Show up to 2 decimal places
      return `₹${lakhs}Cr`; // Append 'L' for lakh
    }
  };

  // Function for slider tooltip formatting

  const handleTipFormatter = (value) => {
    return formatPrice(value);
  };
  useEffect(() => {
    // Adjust slider max value based on selected purpose
    if (selectedPurpose === "Rent") {
      formatPrice(1000000); // Max value is 10 lakh
    } else {
      formatPrice(1000000000); // Default max value
    }
  }, [selectedPurpose, localPrice]);
  return (
    <>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <Container>
          <TabMenu />

          <Col md={12} className="mt-2">
            <div className="widget">
              <div className="widget-boxed main-search-field">
                {/* <h3 className="property-heading-h">Preferred</h3> */}
                <div className="widget-boxed-header">
                  <h4>Search Your Desired Property</h4>
                </div>
                <div className="trip-search">
                  <Formik
                    initialValues={formValues}
                    onSubmit={(values) => {
                      searchFetchData(values);
                      setFormValues(values);
                    }}
                  >
                    {({ setFieldValue, values }) => (
                      <Form className="form">
                        <Row>
                          <Col md={12}>
                            <div className="form-nice-select location">
                              <div className="radio-container">
                                <p>
                                  I Wish To{" "}
                                  <font
                                    className={"text-danger"}
                                    style={{ fontSize: "1.4rem" }}
                                  >
                                    *
                                  </font>
                                </p>
                                <div className="radio-group d-flex align-items-center justify-content-between">
                                  {propertyPurposes.map((purpose) => (
                                    <div
                                      key={purpose?.id}
                                      className="form-check"
                                    >
                                      <Field
                                        type="radio"
                                        name="propertyPurpose"
                                        id={`purpose-${purpose.id}`}
                                        value={purpose?.name}
                                        checked={
                                          values.propertyPurpose ===
                                          purpose?.name
                                        }
                                        onChange={() => {
                                          setFieldValue(
                                            "propertyPurpose",
                                            purpose?.name
                                          );
                                          setSelectedPurpose(purpose?.name);
                                          purpose.name === "Rent"
                                            ? setLocalPrice([0, 1000000])
                                            : setLocalPrice([0, 1000000000]);
                                        }}
                                        className="form-check-input"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`purpose-${purpose.id}`}
                                      >
                                        {purpose?.name}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col md={4} className="mt-2">
                            <div className="">
                              <label
                                htmlFor="user_state"
                                className="form-label"
                              >
                                State
                              </label>
                              <Field
                                as="select"
                                name="user_state"
                                className="form-select"
                                onChange={(e) => {
                                  setSelectedState(e.target.value);
                                  setFieldValue("user_state", e.target.value);
                                }}
                                value={values.user_state}
                              >
                                <option value="">-- Select State --</option>
                                {states.map((state) => (
                                  <option key={state.id} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Col>

                          <Col md={4} className="mt-2">
                            <div className="">
                              <label
                                htmlFor="user_cities"
                                className="form-label"
                              >
                                City
                              </label>
                              <Field
                                as="select"
                                name="user_cities"
                                className="form-select"
                                onChange={(e) => {
                                  setSelectedCity(e.target.value);
                                  setFieldValue("user_cities", e.target.value);
                                }}
                                value={values.user_cities}
                              >
                                <option value="">-- Select City --</option>
                                {cities.map((city) => (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Col>

                          <Col md={4} className="mt-2">
                            <div className="">
                              <label
                                htmlFor="selectedArea"
                                className="form-label"
                              >
                                Area
                              </label>
                              <Select
                                options={areaInput}
                                isMulti
                                onChange={(option) => {
                                  if (
                                    option.some((opt) => opt.value === "other")
                                  ) {
                                    setShowAreaInput(true);
                                    setSelectedArea(option);
                                    setFieldValue("selectedArea", option);
                                  } else {
                                    setSelectedArea(option);
                                    setShowAreaInput(false);
                                    setFieldValue("selectedArea", option);
                                  }
                                }}
                                value={selectedArea}
                                placeholder="Select Area"
                                menuPortalTarget={document.body}
                                disabled={!selectedCity}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 999999,
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                  }),
                                }}
                              />
                              {showAreaInput && (
                                <div className="mt-3">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Enter new area"
                                    value={newArea}
                                    onChange={(e) => setNewArea(e.target.value)}
                                  />
                                  <Button
                                    variant="primary"
                                    onClick={handleAddArea}
                                    disabled={isLoading || !newArea}
                                  >
                                    {isLoading ? "Adding..." : "Add Area"}
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "1rem" }}
                                    variant="secondary"
                                    onClick={() => setShowAreaInput(false)}
                                    className="ml-2"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              )}
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="form-nice-select location">
                              <div className="dropdown-container">
                                <p>Property Type</p>
                                <Field
                                  as="select"
                                  name="propertyType"
                                  className="form-select"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "propertyType",
                                      e.target.value
                                    );
                                    setSelectedPropertyType(e.target.value);
                                  }}
                                  value={values.propertyType}
                                >
                                  <option value="">
                                    Select Property Subtype
                                  </option>
                                  {propertyTypes.map((propertyType) => (
                                    <option
                                      key={propertyType.id}
                                      value={propertyType.id}
                                    >
                                      {propertyType.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </div>
                          </Col>

                          <Col md={4} className="mt-4">
                            <label
                              htmlFor="propertySubtype"
                              className="form-label"
                            >
                              Property Subtype
                            </label>
                            <Field
                              as="select"
                              name="propertySubtype"
                              className="form-select"
                              id="propertySubtype"
                              onChange={(e) => {
                                setFieldValue(
                                  "propertySubtype",
                                  e.target.value
                                );
                              }}
                              value={values.propertySubtype}
                            >
                              <option value="">Select Property Subtype</option>
                              {propertySubtypes.map((subtype) => (
                                <option key={subtype.id} value={subtype.name}>
                                  {subtype.name}
                                </option>
                              ))}
                            </Field>
                          </Col>

                          <Col md={4}>
                            <div className="form-nice-select location">
                              <div className="range-slider">
                                <p>₹ Price</p>
                                {console.log(selectedPurpose === "Rent")}
                                <Slider
                                  min={0}
                                  max={
                                    selectedPurpose === "Rent"
                                      ? 1000000
                                      : 1000000000
                                  } // Dynamic max based on selectedPurpose
                                  step={10000}
                                  range
                                  value={localPrice}
                                  onChange={(value) => {
                                    setFieldValue("price", value);
                                    setLocalPrice(value);
                                  }}
                                  tipFormatter={handleTipFormatter}
                                  tipProps={{
                                    placement: "top",
                                    visible: true,
                                    getTooltipContainer: () =>
                                      document.getElementById(
                                        "slider-container"
                                      ),
                                  }}
                                />
                                <div className="ui-slider-handle">
                                  <div className="first-slider-value">
                                    {formatPrice(localPrice[0])}
                                  </div>
                                  <div className="last-slider-value">
                                    {selectedPurpose === "Rent"
                                      ? formatPrice(localPrice[1])
                                      : formatPrice(localPrice[1])}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <button className="btn btn-primary mt-3" type="submit">
                          Search
                        </button>
                      </Form>
                    )}
                  </Formik>
                  <Row className="mt-5 mb-5">
                    {allProperty?.length > 0 ? (
                      <>
                        <h3>Search Result</h3>
                        <Row>
                          {allProperty.length > 0 && (
                            <PropertySingleCard
                              data={allProperty}
                              assetUrl={assetUrl}
                            />
                          )}
                          <div className="mt-5">
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          </div>
                        </Row>
                      </>
                    ) : (
                      <>
                        <h3
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          No Result To Show
                        </h3>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Container>
      )}
    </>
  );
};

export default Tab2;
