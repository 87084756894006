import React, { useState } from "react";
import { FaFile, FaRegTrashAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import Styles from "./Style.module.css";
import ReactPlayer from "react-player";
const FileUploadField = ({ field, form, showDropzone = true }) => {
  const { setFieldValue } = form;
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    const updatedFiles = [...selectedFiles, ...acceptedFiles];
    setSelectedFiles(updatedFiles);
    setFieldValue(field.name, updatedFiles);
  };

  const removeFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    setFieldValue(field.name, updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const isImage = (file) => {
    return file.type.startsWith("image/");
  };
  const isVideo = (file) => file.type.startsWith("video/");

  return (
    <>
      {showDropzone && (
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{
            padding: "2rem 2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            background: "#EFF4FC",
          }}
        >
          <input {...getInputProps()} />
          <FaFile
            style={{
              fontSize: "4rem",
            }}
            className="text-primary"
          />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
      )}

      <div className={`${Styles.selectedImages}`}>
        {selectedFiles.map((file, index) => (
          <div
            key={index}
            className={
              isImage(file)
                ? `${Styles.selectedImage}`
                : `${Styles.selectedFile}`
            }
          >
            {isImage(file) ? (
              <img
                src={URL.createObjectURL(file)}
                alt={`Selected ${file.name}`}
                className={`${Styles.selectedImage}`}
              />
            ) : isVideo(file) ? (
              // <video
              //   src={URL.createObjectURL(file)}
              //   controls
              //   className={`${Styles.selectedVideo}`}
              //   style={{ maxWidth: "100%", maxHeight: "auto" }}
              // >
              //   Your browser does not support the video tag.
              // </video>
              <ReactPlayer
                url={URL.createObjectURL(file)}
                loop={true}
                style={{ maxWidth: "100%", maxHeight: "auto" }}
                playing={true}
                muted={true}
              />
            ) : (
              <>
                <FaFile className={Styles.fileIcon} />
                <p className={Styles.fileName}>{file.name}</p>
              </>
            )}
            <button
              type="button"
              className={`${Styles.button}`}
              onClick={() => removeFile(index)}
            >
              <FaRegTrashAlt
                style={{
                  color: "#ffffff",
                }}
              />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default FileUploadField;
