import React from "react";
import { Link } from "react-router-dom";
import { StyledLocationItem } from "./Style";

const PbcCard = ({ title, properties, imageUrl }) => (
  <StyledLocationItem className="location-item">
    <div className="location-image">
      <figure>
        <img src={imageUrl} alt={title} />
      </figure>
    </div>
    <div className="location-content">
      <div className="location-header">
        <h3>{title}</h3>
        <p>{properties} Properties</p>
      </div>
      <div className="location-footer">
        <Link
          to={`/login`}
          className="btn-default"
        >
          See More
        </Link>
      </div>
    </div>
  </StyledLocationItem>
);

export default PbcCard;
