import React from "react";
import { useSelector } from "react-redux";

const AdminProtected = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  if (
    (user &&
      user?.userDetails &&
      user?.statusType === "SUCCESS" &&
      user.userDetails?.userType === "SUPER_ADMIN") ||
    user?.userDetails?.userType === "ALL_HOME_DESIRE"
  ) {
    return children;
  }

  return null;
};

export default AdminProtected;
