import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const EditPropertyPurpose = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const { propertyPurposeId } = useParams();

  const editPostApi = Api({
    url: `property/editPurpose`,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getUserTypeApi = Api({
    url: `property/purpose/${propertyPurposeId}`,
  });
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await getUserTypeApi.fetchDataBySingleId(true);
        if (response?.data?.statusType === "SUCCESS") {
          const postData = response.data.propertyPurpose;

          setFormData({
            name: postData.name,
            description: postData.description,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const data = {
        id: propertyPurposeId,
        name: formData.name,
        description: formData.description,
      };
      console.log(data);

      const responseData = await editPostApi.editData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Property Purpose updated successfully", { type: "success" });
        setTimeout(() => {
          navigate("/property-purpose");
        }, 3000);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      showToast("Error Occurred", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Purposes", link: "/property-purpose" },
    { label: "Edit Property Purpose", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Edit Property Purpose Details"
              description="Edit property purpose details section"
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      placeholder="Enter Description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
              </Row>

              <Button variant="primary" type="submit" className="mt-5 mb-5">
                {isLoading ? <Spinner loading={true} /> : "Update"}
              </Button>
              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-5 mt-5"
                to={"/property-purpose"}
              >
                Back
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditPropertyPurpose;
