import React, { useState } from "react";
import {
  Col,
  Badge,
  Card,
  Carousel,
  Tooltip,
  OverlayTrigger,
  Alert,
  Modal,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  FaBookmark,
  FaEye,
  FaEyeSlash,
  FaPlus,
  FaUserPlus,
} from "react-icons/fa";
import "./property.style.css";
import Api from "../api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import axios from "axios";
import Image from "../../../assets/images/noimage.jpg";
import { setFormData } from "../../../features/formData/formSlice";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const PropertySingleCard = ({ data, assetUrl, fetchPropertyData }) => {
  const { userDetails, token } = useSelector((state) => state.auth.user);
  const userId = userDetails?.id;
  const [datas, setDatas] = useState([]);
  const dispatch = useDispatch();
  const userType = userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [propertyData, setPropertyData] = useState(data);
  const [itemIdToPublish, setItemIdToPublish] = useState(null);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handlePublishModalClose = () => setShowPublishModal(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = (itemId) => {
    setShowDeleteAlert(true);
    setItemIdToDelete(itemId);
  };

  const handlePublishAlertClose = () => setShowPublishAlert(false);
  const handlePublishAlertShow = (singleObject) => {
    setShowPublishAlert(true);
    setItemIdToPublish(singleObject);
  };
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const togglePropertyVisibility = () => {
    handleHideData(itemIdToPublish);
  };
  const handlePublishModalShow = (singleObject) => {
    setShowPublishModal(true);
    setItemIdToPublish(singleObject);
  };

  const handleDeleteModalShow = (itemId) => {
    setShowDeleteModal(true);
    setItemIdToDelete(itemId);
  };
  const dataFetch = async () => {
    await fetchPropertyData();
  };
  const handleHideData = async (item) => {
    console.log("hello from handle publish");
    console.log(item);
    try {
      const response = await axios.put(
        `${baseUrl}/property/publish/status`,
        {
          lead_id: item.lead_id,
          is_publish: !item.published,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.statusType === "SUCCESS") {
        showToast("Property Updated successfully", {
          type: "success",
        });
        handlePublishModalClose();
        await dataFetch();
      }
      console.log("your response for updating status", response);
    } catch (error) {
      console.error("Error 'updating ' item:", error);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const filterData = propertyData.filter(
        (singlePropertyData) => singlePropertyData.lead_id === itemIdToDelete
      );
      let response;

      if (filterData[0]?.id === 0) {
        console.log("hello form here", itemIdToDelete);
        response = await axios.delete(
          `${baseUrl}/property/lead/delete/${itemIdToDelete}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await axios.delete(
          `${baseUrl}/property/deleteProperty/${itemIdToDelete}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setDatas(datas.filter((item) => item.id !== itemId));
        showToast("Property deleted successfully", {
          type: "success",
        });
        handleDeleteModalClose();
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
        showToast("Unable to delete property", {
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const addFavouriteApi = Api({ url: `property/favorite/add` });

  const handleFollowOption = async (id, isFavorite) => {
    const dataId = { lead_id: +id };
    console.log(isFavorite);
    if (isFavorite) {
      try {
        const removeFavourite = await axios.delete(
          `${baseUrl}/property/favorite/delete/${isFavorite}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (removeFavourite.data.statusType === "SUCCESS") {
          showToast("Property Removed From Favourite", { type: "success" });
          const updatedData = propertyData.map((singleData) =>
            singleData.lead_id === +id
              ? {
                  ...singleData,
                  is_favorite: !singleData.is_favorite,
                  favorite_id: null,
                }
              : singleData
          );
          setPropertyData(updatedData);
        } else {
          showToast("Error Removing Property From Favourite", {
            type: "error",
          });
        }
      } catch (error) {
        showToast("Error Removing Property From Favourite", { type: "error" });
      }
    } else {
      try {
        const addFavourite = await addFavouriteApi.postData(dataId);
        console.log(addFavourite);
        if (addFavourite.data.statusType === "SUCCESS") {
          const updatedData = propertyData.map((singleData) =>
            singleData.lead_id === +id
              ? {
                  ...singleData,
                  is_favorite: !singleData.is_favorite,
                  favorite_id: addFavourite.data.favorite.id,
                }
              : singleData
          );
          setPropertyData(updatedData);
          showToast("Property Added To Favourite", { type: "success" });
        } else {
          showToast("Error Adding Property To Favourite", { type: "error" });
        }
      } catch (error) {
        showToast("Error Adding Property To Favourite", { type: "error" });
      }
    }
  };

  const renderTooltip = (note) => <Tooltip id="button-tooltip">{note}</Tooltip>;
  const getBadgeColor = (purpose) => {
    switch (purpose) {
      case "Buy":
        return "border border-success";
      case "Sell":
        return "border border-danger";
      case "Rent":
        return "border border-secondary";
      default:
        return "border";
    }
  };

  const addFormData = (id) => {
    const filterData = data.filter((singleData) => singleData?.id === +id);

    console.log(filterData);
    const formData = {
      propertyPurpose: filterData[0].purpose?.id,
      propertyType: filterData[0].type?.id,
      city: filterData[0]?.city?.id,
      state: filterData[0]?.state?.id,
      area: filterData[0]?.area?.name,
      lead_id: filterData[0]?.lead_id,
      name: filterData[0]?.title,
      price: filterData[0]?.budget,
      description: filterData[0]?.description,
      country_name: 1,
      propertySubtype: +filterData[0]?.sub_type?.id,
      featured_property: filterData[0].featured_property,
      images: filterData[0].images,
    };

    console.log(formData);
    dispatch(setFormData(formData));
  };

  return (
    <>
      <Notification />
      <div style={{ padding: "0rem 1rem" }}>
        <Modal
          show={showDeleteModal}
          onHide={handleDeleteModalClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this property?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleDeleteModalClose}
              variant="outline-danger"
              className="me-3"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="danger">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showPublishModal}
          onHide={handlePublishModalClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Publish</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to publish this property?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handlePublishModalClose}
              variant="outline-secondary"
              className="me-3"
            >
              Cancel
            </Button>
            <Button onClick={togglePropertyVisibility} variant="success">
              {console.log(itemIdToPublish)}
              {itemIdToPublish?.is_published ? "Un Publish" : "Publish"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {propertyData?.map((singleData) => (
        <Col lg={6} md={12} sm={12} key={singleData.id}>
          <div
            className={`mainPropertyCard ${getBadgeColor(
              singleData?.purpose?.name
            )}`}
          >
            {/* {singleData?.status?.name === "New" && (
              <div className="c-ribbon c-ribbon--top c-ribbon--left">
                {" "}
                {singleData?.status?.name}
              </div>
            )} */}
            <div className="mainPropertyCardFirst">
              <div className="d-flex justify-content-start align-items-center topNavbar">
                <Badge
                  bg={
                    singleData?.purpose?.name === "Buy"
                      ? "warning"
                      : singleData?.purpose?.name === "Sell"
                      ? "warning"
                      : singleData?.purpose?.name === "Rent"
                      ? "warning"
                      : "warning"
                  }
                  style={{ fontSize: "14px" }}
                >
                  {singleData?.purpose?.name}
                </Badge>
                <Badge
                  bg="primary"
                  style={{ fontSize: "14px", marginLeft: "1rem" }}
                >
                  {singleData?.type?.name}
                </Badge>
                <Badge
                  bg="success"
                  style={{ fontSize: "14px", marginLeft: "1rem" }}
                >
                  {singleData?.sub_type?.name}
                </Badge>
                {singleData?.is_favorite && (
                  <Badge
                    bg="success"
                    style={{ fontSize: "14px", marginLeft: "1rem" }}
                  >
                    Followed
                  </Badge>
                )}
              </div>
            </div>
            <div className="mainPropertyCardSecond">
              <div className="mainPropertySecondFirst">
                <div className="swiper-image">
                  {singleData?.images?.length > 0 ? (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="swiper"
                    >
                      {singleData?.images?.map((singleImage) => (
                        <SwiperSlide style={{ borderRadius: "0.35rem" }}>
                          {singleImage?.image_url?.includes("mp4") ? (
                            <video
                              autoPlay
                              muted
                              loop
                              controls
                              className="main-video"
                            >
                              <source
                                src={`https://images.allhomedesire.in/${singleImage.image_url}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              className="main-image"
                              src={`https://images.allhomedesire.in/${singleImage.image_url}`}
                              alt="Property"
                            />
                          )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="swiper"
                    >
                      <SwiperSlide>
                        <img
                          src={Image}
                          alt="no image"
                          className="main-image"
                        />
                      </SwiperSlide>
                    </Swiper>
                  )}
                </div>
                <div className="image-overlay-pro"></div>
                <div>
                  {" "}
                  <div className="property-details">
                    <div className="property-location">
                      <div className="badge-like">
                        <span className="location-label">State:</span>
                        <span className="location-value">
                          {singleData?.state?.name}
                        </span>
                      </div>
                      <div className="badge-like middleBadge">
                        <span className="location-label">City:</span>
                        <span className="location-value">
                          {singleData?.city?.name}
                        </span>
                      </div>
                      <div className="badge-like">
                        <span className="location-label">Area:</span>
                        <span className="location-value">
                          {singleData?.area?.name}
                        </span>
                      </div>
                    </div>
                    <div>
                      {/* <h4 className="property-title">₹{singleData?.budget}</h4> */}
                      <h4 className="property-title">{singleData?.title}</h4>
                      <div className="property-description">
                        {singleData?.description.length > 100 ? (
                          <>
                            {parse(
                              `${singleData?.description?.substring(0, 200)}...`
                            )}
                            {singleData?.id === 0 ? (
                              <Link
                                variant="outline-primary"
                                className="p-0 text-primary fw-bold"
                                to={`/post/${singleData?.lead_id}`}
                              >
                                More
                              </Link>
                            ) : (
                              <Link
                                variant="link"
                                className="p-0 text-primary fw-bold"
                                to={`/property/${singleData?.id}`}
                              >
                                More
                              </Link>
                            )}
                          </>
                        ) : (
                          parse(singleData?.description)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="property-footer">
                <p class="property-price">₹{singleData?.budget}</p>

                <div className="actions-btn">
                  {singleData?.customer?.id !== userId && (
                    <Link
                      variant="outline-success"
                      className="btn btn-success btn-top-card"
                      onClick={() =>
                        handleFollowOption(
                          singleData?.lead_id,
                          singleData?.favorite_id
                        )
                      }
                      title={singleData?.is_favorite ? "Unfollow" : "Follow"}
                    >
                      <i
                        className={`fa-solid fa-user-${
                          singleData?.is_favorite ? "minus" : "plus"
                        }`}
                      ></i>
                    </Link>
                  )}
                  {singleData?.id === 0 ? (
                    <Link
                      variant="outline-success"
                      className="btn btn-success "
                      to={`/post/${singleData?.lead_id}`}
                    >
                      <i class="fa-solid fa-expand"></i>
                    </Link>
                  ) : (
                    <Link
                      variant="outline-success"
                      className="btn btn-success "
                      to={`/property/${singleData?.id}`}
                      title="View"
                    >
                      <i class="fa-solid fa-expand"></i>
                    </Link>
                  )}

                  {(singleData?.customer?.id === userId ||
                    userType === "SUPER_ADMIN") && (
                    <>
                      <Button
                        bg={singleData?.is_published ? "success" : "danger"}
                        onClick={() => handlePublishModalShow(singleData)}
                        title={
                          singleData?.is_published || singleData?.published
                            ? "UnPublish"
                            : "Publish"
                        }
                      >
                        {singleData?.is_published || singleData?.published ? (
                          <FaEyeSlash />
                        ) : (
                          <i class="fa-solid fa-upload"></i>
                        )}
                        {/* {singleData?.is_published || singleData?.published
                    ? "Unpublish"
                    : "Publish"} */}
                      </Button>
                      {singleData?.id === 0 ? (
                        <>
                          <Link
                            variant="outline-danger"
                            className="btn btn-warning  btn-top-card"
                            to={`/post/${singleData?.lead_id}/edit`}
                            title={"Edit"}
                          >
                            <i className="fa fa-edit"></i>
                          </Link>
                          <Link
                            variant="outline-danger"
                            className="btn btn-danger  btn-top-card"
                            title={"Delete"}
                          >
                            <i
                              class="fa-solid fa-trash"
                              onClick={() =>
                                handleDeleteModalShow(singleData?.lead_id)
                              }
                            ></i>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            variant="outline-danger"
                            className="btn btn-warning  btn-top-card"
                            to={`/property/${singleData?.id}/edit`}
                            title={"Edit"}
                          >
                            <i className="fa fa-edit"></i>
                          </Link>
                          <Link
                            variant="outline-danger"
                            className="btn btn-danger  btn-top-card"
                            title={"Delete"}
                          >
                            <i
                              class="fa-solid fa-trash"
                              onClick={() =>
                                handleDeleteModalShow(singleData?.id)
                              }
                            ></i>
                          </Link>
                        </>
                      )}
                      {singleData?.id === 0 && (
                        <>
                          {singleData?.property_id != 0 &&
                          singleData?.property_id != null ? (
                            <Link
                              className="btn btn-primary"
                              to={`property/${singleData?.property_id}`}
                            >
                              View Property
                            </Link>
                          ) : (
                            <div
                              className="btn btn-primary text-white"
                              onClick={() => {
                                addFormData(singleData?.id);
                              }}
                              title="Add Property"
                            >
                              <Link
                                className="text-white"
                                to={`/property/add-property`}
                              >
                                <FaPlus />
                              </Link>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default PropertySingleCard;
