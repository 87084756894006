import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";

import { Button, Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Api from "../../components/api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import axios from "axios";
const Preference = () => {
  const [cityOptions, setCityOptions] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [areaSuggestions, setAreaSuggestions] = useState([]);
  const [areaInput, setAreaInput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [allCity, setAllCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedArea, setSelectedArea] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [purpose, setPurpose] = useState("");
  const maxCitiesLimit = 3;
  const maxAreasLimit = 5;
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const loggedInUser = useSelector((state) => state.auth.user);
  const authToken = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const getCities = Api({
    url: `admin/cities`,
  });

  const addPreferenceApi = Api({
    url: `admin/addPreferences`,
  });
  const getAreaApi = Api({
    url: `admin/area/id/${selectedCity}`,
  });
  const initialValues = {
    id: "",
    propertyPurpose: {
      id: "",
    },
    user_cities: {
      id: "",
    },
    user_state: {
      id: "",
    },
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await getCities.fetchData();
        console.log(response);
        if (
          response?.data?.statusType === "SUCCESS" &&
          response?.data?.cities
        ) {
          const citiesData = response.data.cities.map((city) => ({
            label: city.name,
            value: city.id,
          }));
          setCityOptions(citiesData);
        } else {
          console.error("Error fetching city data:", response.text);
        }
      } catch (error) {
        console.error("Error fetching city data:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await axios.post(
          `${baseUrl}/admin/countries`,
          {
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }

        const purposeResponse = await axios.post(
          `${baseUrl}/property/purposes`,
          {
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          setPropertyPurposes(purposeResponse.data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.get(`${baseUrl}/property/types`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }

        const statusResponse = await axios.post(
          `${baseUrl}/property/statusList`,
          {
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (statusResponse.data.statusType === "SUCCESS") {
          setStatusList(statusResponse.data.propertyStatuss);
        } else {
          setStatusList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          console.log("Your Country id is", countryId);
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/states/${countryId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/cities/${stateId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        const fetchArea = async (cityId) => {
          if (!cityId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/areas/${cityId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              const areaData = response.data.areas.map((area) => ({
                label: area.name,
                value: area.id,
              }));
              setAreaInput(areaData);
              console.log(response.data.areas);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
        fetchArea(selectedCity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    selectedArea,
    loggedInUser?.token,
  ]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      const data = {
        id: +userId,
        user_purposes: [
          {
            id: +purpose,
          },
        ],
        user_states: [
          {
            id: +selectedState,
          },
        ],
        user_cities: [
          {
            id: +selectedCity,
          },
        ],

        user_areas: selectedArea.map((singleArea) => ({
          id: +singleArea.value,
        })),
      };
      console.log("your data is here", data);
      const response = await axios.post(
        `${baseUrl}/admin/addPrefrences`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.statusType === "SUCCESS") {
        showToast("Preferences added successfully", {
          type: "success",
        });
        resetForm(); // Clear the form
        setSelectedState("");
        setSelectedCity("");
        setSelectedArea([]);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Notification />

      <div className="card" style={{ padding: "0rem 4rem" }}>
        <Row>
          <Col md={12}>
            <div className="card-header" style={{ marginLeft: "-1rem" }}>
              <h2>My Preference</h2>
            </div>
          </Col>
          <Col md={12}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="card-body">
                    <Row>
                      <label htmlFor="title" className="form-label mt-1 mb-2">
                        I Wish To:
                      </label>
                      <FieldArray name="propertyPurpose">
                        {({ push }) => (
                          <>
                            {propertyPurposes.map((purpose) => (
                              <Col>
                                <div key={purpose.id} className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="propertyPurpose"
                                    id={`purpose-${purpose.id}`}
                                    value={purpose.id}
                                    onChange={(e) => {
                                      // push(e.target.value);
                                      setPurpose(e.target.value);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`purpose-${purpose.id}`}
                                  >
                                    {purpose.name}
                                  </label>
                                </div>
                              </Col>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </Row>
                    <ErrorMessage
                      name="propertyType"
                      component="div"
                      className="text-danger"
                    />

                    <Row className=" mt-3">
                      <Col md={6}>
                        <div className="">
                          <label htmlFor="title" className="form-label">
                            State
                          </label>
                          <Field
                            as="select"
                            className="form-control"
                            name="user_state"
                            required=""
                            onChange={(e) => setSelectedState(e.target.value)}
                            value={selectedState}
                          >
                            <option value="">-- Select State --</option>
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </Col>
                      <Col md={6} className="">
                        <div className="">
                          <label htmlFor="title" className="form-label">
                            City
                          </label>
                          <Field
                            as="select"
                            className="form-control"
                            name="user_cities"
                            required=""
                            onChange={(e) => setSelectedCity(e.target.value)}
                            value={selectedCity}
                          >
                            <option value="">-- Select City --</option>
                            {cities?.map((city) => (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mt-3">
                          <label htmlFor="title" className="form-label">
                            Area
                          </label>
                          <Select
                            options={areaInput}
                            isMulti
                            onChange={setSelectedArea}
                            value={selectedArea}
                            placeholder="Select City"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="mb-3 "
                  >
                    Save Preference
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Preference;
