import React, { useState, useEffect, useMemo } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import Pagination from "../pagination/Pagination";

const List = ({
  title,
  data,
  columns,
  button,
  to,
  route,
  handleDelete,
  user,
  role,
  isPostListingPage,
  isPropertyView,
  handleHide,
  btnTwo,
  handleShow,
  handleToggleEnable,
  currentPage,
  totalPages,
  onPageChange,
  isPartner,
}) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userType = loggedInUser?.userDetails?.userType;

  const roles = loggedInUser?.userDetails?.roles;

  const userId = loggedInUser?.userDetails?.id;
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);
  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    setItemIdToDelete(null);
  };

  const handleDeleteAlertShow = (itemId) => {
    setShowDeleteAlert(true);
    setItemIdToDelete(itemId);
  };

  const handleDeleteConfirmed = () => {
    handleDelete(itemIdToDelete);
    handleDeleteAlertClose();
  };

  const handleHideApi = (item) => {
    handleHide(item);
  };
  const handleClick = (id) => {
    secureLocalStorage.setItem("leadId", id);
  };
  const sortedData =
    data &&
    [...data]?.sort((a, b) => {
      if (a.user?.id === userId) return -1;
      if (b.user?.id === userId) return 1;
      return 0;
    });

  const accessControlAxis = [
    {
      page: "post",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },

    {
      page: "query",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "contact-us",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "state",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "lead",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "city",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: route.includes("area") && route,
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "country",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "user-type",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "service",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "property-purpose",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "property-status",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "property-type",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "property-subtype",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "property",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "contact",
      allowedUserTypes: ["PARTNER"],
      role: ["ROLE_ADMIN"],
      canEditDelete: true,
    },
    {
      page: "network",
      allowedUserTypes: ["PARTNER"],
      role: ["ROLE_ADMIN"],
      canEditDelete: true,
    },
    {
      page: "user",
      allowedUserTypes: ["SUPER_ADMIN", "PARTNER", "ALL_HOME_DESIRE"],
      role: ["ROLE_ADMIN"],
      canEditDelete: true,
    },
    {
      page: "partners",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
    {
      page: "allhomedesire",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
      canEditDelete: true,
    },
  ];

  // accessControlAxis se current page ke access permissions nikalna
  const currentPageAccess = accessControlAxis.find(
    (entry) => entry.page === route
  );

  // agar currentPageAccess nahi milta hai ya Edit/Delete ka axis nahi hai, toh default false set kardo
  const isEditDeleteAllowed = currentPageAccess
    ? currentPageAccess.canEditDelete
    : false;

  // agar Edit/Delete allowed hai aur user type allowed list mein hai, toh buttons dikhao
  const isUserAuthorized =
    isEditDeleteAllowed &&
    (currentPageAccess.allowedUserTypes.includes(userType) ||
      (userType === "PARTNER" &&
        roles.includes("ROLE_ADMIN") &&
        currentPageAccess.role.includes("ROLE_ADMIN")));

  const handleToggleEnableLocal = async (id, newStatus) => {
    const updatedData = localData.map((item) =>
      item.id === id ? { ...item, active: newStatus } : item
    );

    setLocalData(updatedData);
    handleToggleEnable(id, newStatus);
  };

  return (
    <>
      <Container fluid>
      <Modal
          show={showDeleteAlert}
          onHide={handleDeleteAlertClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to Delete ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleDeleteAlertClose}
              variant="outline-secondary"
              className="me-3"
            >
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} variant="danger">
              
            Delete
            </Button>
          </Modal.Footer>
        </Modal>
   
        {/* <Alert
          show={showDeleteAlert}
          variant="danger"
          onClose={handleDeleteAlertClose}
          dismissible
        >
          <Alert.Heading>Confirm Delete</Alert.Heading>
          <p>Are you sure you want to delete this item?</p>
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-secondary"
              onClick={handleDeleteAlertClose}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirmed}>
              Delete
            </Button>
          </div>
        </Alert> */}

        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4 className="card-title">{title}</h4>
            <div>
              {btnTwo && (
                <Link
                  className="btn btn-primary"
                  onClick={handleShow}
                  style={{ marginRight: "2rem" }}
                >
                  {btnTwo}
                </Link>
              )}
              {button ? (
                <Link className="btn btn-primary" to={to}>
                  {button}
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table  table-bordered">
                <thead>
                  <tr className="table-sub-header">
                    {columns.map((column, index) => (
                      <th key={index}>{column.displayName}</th>
                    ))}
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((item, index) => (
                    <tr key={index}>
                      {columns?.map((column, columnIndex) => (
                        <>
                          {isPartner ? (
                            column.displayName === "Status" ? (
                              <td key={columnIndex}>
                                {
                                  item[column.key] === 'APPROVE' ? (
                                    <Badge bg="success">
                                    {column.render
                                      ? column.render(item, index)
                                      : column.key?.includes(".")
                                      ? getPropertyByPath(item, column.key)
                                      : item[column.key]}{" "}
                                  </Badge>
                                  ) : (
                                    item[column.key] === 'REJECT' ? (
                                      <Badge bg="danger">
                                      {column.render
                                        ? column.render(item, index)
                                        : column.key?.includes(".")
                                        ? getPropertyByPath(item, column.key)
                                        : item[column.key]}{" "}
                                    </Badge>
                                    ) : (
                                      <Badge bg="secondary">
                                      {column.render
                                        ? column.render(item, index)
                                        : column.key?.includes(".")
                                        ? getPropertyByPath(item, column.key)
                                        : item[column.key]}{" "}
                                    </Badge>
                                    )
                                  
                                  )
                                }
                              
                              </td>
                            ) : (
                              <td key={columnIndex}>
                                {column.render
                                  ? column.render(item, index)
                                  : column.key?.includes(".")
                                  ? getPropertyByPath(item, column.key)
                                  : item[column.key]}{" "}
                              </td>
                            )
                          ) : (
                            <>
                            {
                              column.key === 'roles' ? (
                                <td key={columnIndex}>
                                {column.render
                                  ? column.render(item, index)
                                  : column.key?.includes(".")
                                  ? getPropertyByPath(item, column.key)
                                  : item[column.key].length > 0 ?  (item[column.key].map((singleData) => (
                                    <>
                                   {singleData.name } <br/>
                                   </>
                                  ))) : (
                                    'Customer'
                                  )
                                  
                                  }
                              </td>
                              ) : (

                                <td key={columnIndex}>
                                {column.render
                                  ? column.render(item, index)
                                  : column.key?.includes(".")
                                  ? getPropertyByPath(item, column.key)
                                  : item[column.key]}{" "}
                              </td>
                              )
                            }
                          
                            </>
                           
                          )}

                          {/* <td key={columnIndex}>
                            {column.key?.includes(".")
                              ? getPropertyByPath(item, column.key)
                              : item[column.key]}{" "}
                          </td> */}
                        </>
                      ))}
                      <td>
                        <div className="button-list">
                          {(isUserAuthorized || item?.user?.id === userId) && (
                            <>
                              <Link
                                style={{ color: "#fff" }}
                                to={`/${route}/${item.id}`}
                                className="btn btn-info me-2"
                              >
                                <i
                                  className="fa fa-eye pr-0"
                                  aria-hidden="true"
                                  style={{ paddingRight: "0rem" }}
                                ></i>
                              </Link>

                              <Link
                                style={{ color: "#fff" }}
                                to={`/${route}/${item.id}/edit`}
                                className="btn btn-primary me-2"
                              >
                                <i
                                  className="fa fa-edit"
                                  style={{ paddingRight: "0rem" }}
                                ></i>
                              </Link>
                            </>
                          )}

                          {/* {user === true &&
                            (userType === "SUPER_ADMIN" ||
                              userType === "ALL_HOME_DESIRE") && (
                              <button className="btn btn-info me-2">
                                <Link
                                  style={{ color: "#fff" }}
                                  to={`/${route}/${item.id}/changepassword`}
                                >
                                  <i
                                    className="fa fa-password"
                                    aria-hidden="true"
                                  ></i>
                                  Change Password
                                </Link>
                              </button>
                            )} */}
                          {user === true ? (
                            <>
                              {userId === item.id ? (
                                <></>
                              ) : (
                                <Link
                                  className="btn btn-danger me-2"
                                  onClick={() => handleDeleteAlertShow(item.id)}
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                    style={{ paddingRight: "0rem" }}
                                  ></i>
                                </Link>
                              )}
                            </>
                          ) : (
                            <Link
                              className="btn btn-danger me-2"
                              onClick={() => handleDeleteAlertShow(item.id)}
                            >
                              <i
                                className="fa fa-trash-o"
                                aria-hidden="true"
                                style={{ paddingRight: "0rem" }}
                              ></i>
                            </Link>
                          )}

                          {isPropertyView === true && (
                            <>
                              {item?.property_id != 0 &&
                              item?.property_id != null ? (
                                <button className="btn btn-info me-2 ">
                                  <Link
                                    style={{ color: "#fff" }}
                                    to={`/property/${item?.property_id}`}
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                    View Property
                                  </Link>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-info me-2"
                                  onClick={() => handleClick(item.id)}
                                >
                                  <Link
                                    style={{ color: "#fff" }}
                                    to={`/add-property`}
                                  >
                                    <i
                                      className="fa fa-add"
                                      aria-hidden="true"
                                    ></i>
                                    Add Property
                                  </Link>
                                </button>
                              )}
                            </>
                          )}

                          {(route === "property" || route === "post") &&
                            isUserAuthorized && (
                              <Row className="mt-3 ">
                                <Col className="d-flex justify-content-center align-item-center">
                                  <div className="form-check form-switch">
                                    {item.published === false ? (
                                      <>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="switchButton"
                                          onClick={() => handleHideApi(item)}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="switchButton"
                                        >
                                          Show Property
                                        </label>
                                      </>
                                    ) : (
                                      <>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="switchButton"
                                          onClick={() => handleHideApi(item)}
                                          checked={true}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="switchButton"
                                        >
                                          Hide Property
                                        </label>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            )}

                          {userType === "SUPER_ADMIN" &&
                            (route === "user" ||
                              route === "partners" ||
                              route === "allhomedesire") && (
                              <div
                                className="form-check form-switch mt-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`toggleSwitch${item.id}`}
                                  checked={item.active}
                                  onChange={() =>
                                    handleToggleEnableLocal(
                                      item.id,
                                      !item.active
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label  mt-1"
                                  htmlFor={`toggleSwitch${item.id}`}
                                  style={{
                                    marginLeft: "1rem",
                                  }}
                                >
                                  {item.active ? "Disable" : "Enable"}
                                </label>
                              </div>
                            )}
                        </div>

                        {/* <div className="button-list">
                          {(userType === "SUPER_ADMIN" ||
                            userType === "ADMIN" ||
                            (userType === "PARTNER" &&
                              roles.includes("ROLE_ADMIN")) ||
                            item?.user?.id === userId) && (
                            <>
                              <button
                                className="btn btn-danger me-2"
                                onClick={() => handleDeleteAlertShow(item.id)}
                              >
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                ></i>
                                Delete
                              </button>
                              <button className="btn btn-primary me-2">
                                <Link
                                  style={{ color: "#fff" }}
                                  to={`/${route}/${item.id}/edit`}
                                >
                                  <i className="fa fa-edit"></i>
                                  Edit
                                </Link>
                              </button>
                            </>
                          )}
                          {user === true &&
                            (userType === "SUPER_ADMIN" ||
                              userType === "ALL_HOME_DESIRE") && (
                              <button className="btn btn-info me-2">
                                <Link
                                  style={{ color: "#fff" }}
                                  to={`/${route}/${item.id}/changepassword`}
                                >
                                  <i
                                    className="fa fa-password"
                                    aria-hidden="true"
                                  ></i>
                                  Change Password
                                </Link>
                              </button>
                            )}
                          <button className="btn btn-info me-2">
                            <Link
                              style={{ color: "#fff" }}
                              to={`/${route}/${item.id}`}
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              View
                            </Link>
                          </button>
                        </div> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

const getPropertyByPath = (obj, path) => {
  const keys = path.split(".");
  let value = obj;
  for (let key of keys) {
    value = value[key];
  }
  return value;
};

export default List;
