import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Col, Container, Row, Button, Form } from "react-bootstrap";

import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const EditCity = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { cityId } = useParams();
  console.log(cityId);
  const editCityApi = Api({
    url: `admin/editCity`,
  });

  const [selectedUserType, setSetelectedUserType] = useState("");
  const getUserTypeApi = Api({
    url: `admin/city/id/${cityId}`,
  });
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await getUserTypeApi.fetchData();
        console.log(response);
        if (response?.data?.statusType === "SUCCESS") {
          console.log(response);
          const postData = response.data.city;
          console.log(postData);
          setFormData({
            name: postData.name,
            description: postData.description,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const data = {
        id: cityId,
        name: formData.name,
        description: formData.description,
      };
      console.log(data);
      const responseData = await editCityApi.editData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("City  Updated successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/city");
        }, 3000);
      }

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      console.log(error);
      showToast("Error Occured", {
        type: "error",
      });
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Cities", link: "/city" },
    { label: "Edit city", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Edit City Details"
              description="Edit city details section"
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      placeholder="Enter Description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
              </Row>

              <Button variant="primary" type="submit" className="mt-5 mb-5">
                {isLoading ? <Spinner loading={true} /> : "Update"}
              </Button>

              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-5 mt-5"
                to={"/city"}
              >
                Back
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditCity;
