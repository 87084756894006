import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Api from "../api/Api";
import { Badge, Container } from "react-bootstrap";
import TimeAgo from "../timeAgo/TimeAgo";

const NotificationItem = () => {
  const { id } = useParams(); // Extracting notification ID from URL
  const [notification, setNotification] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const getSingleNotficationId = Api({
    url: `notify/id/${id}`,
  });
  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await getSingleNotficationId.fetchData();
        setNotification(response?.data?.notification);
      } catch (error) {
        console.error("Error fetching notification:", error);
      }
    };

    fetchNotification();
  }, [id]);

  return (
    <div>
      {notification ? (
        <Container className="p-5">
          <div>
            <h2>Notification Detail</h2>
            <Badge bg="success">
              For:
              {notification?.notification_for}
            </Badge>
            <h2 className="mt-2">{notification?.notification_title}</h2>
            <p>{notification?.notification_text}</p>
            <p>
              <TimeAgo timestamp={notification?.created_on} />
            </p>
            {/* Render other details of the notification */}
          </div>
        </Container>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default NotificationItem;
