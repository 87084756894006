import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  InputGroup,
  Row,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import parse from "html-react-parser";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import PostComment from "../post/PostComment";
import axios from "axios";
import { useSelector } from "react-redux";
import { FaEllipsisV, FaComment } from "react-icons/fa";
import AdminHeader from "../../common/Header";

const ContactUsItem = () => {
  const { contactUsId } = useParams();
  const [userDetail, setUserDetails] = useState();
  const [singleData, setSingleData] = useState();
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `admin/contactus/${contactUsId}`,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchDataBySingleId();
        console.log(responseData);
        setUserDetails(responseData.data.contactUs);
        setSingleData(responseData.data.contactUs);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Leads", link: "/contact-us" },
    { label: "Lead Detail", link: "/contact-us", active: true },
  ];

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/deleteContactUs/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        showToast("Contact us query deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {/* <Container style={{ marginTop: "2rem" }}>
            <BreadCrumb items={breadcrumbItems} />
            <SingleDetailHeader
              header="Lead Details"
              description="See the contact details section"
            />

            <Row>
              <Col>
                <SingleDetail label="Name" userDetail={userDetail?.name} />
              </Col>
              <Col>
                <SingleDetail
                  label="Mobile Number"
                  userDetail={userDetail?.mobile_number}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SingleDetail
                  label="Email Address"
                  userDetail={userDetail?.email_address}
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col className={Style.col} md={12}>
                <label className={Style.label}>Message</label>
                <textarea
                  className={Style.textarea1}
                  style={{ padding: "1rem 2rem" }}
                >
                  {userDetail?.message}
                </textarea>
              </Col>
            </Row>

            <Link
              style={{ marginLeft: "1rem" }}
              className="btn btn-primary mb-5 mt-5"
              to={"/contact-us"}
            >
              Back
            </Link>
          </Container> */}

          <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
            <BreadCrumb items={breadcrumbItems} />
            <Row>
              <Col lg={3} md={4} sm={12}>
                <AdminHeader />
              </Col>
              <Col lg={9} md={8} sm={12}>
                <Row className="mainCard mt-3">
                  <Col lg={12} md={12} sm={12}>
                    <SingleDetailHeader
                      header="Lead Detail"
                      description="See the lead details section"
                    />
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mainCardAreaInside">
                    <div className="card mb-5">
                      <Row>
                        <Col
                          md="auto"
                          sm="auto"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                        >
                          <div className="profilePic">
                            <img
                              src="https://plus.unsplash.com/premium_photo-1661964217492-70800dc09cac?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzM3x8fGVufDB8fHx8fA%3D%3D"
                              alt="Profile"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={10} sm={10}>
                          <div
                            className="cardTop"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <div className="nameSection mt-2">
                              <p>{singleData?.name}</p>
                            </div>
                            <div className="badgebootstrap">
                              {singleData?.service?.name === "Buy" ? (
                                <Badge bg="success">
                                  {singleData?.service?.name}
                                </Badge>
                              ) : singleData?.service?.name === "Sell" ? (
                                <Badge bg="danger">
                                  {singleData?.service?.name}
                                </Badge>
                              ) : singleData?.service?.name === "Rent" ? (
                                <Badge bg="secondary">
                                  {singleData?.service?.name}
                                </Badge>
                              ) : singleData?.service?.name ===
                                "Interior Design" ? (
                                <Badge bg="primary">
                                  {singleData?.service?.name}
                                </Badge>
                              ) : null}
                            </div>
                            <div className="timeAgo">
                              <TimeAgo
                                timestamp={singleData?.created_on || null}
                              />
                            </div>
                          </div>
                          <div className="detailsSection">
                            <Row>
                              <Col md={6} sm={6} className="mt-2">
                                <div className="smallCard extraSmall">
                                  <p>Email: {singleData?.email_address}</p>
                                </div>
                              </Col>
                              <Col md={6} sm={6} className="mt-2">
                                <div className="smallCard extraSmall">
                                  <p>
                                    Mobile Number: {singleData?.mobile_number}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="messageSection mt-3">
                            <p>{parse(`${singleData?.message}`)}</p>
                          </div>
                        </Col>
                        <Col md={1} sm="2">
                          <div className="menu">
                            <DropdownButton
                              id="dropdown-basic-button"
                              title={<FaEllipsisV />}
                              variant="Secondary"
                              style={{ color: "#fff" }}
                            >
                              {(singleData?.user?.id === userId ||
                                userType === "SUPER_ADMIN") && (
                                <Dropdown.Item
                                  onClick={() => handleDelete(singleData?.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              )}
                            </DropdownButton>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12}>
                    <Row style={{ marginTop: "2rem" }}>
                      <div>
                        <Link className="btn btn-primary" to="/query">
                          Back
                        </Link>
                      </div>
                    </Row>
                  </Col>
                  <Col lg={12} md={12} sm={12}>
                    <div className="card">
                      <Row>
                        <PostComment />
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ContactUsItem;
