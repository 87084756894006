import React from "react";
import { formatDistanceToNow } from "date-fns";

const TimeAgo = ({ timestamp }) => {
  if (!timestamp) {
    return <span></span>;
  }

  let parsedDate;
  // Check if the timestamp is in "DD-MM-YYYY HH:mm:ss" format
  if (timestamp.includes(" ")) {
    const [datePart, timePart] = timestamp.split(" ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);
  } else {
    // Assume ISO format "YYYY-MM-DDTHH:mm:ss.sssZ"
    parsedDate = new Date(timestamp);
  }

  // Calculate the time difference and format it
  const timeAgoString = formatDistanceToNow(parsedDate, { addSuffix: true });

  return <span>{timeAgoString}</span>;
};

export default TimeAgo;
