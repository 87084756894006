import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  nextStep,
  prevStep,
} from "../../features/partnerRegistration/partnerSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Notification, {
  showToast,
} from "../../components/common/notification/Notification";

// Validation schema using Yup
const validationSchema = Yup.object().shape({

  mode: Yup.string().required("Mode is required"),
  gateway: Yup.string().required("Gateway is required"),
  provider: Yup.string().required("Provider is required"),
  status: Yup.string().required("Status is required"),
});

const PartnerForm2 = () => {
  const [screenshotFile, setScreenshotFile] = useState(null);
  const partnerData = useSelector((state) => state.partner.formData);
  const partner_id = secureLocalStorage.getItem("partner_id");
  const paymentPartner = secureLocalStorage.getItem("partnerP");
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const initialValues = {

    mode: "",
    gateway: "",
    provider: "",
    status: "",
  };

  // Configure React Dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
          setScreenshotFile({
            file,
            preview: reader.result,
          });
        };
        reader.readAsDataURL(file);
      }
    },
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("mode", values.mode);
      formData.append("gateway", values.gateway);
      formData.append("provider", values.provider);
      formData.append("status", values.status);
      formData.append("amount", "12000");
      formData.append("user_id", partner_id);
      if (screenshotFile) {
        formData.append("file", screenshotFile.file);
      }
      const response = await axios.post(
        `${baseUrl}/all/payment/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.statusType === "SUCCESS") {
        showToast(`Payment Details Uploaded Successfully`, {
          type: "success",
        });
        secureLocalStorage.setItem("partnerP", "SUCCESS");
        dispatch(nextStep()); // Enable the next step
      }
    } catch (error) {
      showToast("Error Uploading Payment Details", {
        type: "danger",
      });
    } finally {
      setSubmitting(false); // Always stop submitting state
    }
  };

  const handlePrevious = () => {
    dispatch(prevStep());
  };
  const handleNext = () => {
    dispatch(nextStep());
  };

  return (
    <Container fluid className="mt-5">
      <Row>
        {paymentPartner ? (
          <>
            <Col>
              <h4 className="d-flex justify-content-center mb-3">
                Payment details was uploaded successfully
              </h4>
            </Col>

            <Col md={12} className="d-flex justify-content-between mb-3">
              <Button className="btn btn-primary" onClick={handlePrevious}>
                Previous
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                onClick={handleNext}
              >
                Next
              </Button>
            </Col>
          </>
        ) : (
          <Col md={12} className="p-0">
            <Card>
              <Card.Header className="text-center">
                <h3>Share Your Payment Receipt</h3>
              </Card.Header>
              <Card.Body>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema} // Apply validation schema
                >
                  {({ isSubmitting, isValid, setFieldValue }) => (
                    <Form>
                      <Row>
                        <Col md={6}>
                          <label htmlFor="mode" className="form-label">
                            Mode
                          </label>
                          <Field
                            as="select"
                            className="form-select"
                            id="mode"
                            name="mode"
                          >
                            <option value="" label="Select option" />
                            <option value="ONLINE" label="Online" />
                          </Field>
                          <ErrorMessage
                            name="mode"
                            component="div"
                            className="text-danger"
                          />
                        </Col>

                        <Col md={6}>
                          <label htmlFor="gateway" className="form-label">
                            Gateway
                          </label>
                          <Field
                            as="select"
                            className="form-select"
                            id="gateway"
                            name="gateway"
                          >
                            <option value="" label="Select option" />
                            <option value="UPI" label="UPI" />
                            <option value="NETBANKING" label="Netbanking" />
                          </Field>
                          <ErrorMessage
                            name="gateway"
                            component="div"
                            className="text-danger"
                          />
                        </Col>

                        <Col md={6} className="mt-3">
                          <label htmlFor="provider" className="form-label">
                            Provider
                          </label>
                          <Field
                            as="select"
                            className="form-select"
                            id="provider"
                            name="provider"
                          >
                            <option value="" label="Select option" />
                            <option value="PHONEPE" label="PhonePe" />
                            <option value="GOOGLEPAY" label="Google Pay" />
                            <option value="PAYTM" label="Paytm" />
                          </Field>
                          <ErrorMessage
                            name="provider"
                            component="div"
                            className="text-danger"
                          />
                        </Col>

                        <Col md={6} className="mt-3">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <Field
                            as="select"
                            className="form-select"
                            id="status"
                            name="status"
                          >
                            <option value="" label="Select option" />
                            <option value="SUCCESS" label="Success" />
                            <option value="FAILURE" label="Failure" />
                          </Field>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger"
                          />
                        </Col>

                        <Col md={12} className="mt-4">
                          <label htmlFor="screenshot" className="form-label">
                            Payment Screenshot
                          </label>
                          <div
                            {...getRootProps()}
                            style={{
                              border: "2px dashed #ccc",
                              borderRadius: "5px",
                              padding: "20px",
                              textAlign: "center",
                              cursor: "pointer",
                              backgroundColor: isDragActive
                                ? "#f0f8ff"
                                : "#fff",
                              marginBottom: "20px",
                            }}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              <p>
                                Drag & drop a screenshot here, or click to
                                select files
                              </p>
                            )}
                          </div>
                          {screenshotFile && (
                            <div className="text-center mb-4">
                              <h5>Preview</h5>
                              <img
                                src={screenshotFile.preview}
                                alt="Screenshot"
                                style={{
                                  maxWidth: "30rem",
                                  height: "auto",
                                  border: "1px solid #ccc",
                                  marginBottom: "10px",
                                }}
                              />
                            </div>
                          )}
                          {!screenshotFile && (
                            <div className="text-danger text-center">
                              Screenshot is required
                            </div>
                          )}
                        </Col>

                        <Col
                          md={12}
                          className="d-flex justify-content-between mb-3"
                        >
                          <Button
                            className="btn btn-primary"
                            onClick={handlePrevious}
                          >
                            Previous
                          </Button>
                          <Button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!isValid || isSubmitting} // Disable button if form is invalid or screenshot not uploaded
                          >
                            {isSubmitting ? "Submitting..." : "Next"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default PartnerForm2;
