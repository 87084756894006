import React, { useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import Spinner from "../components/common/spinner/Spinner";
import Error from "../components/common/error/Error";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUserAction } from "../features/auth/authActions";
import Notification, {
  showToast,
} from "../components/common/notification/Notification";

const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // New State for checkbox and modal
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const dispatch = useDispatch();
  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    password: "",
    userType: {
      name: "CUSTOMER",
    },
    role: ["ROLE_ADMIN", "ROLE_MODERATOR", "ROLE_USER"],
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const successData = await dispatch(createUserAction(values));
    try {
      setSubmitting(true);

      console.log(successData);
      if (successData.success !== true) {
        throw new Error("Signup failed");
      } else {
        if (successData.success === true) {
          showToast("Signed Up Successfully", {
            type: "success",
          });
          console.log("user logged in successfully");

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      }
    } catch (error) {
      setIsError(true);
      showToast(successData.data.text, {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Using Yup for form validation
  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email_address: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    mobile_number: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Invalid Indian phone number")
      .required("Mobile Number is required"),
    password: Yup.string().required("Password is required"),
  });

  // Function to open modal with content
  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  // Function to close modal
  const closeModal = () => {
    setShowModal(false);
    setModalContent("");
  };

  return (
    <Container>
      <Notification />
      <Row className="justify-content-center mt-5 mb-5">
        <Col md={6}>
          <div className="card">
            <div className="card-header">
              <h3>Sign Up</h3>
            </div>

            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <div className="mb-3">
                      <label htmlFor="first_name" className="form-label">
                        First Name
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="first_name"
                        aria-describedby="emailHelp"
                        placeholder="Enter First Name"
                        name="first_name"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="last_name" className="form-label">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="last_name"
                        aria-describedby="emailHelp"
                        placeholder="Enter Last Name"
                        name="last_name"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        name="email_address"
                      />
                      <ErrorMessage
                        name="email_address"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="mobile_number" className="form-label">
                        Mobile Number
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="mobile_number"
                        aria-describedby="emailHelp"
                        placeholder="Enter Phone Number"
                        name="mobile_number"
                      />
                      <ErrorMessage
                        name="mobile_number"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <Field
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Checkbox for Privacy Policy and Terms */}
                    <div className="form-check mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsCheckbox"
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="termsCheckbox"
                      >
                        I agree to the
                        {/* I agree to the{" "}
                        <a
                          className="text-primary"
                          style={{ fontWeight: "bold" }}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal("Privacy Policy");
                          }}
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "} */}
                        <a
                          href="#"
                          className="text-primary mx-1"
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            openModal("Terms and Conditions");
                          }}
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    </div>

                    {isError && (
                      <Error
                        message="Incorrect Credentials"
                        variant="danger"
                        show={true}
                      />
                    )}

                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        isSubmitting || !(isValid && dirty && isCheckboxChecked)
                      }
                    >
                      {isSubmitting ? <Spinner loading={true} /> : "Signup"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>

            <Modal show={showModal} onHide={closeModal} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>{modalContent}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ overflowY: "hidden" }}>
                {modalContent === "Privacy Policy" && (
                  <div>
                    {/* Privacy Policy content here */}
                    <h5>Privacy Policy</h5>
                    <p>
                      This is the privacy policy for our application. We ensure
                      the protection of your data and privacy.
                    </p>
                    <p>
                      By using our application, you consent to the terms of this
                      privacy policy.
                    </p>
                    {/* Add more content as needed */}
                  </div>
                )}
                {modalContent === "Terms and Conditions" && (
                  <div className="privacy-statement">
                    <h2>Ushma Infotech Privacy Statement</h2>
                    <p>
                      Ushma Infotech (“we” or “us”) respects your privacy and is
                      committed to protecting it. This Privacy Statement,
                      together with all related/linked information, documents
                      and the Terms of Use, describes the personal information
                      we collect from you or that you may provide to us when you
                      visit our website{" "}
                      <a
                        href="http://www.ushmainfotech.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.ushmainfotech.com
                      </a>{" "}
                      (the “Site”) or which we otherwise gather in connection
                      with services, products, applications offered by Ushma
                      Infotech.
                    </p>

                    <h3>
                      Key, non-exhaustive points about our information practices
                      are summarized below, followed by comprehensive
                      explanations of each subject:
                    </h3>
                    <ul>
                      <li>
                        <strong>Collection:</strong> We collect information
                        about you, such as identifying information (e.g., name,
                        date of birth), payment data, current and past
                        employment information and demographic information (for
                        job applicants), and contact information (e.g., e-mail
                        address, telephone number).
                      </li>
                      <li>
                        <strong>Use:</strong> We use your information to improve
                        the quality, safety, and security of our products and
                        services, to develop new products and services, for
                        marketing, including marketing tailored to your
                        interests and preferences, and to develop a business
                        relationship with you.
                      </li>
                      <li>
                        <strong>Sharing:</strong> We share your information to
                        provide you with products and services, to improve the
                        quality, safety, and security of products and services,
                        to comply with applicable law, to respond to your
                        requests, and to allow recipients to use it for
                        marketing or other purposes, subject to your consent
                        where required.
                      </li>
                      <li>
                        <strong>Data Security:</strong> We use reasonable
                        administrative, technical and physical security to
                        protect the personal information we retain.
                      </li>
                      <li>
                        <strong>Choices:</strong> You have choices regarding how
                        we use and share your information for marketing and
                        other purposes.
                      </li>
                      <li>
                        <strong>Children’s Privacy:</strong> We do not target or
                        knowingly collect any information from children under
                        the age of majority.
                      </li>
                      <li>
                        <strong>Changes:</strong> We will notify you of any
                        material changes by posting the updated version of this
                        Privacy Statement and taking other steps as needed.
                      </li>
                      <li>
                        <strong>Contact Us:</strong> Please contact us at{" "}
                        <a
                          style={{ fontWeight: "bold" }}
                          className="text-primary"
                          href="mailto:info@ushmainfotech.com"
                        >
                          info@ushmainfotech.com
                        </a>{" "}
                        if you have any questions or concerns regarding our data
                        handling practices.
                      </li>
                    </ul>

                    <h3>
                      INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT
                    </h3>
                    <p>
                      The personal information we collect depends on how you
                      interact with us, the products you purchase, and the
                      choices you make. We collect personal information about
                      you from different sources and in various ways.
                    </p>

                    <h3>Information You Provide to Us:</h3>
                    <p>
                      Ushma Infotech collects information you provide directly
                      to us on the Site, via email, or through your
                      communications or interactions with us. This includes, but
                      is not limited to, the following:
                    </p>
                    <ul>
                      <li>
                        <strong>Identifying information:</strong> such as your
                        full name, gender, date of birth, and signature.
                      </li>
                      <li>
                        <strong>Contact information:</strong> such as your home
                        address, telephone numbers, and email addresses.
                      </li>
                      <li>
                        <strong>Residence information:</strong> such as your
                        home residency status, home insurance status, and home
                        community status.
                      </li>
                      <li>
                        <strong>Payment data:</strong> such as payment method,
                        credit or debit card numbers, and other payment or
                        billing information if you provide it to us.
                      </li>
                      <li>
                        <strong>Preferences:</strong> such as your preferences
                        for communication, language, and time zone.
                      </li>
                      <li>
                        <strong>Security:</strong> such as passwords, security
                        questions, and usernames when you create an account with
                        us.
                      </li>
                    </ul>

                    <h3>Information from Other Sources:</h3>
                    <p>
                      Ushma Infotech may receive information about you if you
                      use any other websites affiliated with Ushma Infotech or
                      from information you post on or submit to the Site. Ushma
                      Infotech may also receive your information from
                      third-party marketing companies that we use to provide you
                      with information concerning our products, service
                      offerings, and other information.
                    </p>

                    <h3>
                      Information We Collect through Automatic Data Collection
                      Technologies:
                    </h3>
                    <p>
                      As you navigate through and interact with the Site, we may
                      use automatic data collection technologies to collect
                      certain information about your equipment, browsing
                      actions, and patterns, including:
                    </p>
                    <ul>
                      <li>
                        <strong>
                          Information Automatically Collected via the Site:
                        </strong>{" "}
                        When you visit the Site, Ushma Infotech automatically
                        collects certain technical information from your
                        browser, such as the internet protocol (IP) address used
                        to connect your computer to the internet, browser type
                        and version, time zone setting, browser plug-in types
                        and versions, operating system and platform, and other
                        data about your visit to the Site.
                      </li>
                      <li>
                        <strong>Information Collected Using Cookies:</strong>{" "}
                        Cookies are data files created when you visit a website
                        and those files are stored locally (on your device).
                        When visiting the Site, we may use both session Cookies
                        (which expire once you close your web browser) and
                        persistent Cookies (which stay on your computer until
                        you delete them) to make your experience on the Site
                        more personal and interactive. Information collected may
                        include websites you visited before browsing the Site,
                        pages you viewed on the Site, number of times you
                        accessed the Site, how long you spent on the Site, and
                        other web traffic data. You may be able to disable the
                        use of Cookies (or disallow the use of Cookies on
                        specific websites) by adjusting your web browser
                        settings. Ushma Infotech’s Site is hosted by a
                        third-party platform, Amazon Web Services (AWS). For
                        more information about AWS’s platform and their Cookie
                        and privacy policy, please visit{" "}
                        <a
                          href="https://aws.amazon.com/privacy/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          AWS Privacy
                        </a>
                        .
                      </li>
                      <li>
                        <strong>
                          Information Collected Using Other Tracking Tools:
                        </strong>{" "}
                        In addition to, or in conjunction with Cookies, we may
                        use other tracking tools to improve users’ experiences
                        on the Site. For example, pixel tags (also known as
                        clear GIFs, web bugs, or web beacons) may be used to
                        track the online movements of users of the Site. Pixel
                        tags and similar tools may also be used in our emails to
                        track whether an email was read or forwarded to someone
                        else.
                      </li>
                      <li>
                        <strong>
                          Information Collected Using Web Analytics Tools:
                        </strong>{" "}
                        We may use Google Analytics Advertising features such as
                        demographics and interest reporting to collect
                        anonymized data about the gender, age, and interests of
                        visitors to the Site. You can read Google’s security and
                        privacy policies for Google Analytics at{" "}
                        <a
                          href="https://policies.google.com/technologies/partner-sites"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Google Analytics
                        </a>
                        . You may opt out of having your data used by Google
                        Analytics by accessing Google’s Ads Settings, Ad
                        Settings for mobile apps, or any other available means.
                      </li>
                    </ul>

                    <p>
                      You can prevent the storage of Cookies by setting your
                      browser software accordingly. Disclosure of other Data.
                      The type and/or methodology of data collection may change
                      from time to time, and we may introduce new features or
                      capabilities to the Site. Any such changes that apply to
                      your information are reflected in the “Information We
                      Collect About You and How We Collect It” section of this
                      Privacy Statement.
                    </p>

                    <h2>OUR USE OF YOUR PERSONAL INFORMATION</h2>
                    <p>
                      We use the information we collect in the following ways:
                    </p>
                    <ul>
                      <li>
                        <strong>
                          To Further an Established Business Relationship:
                        </strong>{" "}
                        We use collected information to provide products and
                        services to you, to fulfill requests that you make of
                        us, to identify additional products and services that
                        may interest you, and to communicate such information
                        and opportunities to you.
                      </li>
                      <li>
                        <strong>To Send You Promotional Materials:</strong> We
                        use your information to fulfill your requests,
                        including, but not limited to, requests to receive
                        promotional materials. Promotional materials may include
                        newsletters or other information regarding Ushma
                        Infotech contests, events, and/or products. We also use
                        your information to share other services or products
                        that may be of interest to you. Promotional materials
                        may be conveyed in emails. If you no longer wish to
                        receive these materials, you may opt-out by following
                        the opt-out instructions included in our emails, texts,
                        or mailings, or by contacting us at{" "}
                        <a
                          style={{ fontWeight: "bold" }}
                          className="text-primary"
                          href="mailto:info@ushmainfotech.com"
                        >
                          info@ushmainfotech.com
                        </a>
                        .
                      </li>
                      <li>
                        <strong>To Improve Our Services:</strong> We collect
                        information about how you interact with our Site and
                        services to help us improve and develop our products and
                        services. This includes recognizing and contacting you
                        across multiple devices, performing research and
                        analytics on your use of the Site, and contacting you
                        for informational, marketing, and promotional purposes.
                      </li>
                      <li>
                        <strong>To Enhance Site Navigation:</strong> We use
                        Cookies and IP addresses to administer the Site, track
                        your movement through the Site, and gather broad
                        demographic information for aggregate use. This enables
                        us to optimize our Site and provide better service to
                        Site visitors. Information collected using Cookies and
                        IP addresses is aggregated, and it is not associated
                        with a specific user.
                      </li>
                    </ul>

                    <h3>DISCLOSURE OF YOUR PERSONAL INFORMATION</h3>
                    <p>
                      We share personal information for the following business
                      purposes:
                    </p>
                    <ul>
                      <li>
                        <strong>With Affiliates:</strong> We may disclose your
                        information to affiliates or other parties in our
                        corporate group.
                      </li>
                      <li>
                        <strong>With Service Providers:</strong> We may share
                        your personal information with service providers who
                        need access to such information to carry out work on our
                        behalf.
                      </li>
                      <li>
                        <strong>
                          To Comply with Legal Requirements and Prevent Harm:
                        </strong>{" "}
                        We will disclose personal information when we believe it
                        is necessary to comply with the law, to protect or
                        defend our rights or the rights of others, or to prevent
                        harm.
                      </li>
                    </ul>

                    <h3>DATA SECURITY</h3>
                    <p>
                      Ushma Infotech uses administrative, technical, and
                      physical security measures to protect your personal
                      information from unauthorized access, use, disclosure,
                      alteration, or destruction. However, no internet-based
                      site or electronic transmission is completely secure, and
                      we cannot guarantee its absolute security. You are
                      responsible for protecting your password(s) and other
                      log-in information from disclosure to third parties, and
                      you are solely responsible for any actions taken using
                      your log-in credentials.
                    </p>

                    <h3>YOUR CHOICES</h3>
                    <p>
                      You may choose not to provide us with certain information,
                      but that may result in your being unable to use certain
                      features of our Site or services. You may also opt-out of
                      receiving marketing communications by following the
                      opt-out instructions in emails, texts, or mailings, or by
                      contacting us at{" "}
                      <a
                        style={{ fontWeight: "bold" }}
                        className="text-primary"
                        href="mailto:info@ushmainfotech.com"
                      >
                        info@ushmainfotech.com
                      </a>
                      .
                    </p>

                    <h3>CHILDREN'S PRIVACY</h3>
                    <p>
                      Ushma Infotech’s Site is not directed to children under
                      the age of majority, and we do not knowingly collect
                      personal information from children under the age of
                      majority. If we learn that we have collected personal
                      information from a child under the age of majority, we
                      will delete it promptly.
                    </p>

                    <h3>CHANGES TO THIS PRIVACY STATEMENT</h3>
                    <p>
                      We may update this Privacy Statement to reflect changes in
                      our practices or services. We will notify you of any
                      material changes by posting the updated version of this
                      Privacy Statement on our Site and taking other steps as
                      needed.
                    </p>

                    <h3>CONTACT US</h3>
                    <p>
                      If you have questions or concerns about our privacy
                      practices or this Privacy Statement, please contact us at{" "}
                      <a
                        style={{ fontWeight: "bold" }}
                        className="text-primary"
                        href="mailto:info@ushmainfotech.com"
                      >
                        info@ushmainfotech.com
                      </a>
                      .
                    </p>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Row>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                Already have an account?
                <Link
                  style={{
                    marginLeft: "1rem",
                  }}
                  to={"/login"}
                >
                  SignIn
                </Link>
              </p>
              <Col></Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
