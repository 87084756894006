import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Container } from "react-bootstrap";
import Select from "react-select";
import PropertySingleCard from "../../components/Property/PropertySingleCard";
import PostSingleCard from "../../components/post/PostSingleCard";
import { useSelector } from "react-redux";
import axios from "axios";
import TabMenu from "./TabMenu";
import Pagination from "../../components/pagination/Pagination";
import PropertyLikePostCard from "../../components/post/PropertyLikePostCard";

const Tab3 = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const fetchOptions = useSelector((state) => state.mySpace.option);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log();
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(fetchOptions || "all");
  const [assetUrl, setAssetUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchData = async (pageNumber, pageSize = 10) => {
    setIsLoading(true);
    const postData = {
      search_type: capitalizeFirstLetter(selectedValue),
      page_number: pageNumber,
      page_size: pageSize,
      order_by: "DSC",
    };
    try {
      const response = await axios.post(
        `${baseUrl}/property/list/my/space`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (selectedValue === "post") {
        console.log(response);
        response.data.properties = response.data.properties =
          response.data.properties.map((property) => ({
            is_published: property?.is_published,
            active: property.active,
            deleted: property.deleted,
            favourite: property.favorite,
            favorite_id: property?.favorite_id,
            is_favorite: property.is_favorite,
            id: property.lead_id,
            title: property.title || null,
            description: property.description,
            budget: property.budget || null,
            featured_property: property.featured_property,
            created_on: property.created_on, // Assuming the date format is the same
            property_id: property.id, // Assuming property.id is the same as property_id
            customer_id: property.customer.id,
            customer_name: `${property.customer.first_name} ${property.customer.last_name}`,
            partner_id: property.Partner ? property.Partner.id : 0,
            partner_name: property.Partner ? property.Partner.name : "",
            purpose_id: property.purpose.id,
            purpose_name: property.purpose.name,
            type_id: property.type.id,
            type_name: property.type.name,
            sub_type_id: property.sub_type.id,
            sub_type_name: property.sub_type.name,
            comment_count: property.comment_count,
            area_name: property.area.name,
            city_name: property.city.name,
            state_name: property.state.name,
            country_name: property.country.name,
            area_id: property.area.id,
            city_id: property.city.id,
            state_id: property.state.id,
            country_id: property.country.id,
            images: property?.images,
          }));
      }

      setData(response.data.properties || response.data.leads);

      setAssetUrl(response.data.assetUrl);
      setTotalPages(response.data.totalPage);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [selectedValue, currentPage]);

  const selectOptions = [
    { label: "All", value: "all" },
    { label: "Posts", value: "post" },
    { label: "Properties", value: "property" },
    { label: "Follow", value: "follow" },
  ];

  return (
    <>
      <Container>
        <TabMenu />
        <Col md="12" className="mt-2">
          <div className="widget-boxed main-search-field">
            <Row>
              {/* <h3 className="property-heading-h">My Space</h3> */}
              <Col md="12">
                <Select
                  options={selectOptions}
                  value={selectOptions.find(
                    (option) => option.value === selectedValue
                  )}
                  placeholder="Select Type"
                  onChange={(selectedOption) =>
                    setSelectedValue(selectedOption.value)
                  }
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 999999 }),
                    valueContainer: (provided) => ({
                      ...provided,
                      // Adjust the padding value as needed
                    }),
                  }}
                />
              </Col>

              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  {data.length > 0 ? (
                    selectedValue === "post" ? (
                      // <PostSingleCard data={data} />
                      // <PropertySingleCard data={data} assetUrl={assetUrl} />
                      <PropertyLikePostCard data={data} assetUrl={assetUrl} />
                    ) : (
                      <PropertySingleCard data={data} assetUrl={assetUrl} />
                    )
                  ) : (
                    <p className="d-flex align-items-center justify-content-center mt-5">
                      No Data To Show
                    </p>
                  )}
                </>
              )}
            </Row>
          </div>
        </Col>
        <div className="mt-5">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
    </>
  );
};

export default Tab3;
