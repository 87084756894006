import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import AdminHeader from "../../common/Header";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/common/spinner/Spinner";
import Error from "../../../components/common/error/Error";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import FileUploadField from "../../../components/fileUpload/FIleUploadField";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Api from "../../components/api/Api";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
const AddArea = () => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const initialValues = {
    name: "",
    description: "",
    city: {
      id: "",
    },
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log(values);
      const addArea = {
        name: values.name,
        description: values.description,
        city: {
          id: +values.city,
        },
      };
      console.log(addArea);
      console.log(values);
      setSubmitting(true);

      const response = await axios.post(`${baseUrl}/admin/addArea`, addArea, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);

      if (response.data.statusType === "SUCCESS") {
        console.log("Property created successfully:", response);
        // toast.success("Property added successfully");
        // navigate("/property");
        showToast("Area added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/area");
        }, 3000);
      } else {
        console.error("Error creating property:", response.text);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setSubmitting(false);
    }
  };

  // Using Yup for form validation

  // all functions starts here

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await axios.post(
          `${baseUrl}/admin/countries`,
          {
            page_number: 1,
            page_size: 50,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          console.log("Your Country id is", countryId);
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/states/${countryId}`,
              {
                page_number: 1,
                page_size: 50,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/cities/${stateId}`,
              {
                page_number: 1,
                page_size: 50,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCountry, selectedState, loggedInUser?.token]);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Areas", link: "/area", active: true },
    { label: "Add Area Item", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="card-body">
                    <div className="card mb-4">
                      <div className="card-body">
                        <Row className="mb-3">
                          <Col md={12}>
                            <div className="">
                              <label htmlFor="title" className="form-label">
                                Country
                              </label>
                              <Field
                                as="select"
                                className="form-control"
                                name="country"
                                required=""
                                onChange={(e) => {
                                  setSelectedCountry(e.target.value);
                                  setSelectedState(""); // Reset state when country changes
                                }}
                              >
                                <option value="">-- Select Country --</option>
                                {countries.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="">
                              <label htmlFor="title" className="form-label">
                                State
                              </label>
                              <select
                                className="form-control"
                                name="state"
                                required=""
                                disabled={!selectedCountry} // Disable state field if no country is selected
                                onChange={(e) =>
                                  setSelectedState(e.target.value)
                                }
                              >
                                <option value="">-- Select State --</option>
                                {states.map((state) => (
                                  <option key={state.id} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="">
                              <label htmlFor="title" className="form-label">
                                City
                              </label>
                              <Field
                                as="select"
                                className="form-control"
                                name="city"
                                required=""
                                disabled={!selectedState} // Disable city field if no state is selected
                              >
                                <option value="">-- Select City --</option>
                                {cities.map((city) => (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              id="name"
                              aria-describedby="emailHelp"
                              placeholder="Enter name"
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                              Description
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              id="description"
                              aria-describedby="emailHelp"
                              placeholder="Enter Description"
                              name="description"
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Row>
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary mb-5">
                      {isSubmitting ? <Spinner loading={true} /> : "Add Area"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddArea;
