import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Tr from "../../components/tr/Tr";
const PropertyStatus = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const serviceApi = Api({
    url: "property/statusList",
  });
  const deleteQueryApi = Api({
    url: `admin/deleteCountry/1`,
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/property/deleteStatus/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Property Status deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Name", key: "name" },
    { displayName: "Description", key: "description" },
  ];
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      const responseData = await serviceApi.fetchData(pageNumber);
      setData(responseData.data.propertyStatuss);
      setTotalPages(responseData.data.totalPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPropertyData(currentPage);
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Statuses", link: "/property-status", active: true },
  ];
  return (
    <>
      <Notification />
      <div className="content-body2">
        <Container>
          {/* Delete Alert */}
          <BreadCrumb items={breadcrumbItems} />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>
            <Col lg={9} md={8} sm={12}>
              {data?.length === 0 ? (
                <>
                  <Tr
                    columns={columns}
                    initialValues={initialValues}
                    title="No Property Status Found"
                    buttonName="Create"
                    btnLink="property-status/add-propertyStatus"
                    titleHeader="Property Statuses"
                  />
                  {/* <Row>
                    <Col>
                      <AllMessage title="No Property Status Found" />
                    </Col>
                  </Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Link
                      to={"property-status/add-propertyStatus"}
                      className="btn btn-primary"
                    >
                      Add Property Status
                    </Link>
                  </Col> */}
                </>
              ) : (
                <List
                  title="Property Statuses"
                  data={data}
                  columns={columns}
                  button="Create"
                  route="property-status"
                  to="add-propertyStatus"
                  handleDelete={handleDelete}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PropertyStatus;
