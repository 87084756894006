import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";

import "swiper/css";
import Col from "react-bootstrap/Col";

import BuyImg from "../../assets/images/buy.png";
import SellImg from "../../assets/images/sell.png";
import RentImg from "../../assets/images/rent.png";
import Button from "../common/button/Button";
import TypeCard from "./cards/typeCard/TypeCard";

const Type = () => {
  return (
    <>
      <section className="all-section">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <TypeCard
                title="Buy a property"
                description="Discover the wide variety of properties for sale in your preferred location. Whether you're
looking for a cozy apartment, a spacious house, or a commercial space, find the perfect
property to suit your needs and preferences."
                imageUrl={BuyImg}
                btnText="Search Property"
              />
            </Col>
            <Col lg={4} md={6}>
              <TypeCard
                title="Sell a property"
                description="Are you looking to sell your place? We can help you out with that! Our platform is designed to
make the selling process a breeze. We'll give you all the tools and resources you need to
showcase your property in the best possible light and attract potential buyers."
                imageUrl={SellImg}
                btnText="List Property"
              />
            </Col>
            <Col lg={4} md={6}>
              <TypeCard
                title="Rent a property"
                description="Explore a variety of rental properties that fit your lifestyle and budget. Whether you're looking for
a temporary residence or a long-term rental, find the perfect place to call home easily."
                imageUrl={RentImg}
                btnText="Search Property"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Type;
