import React from "react";
import PropTypes from "prop-types";
import "./Style.css";
import { FaCheckCircle } from "react-icons/fa";

const PropertyProgressBar = ({ currentStep, steps }) => {
  return (
    <div className="progress-bar-container">
      {steps.map((step, index) => (
        <>
          <div key={index} className="step-container">
            <div className="insideMainStep">
              <div
                className={`step-number ${
                  index < currentStep - 1
                    ? "completed"
                    : index === currentStep - 1
                    ? "current"
                    : ""
                }`}
              >
                {index < currentStep - 1 ? <FaCheckCircle /> : index + 1}
              </div>
              <div className="step-title">{step.title}</div>
            </div>
          </div>
        </>
      ))}
      <hr />
    </div>
  );
};

PropertyProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PropertyProgressBar;
