import React, { useState, useEffect } from "react";
import "./Style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Container,
  Row,
  Col,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Spinner from "react-bootstrap/Spinner";
import Style from "../../UserStyle.module.css";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  FaEllipsisV,
  FaComment,
  FaCheckCircle,
  FaBed,
  FaBath,
  FaRestroom,
} from "react-icons/fa";
import { resetStep, resetForm } from "../../../features/formData/formSlice";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import PropTypes from "prop-types";
import { setOption } from "../../../features/myspace/myspaceSlice";
import ReactPlayer from "react-player";

const PreviewPropertyDetails = ({
  isPropertyFromDashboard,
  callSubmitFunc,
  isPost,
  isEditProperty,
  isEditPost,
}) => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.user);
  const currentStep = useSelector((state) => state.form.currentStep);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const lead_id = secureLocalStorage.getItem("leadId");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [assetUrl, setAssetUrl] = useState("https://images.allhomedesire.in/");
  const singleData = useSelector((state) => state.form.formData);

  const renderTrueFeatures = (features) => {
    const trueFeatures = Object.entries(features).filter(
      ([key, value]) =>
        value === true &&
        !["active", "deleted", "id", "created_on", "updated_on"].includes(key)
    );

    return trueFeatures.map(([key]) => (
      <Col md={4} key={key} className="d-flex align-items-center mb-3">
        <FaCheckCircle className="me-2" />
        <span>
          {key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())}
        </span>
      </Col>
    ));
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const formattedAttachments = singleData?.files?.map((file) => ({
    attachmentPath: file?.attachmentPath,
  }));

  const handleSubmit = async () => {
    const propertyData = {
      user_id: userId,
      city_name: singleData?.cityName?.name,
      state_name: singleData?.stateName?.name,
      country_name: "India",
      area_name: singleData?.area,
      property_type_id: +singleData.propertyType,
      purpose_id: +singleData?.propertyPurpose,
      lead_id: +singleData?.lead_id || 0,
      property_status_id: +singleData.status,
      sub_type_id: +singleData?.propertySubtype,
      // property_status_id: 1,
      structure: {
        id: +singleData?.structure_id,
        bed_rooms: +singleData.bedrooms,
        bath_rooms: +singleData.bathrooms,
        rooms: +singleData.rooms,
      },
      feature: singleData.feature,
      images: singleData?.images,
      attachments: formattedAttachments,
      videos: [{}],
      tags: singleData.tags,
      name: singleData.name,
      description: singleData?.textData,
      address: singleData.address,
      land_mark: "",
      pin_code: singleData.pin_code,
      price: singleData.price,
      featured_property: singleData.featured_property,
    };
    let response;
    if (isEditProperty) {
      const editPropertyData = {
        id: singleData?.id,
        budget: singleData?.budget,
        type_id: singleData?.propertyType,

        ...propertyData,
      };
      response = await axios.put(
        `${baseUrl}/property/editProperty`,
        editPropertyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      response = await axios.post(
        `${baseUrl}/property/addProperty`,
        propertyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    console.log(response);
    if (response.data.statusType === "SUCCESS") {
      console.log("Property created successfully:", response);
      if (isEditProperty) {
        showToast("Property updated successfully", {
          type: "success",
        });
      } else {
        showToast("Property added successfully", {
          type: "success",
        });
      }

      if (isPropertyFromDashboard) {
        setTimeout(() => {
          dispatch(setOption("property"));
          dispatch(resetForm());
          navigate("/dashboard/myspace");
        }, 3000);
      } else {
        setTimeout(() => {
          dispatch(setOption("property"));
          dispatch(resetForm());
          navigate("/dashboard/myspace");
        }, 3000);
      }
    } else {
      showToast("Error creating property:", {
        type: "danger",
      });
    }
    try {
    } catch (error) {}
  };
  const handleSubmitPOst = async () => {
    const propertyData = {
      city_name: singleData?.cityName?.name,
      state_name: singleData?.stateName?.name,
      country_name: "India",
      area_name: singleData?.area,
      type_id: +singleData.propertyType,
      purpose_id: +singleData?.propertyPurpose,
      sub_type_id: +singleData?.propertySubtype,
      images: singleData?.images,
      attachments: formattedAttachments,
      title: singleData.name,
      description: singleData?.textData,
      budget: singleData.price,
      featured_property: singleData.featured_property,
    };
    let response;

    if (isEditPost) {
      console.log(singleData.lead_id);
      const editPropertyData = {
        id: singleData?.lead_id,
        ...propertyData,
      };
      response = await axios.put(
        `${baseUrl}/property/lead/edit`,
        editPropertyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      response = await axios.post(
        `${baseUrl}/property/lead/add`,
        propertyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    console.log(response);
    if (response.data.statusType === "SUCCESS") {
      dispatch(setOption("post"));

      showToast("Post added successfully", {
        type: "success",
      });

      if (isPropertyFromDashboard) {
        setTimeout(() => {
          dispatch(resetForm());
          navigate("/dashboard/myspace");
        }, 3000);
      } else {
        dispatch(resetForm());
        navigate("/dashboard/myspace");
      }
    } else {
      console.error("Error creating property:", response.text);
    }
    try {
    } catch (error) {}
  };
  const checkRequiredData = () => {
    return (
      singleData?.feature || singleData?.attachments || singleData?.bedrooms
    );
  };
  const handleUpdate = () => {
    const formDaraInside = {};
    dispatch(resetStep());
  };
  return (
    <>
      <Notification />
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div
            className="main-wrapper"
            style={
              {
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                // flexDirection: "column",
                // marginTop: "2rem",
              }
            }
          >
            <Row className="mainCard">
              <Col lg={12} md={12} sm={12} className="mainCardAreaInside">
                <Row className="">
                  <Col md={12} sm={8}>
                    <Col md={12}>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          //   justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={10}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3>{singleData?.name}</h3>
                        </Col>
                        <Col
                          md={10}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3>Price: ₹{singleData?.price}</h3>
                        </Col>

                        <Col
                          md={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <FaCheckCircle style={{ fontSize: "1.2rem" }} />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <div className="mainDataDiv">
                        <div className="badgeDataDiv">
                          <div className="iconDataDiv">
                            <small>
                              <FaBed className="faBed" />
                            </small>
                          </div>
                          <div className="roomDataDiv">
                            <span className="small">
                              {singleData?.bedrooms} bedrooms
                            </span>
                          </div>
                        </div>
                        <div
                          className="badgeDataDiv"
                          style={{ marginLeft: "2rem" }}
                        >
                          <div className="iconDataDiv">
                            <small>
                              <FaBath className="faBed" />
                            </small>
                          </div>
                          <div className="roomDataDiv ">
                            <span className="small">
                              {singleData?.bathrooms} bathrooms
                            </span>
                          </div>
                        </div>
                        <div
                          className="badgeDataDiv"
                          style={{ marginLeft: "2rem" }}
                        >
                          <div className="iconDataDiv">
                            <small>{/* <FaRooms className="faBed" /> */}</small>
                          </div>
                          <div className="roomDataDiv ">
                            <span className="small">
                              {singleData?.rooms} rooms
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {isPost ? (
                      <Col md={12}>
                        <div className="slider-container">
                          <Swiper
                            spaceBetween={50}
                            breakpoints={{
                              576: {
                                width: 576,
                                slidesPerView: 2,
                              },
                              768: {
                                width: 768,
                                slidesPerView: 3,
                              },
                              1100: {
                                width: 768,
                                slidesPerView: 3,
                              },
                            }}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {singleData?.images?.map((singleImage) => (
                              <SwiperSlide>
                                {singleImage?.image_url?.includes(".mp4") ? (
                                  // <ReactPlayer
                                  //   url={singleImage?.image_url}
                                  //   loop={true}
                                  //   style={{
                                  //     maxWidth: "100%",
                                  //     maxHeight: "auto",
                                  //   }}
                                  //   playing={true}
                                  //   muted={true}
                                  // />

                                  isEditPost ? (
                                    <video
                                      autoPlay
                                      muted
                                      loop
                                      controls
                                      style={{
                                        width: "100%",
                                        height: "15rem",
                                        objectFit: "cover",
                                      }} // Optional styling for responsive videos
                                    >
                                      <source
                                        src={`${assetUrl}/${singleImage.image_url}`}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <video
                                      autoPlay
                                      muted
                                      loop
                                      controls
                                      style={{
                                        width: "100%",
                                        height: "15rem",
                                        objectFit: "cover",
                                      }} // Optional styling for responsive videos
                                    >
                                      <source
                                        src={singleImage?.image_url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )
                                ) : isEditPost ? (
                                  <img
                                    src={`${assetUrl}/${singleImage.image_url}`}
                                    style={{
                                      height: "15rem",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "0.35rem",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={singleImage.image_url}
                                    style={{
                                      height: "15rem",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "0.35rem",
                                    }}
                                  />
                                )}
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </Col>
                    ) : (
                      <Col md={12}>
                        <div className="slider-container">
                          <Swiper
                            spaceBetween={50}
                            breakpoints={{
                              576: {
                                width: 576,
                                slidesPerView: 2,
                              },
                              768: {
                                width: 768,
                                slidesPerView: 3,
                              },
                              1100: {
                                width: 768,
                                slidesPerView: 3,
                              },
                            }}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {singleData?.images?.map((singleImage) => (
                              <SwiperSlide>
                                {singleImage?.image_url?.includes(".mp4") ? (
                                  // <ReactPlayer
                                  //   url={singleImage?.image_url}
                                  //   loop={true}
                                  //   style={{
                                  //     height: "20rem",
                                  //     display: "flex",
                                  //     alignItems: "center",
                                  //     justifyContent: "center",
                                  //     width: "30%",
                                  //     objectFit: "cover",
                                  //     borderRadius: "0.35rem",
                                  //   }}
                                  //   playing={true}
                                  //   muted={true}
                                  // />

                                  isEditProperty ? (
                                    <video
                                      autoPlay
                                      muted
                                      loop
                                      controls
                                      style={{
                                        width: "100%",
                                        height: "15rem",
                                        objectFit: "cover",
                                      }} // Optional styling for responsive videos
                                    >
                                      <source
                                        src={`${assetUrl}/${singleImage.image_url}`}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <video
                                      autoPlay
                                      muted
                                      loop
                                      controls
                                      style={{
                                        width: "100%",
                                        height: "15rem",
                                        objectFit: "cover",
                                      }} // Optional styling for responsive videos
                                    >
                                      <source
                                        src={singleImage?.image_url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )
                                ) : isEditProperty ? (
                                  <img
                                    src={`${assetUrl}/${singleImage.image_url}`}
                                    style={{
                                      height: "15rem",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "0.35rem",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={`${singleImage.image_url}`}
                                    style={{
                                      height: "15rem",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "0.35rem",
                                    }}
                                  />
                                )}
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </Col>
                    )}

                    <Col md={12}>
                      <Row>
                        <Col md={12} className="mt-1">
                          <div className="allCard">
                            <Row>
                              <Col md={4} sm={6} className="mt-2">
                                <div className="smallCard">
                                  <p>State: {singleData?.stateName?.name} </p>
                                </div>
                              </Col>
                              <Col md={4} sm={6} className="mt-2">
                                <div className="smallCard">
                                  <p>City: {singleData?.cityName?.name}</p>
                                </div>
                              </Col>
                              <Col md={4} sm={6} className="mt-2">
                                <div className="smallCard">
                                  <p>Area: {singleData?.area}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md={12} className="mt-3">
                          <p>
                            {showFullDescription
                              ? parse(`${singleData?.textData}`)
                              : parse(
                                  `${singleData?.textData?.substring(0, 200)}`
                                )}
                          </p>
                          <Link
                            className="p-0 text-primary fw-bold"
                            onClick={toggleDescription}
                          >
                            {showFullDescription ? "Read less" : "Read more"}
                          </Link>
                        </Col>
                        <Col className="mt-3">
                          <p>Address: {singleData?.address}</p>
                        </Col>
                        <Col md={12} className="mt-3">
                          <Row>
                            {singleData?.feature &&
                              renderTrueFeatures(singleData.feature)}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-primary" onClick={handleUpdate}>
                  Update
                </button>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                {console.log(isEditProperty)}

                {isEditProperty ? (
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Final Submit
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={
                      checkRequiredData() ? handleSubmit : handleSubmitPOst
                    }
                  >
                    Final Submit
                  </button>
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

PreviewPropertyDetails.propTypes = {
  showThings: PropTypes.bool,
  isPropertyFromDashboard: PropTypes.bool,
  callSubmitFunc: PropTypes.bool,
  isPost: PropTypes.bool,
  isEditProperty: PropTypes.bool,
  isEditPost: PropTypes.bool,
};

PreviewPropertyDetails.defaultProps = {
  showThings: true,
  isPropertyFromDashboard: false,
  callSubmitFunc: false,
  isPost: false,
  isEditProperty: false,
  isEditPost: false,
};

export default PreviewPropertyDetails;
