import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import "./post.css";
import PropTypes from "prop-types";
import { setFormData, saveLeadId } from "../../../features/formData/formSlice";
import PostBasicPropertyForm from "./PostBasicPropertyForm";

const AddPost = ({ showThings, isPostFromDashboard }) => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [areaInput, setAreaInput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [showBasicPropertyForm, setShowBasicPropertyForm] = useState(false);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedState, setSelectedState] = useState("");
  const [selectedArea, setSelectedArea] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [purpose, setPurpose] = useState("");
  const [allPurpose, setAllPurpose] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [showAreaInput, setShowAreaInput] = useState(false);

  const [newArea, setNewArea] = useState("");
  const authToken = loggedInUser?.token;
  const getPurposesApi = Api({
    url: `property/purposes`,
  });
  const getCityApi = Api({
    url: `admin/cities`,
  });
  const addPostApi = Api({
    url: `admin/addDesiredRequest`,
  });
  const getPropertyTypeApi = Api({
    url: `property/types`,
  });

  const getPurposes = async () => {
    try {
      const responseData = await getPurposesApi.fetchData();
      if (responseData.data.statusType === "SUCCESS") {
        console.log(responseData);
        const purposeData = responseData.data.propertyPurposes.map(
          (purpose) => ({
            label: purpose.name,
            value: purpose.id,
          })
        );

        setAllPurpose(purposeData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCity = async () => {
    try {
      const responseData = await getCityApi.fetchData();
      if (responseData.data.statusType === "SUCCESS") {
        console.log(responseData);
        const citiesData = responseData.data.cities.map((city) => ({
          label: city.name,
          value: city.id,
        }));
        setAllCity(citiesData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPropertyType = async () => {
    console.log("calling from property type");
    try {
      // const responseData = await getPropertyTypeApi.fetchData(true);
      const responseData = await axios.post(
        `${baseUrl}/property/types`,
        {
          page_number: 1,
          page_size: 20,
          order_by: "ASC",
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (responseData.data.statusType === "SUCCESS") {
        console.log(responseData);
        const purposeData = responseData.data.propertyTypes.map((type) => ({
          label: type.name,
          value: type.id,
        }));

        setPropertyType(purposeData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const initialValues = {
    title: "",
    description: "",
  };

  useEffect(() => {
    getPurposes();
    getCity();
    getPropertyType();
  }, []);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Add your authToken here
        const countryResponse = await axios.post(
          `${baseUrl}/admin/countries`,
          {
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }

        const purposeResponse = await axios.post(
          `${baseUrl}/property/purposes`,
          {
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          setPropertyPurposes(purposeResponse.data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.get(`${baseUrl}/property/types`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }

        const statusResponse = await axios.post(
          `${baseUrl}/property/statusList`,
          {
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (statusResponse.data.statusType === "SUCCESS") {
          setStatusList(statusResponse.data.propertyStatuss);
        } else {
          setStatusList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          console.log("Your Country id is", countryId);
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/states/${countryId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/cities/${stateId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        const fetchArea = async (cityId) => {
          if (!cityId) {
            setCities([]);
            return;
          }
          try {
            console.log("your city id ", cityId);
            const response = await axios.post(
              `${baseUrl}/admin/areas/${cityId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              const areaData = response.data.areas.map((area) => ({
                label: area.name,
                value: area.id,
              }));
              setAreaInput([{ label: "Other", value: "other" }, ...areaData]);
              console.log(response.data.areas);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
        fetchArea(selectedCity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    selectedArea,
    loggedInUser?.token,
  ]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const data = {
        title: "",
        description: values.description,
        purpose_id: selectedPurpose.value,
        user_id: userId,
        type_id: selectedType.value,
        // user_state: {
        //   id: +selectedState,
        // },
        // user_cities: {
        //   id: +selectedCity,
        // },
        desired_areas: selectedArea.map((area) => ({
          id: area.value,
        })),
      };

      const responseData = await addPostApi.postData(data);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Post added successfully", {
          type: "success",
        });
        const formData = {
          lead_id: responseData.data.id,
        };

        dispatch(saveLeadId(responseData.data.desiredRequest.id));
        setTimeout(() => {
          setShowBasicPropertyForm(true);
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleAddArea = async () => {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Posts", link: "/post" },
    { label: "Add Post", link: "", active: true },
  ];

  return (
    <>
      {showBasicPropertyForm ? (
        <PostBasicPropertyForm isPostFromDashboard={isPostFromDashboard} />
      ) : (
        <>
          <Notification />
          <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
            {showThings && (
              <>
                <BreadCrumb items={breadcrumbItems} />
                <SingleDetailHeader
                  header="Add Post"
                  description="Add the post details section"
                />
              </>
            )}

            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  {/* <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="title"
                    aria-describedby="emailHelp"
                    placeholder="Enter Title"
                    name="title"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger"
                  />
                </div> */}
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <label htmlFor="wish" className="form-label">
                          I Wish TO
                        </label>
                        <Select
                          options={allPurpose}
                          onChange={setSelectedPurpose}
                          value={selectedPurpose}
                          placeholder="Select Purpose"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 999999,
                            }),

                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "10px", // Adjust the padding value as needed
                            }),
                          }}
                        />
                        <ErrorMessage
                          name="userType"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="">
                        <label htmlFor="title" className="form-label">
                          State
                        </label>
                        <Field
                          as="select"
                          className="form-control"
                          name="user_state"
                          required=""
                          onChange={(e) => setSelectedState(e.target.value)}
                          value={selectedState}
                        >
                          <option value="">-- Select State --</option>
                          {states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="">
                        <label htmlFor="title" className="form-label">
                          City
                        </label>
                        <Field
                          as="select"
                          className="form-control"
                          name="user_cities"
                          required=""
                          onChange={(e) => setSelectedCity(e.target.value)}
                          value={selectedCity}
                        >
                          <option value="">-- Select City --</option>
                          {cities?.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </Col>
                    <Col md={6}>
                      {/* <div className="">
                      <label htmlFor="title" className="form-label">
                        Area
                      </label>
                      <Select
                        options={areaInput}
                        isMulti
                        onChange={setSelectedArea}
                        value={selectedArea}
                        placeholder="Select Area"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 999999,
                          }),
  
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "10px", // Adjust the padding value as needed
                          }),
                        }}
                      />
                    </div> */}

                      <div className="">
                        <label htmlFor="title" className="form-label">
                          Area
                        </label>
                        <Select
                          options={areaInput}
                          isMulti
                          onChange={(option) => {
                            if (option.value === "other") {
                              setShowAreaInput(true);
                              setSelectedArea(option);
                            } else {
                              setSelectedArea(option);
                              setShowAreaInput(false);
                            }
                          }}
                          value={selectedArea}
                          placeholder="Select Area"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 999999,
                            }),

                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "10px",
                            }),
                          }}
                        />
                        {showAreaInput && (
                          <div className="mt-3">
                            <input
                              type="text"
                              className="form-control mb-2"
                              placeholder="Enter new area"
                              value={newArea}
                              onChange={(e) => setNewArea(e.target.value)}
                            />
                            <Button
                              variant="primary"
                              onClick={handleAddArea}
                              disabled={isLoading || !newArea}
                            >
                              {isLoading ? "Adding..." : "Add Area"}
                            </Button>
                            <Button
                              style={{ marginLeft: "1rem" }}
                              variant="secondary"
                              onClick={() => setShowAreaInput(false)}
                              className="ml-2"
                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={6}>
                      <div className="mb-3">
                        <label htmlFor="wish" className="form-label">
                          Property Type
                        </label>
                        <Select
                          options={propertyType}
                          onChange={setSelectedType}
                          value={selectedType}
                          placeholder="Select Property Type"
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 999999,
                            }),

                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "10px", // Adjust the padding value as needed
                            }),
                          }}
                        />
                        <ErrorMessage
                          name="userType"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="description"
                          aria-describedby="emailHelp"
                          placeholder="Enter Description"
                          name="description"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    style={{ marginBottom: "10rem" }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner loading={true} /> : "Add Post"}
                  </button>
                </Form>
              )}
            </Formik>
          </Container>
        </>
      )}
    </>
  );
};
AddPost.propTypes = {
  showThings: PropTypes.bool,
  isPostFromDashboard: PropTypes.bool,
};

AddPost.defaultProps = {
  showThings: true,
  isPostFromDashboard: false,
};
export default AddPost;
