import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function showToast(message, options = {}) {

  toast(message, options);
}

const Notification = () => {
  return (
    <div
      style={{
        zIndex: "999999999999999",
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{
          marginTop: "3rem",
        }}
      />
    </div>
  );
};
export { showToast };
export default Notification;
