import { Button } from "react-bootstrap";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  return (
    <div
      className="pagination-container mb-4"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        variant="primary"
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        size="sm"
        style={{
          marginRight: "1rem",
        }}
      >
        <FaAngleDoubleLeft />
      </Button>
      {Array.from({ length: totalPages }, (_, index) => (
        <a
          key={index + 1}
          variant={index + 1 === currentPage ? "secondary" : "primary"}
          onClick={() => handleClick(index + 1)}
          style={{
            marginRight: "2rem",
            marginLeft: "2rem",
          }}
        >
          {index + 1}
        </a>
      ))}
      <Button
        variant="primary"
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        size="sm"
        style={{
          marginLeft: "1rem",
        }}
      >
        <FaAngleDoubleRight />
      </Button>
    </div>
  );
};

export default Pagination;
