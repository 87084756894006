// features/form/formSlice.js

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  option: "all",
  // Add other form-related state here
};
const mySpaceSlice = createSlice({
  name: "mySpace",
  initialState,
  reducers: {
    setOption(state, action) {
      state.option = action.payload;
    },
  },
});

export const { setOption } = mySpaceSlice.actions;

export default mySpaceSlice.reducer;
