import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Alert,
  Badge,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import parse from "html-react-parser";
import { FaEllipsisV, FaComment } from "react-icons/fa";

import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Tr from "../../components/tr/Tr";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import Pagination from "../../components/pagination/Pagination";

const ContactUs = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;

  const propertyApi = Api({
    url: "admin/listContactUs",
  });

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/admin/deleteContactUs/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Contact us query deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Name", key: "name" },
    { displayName: "Mobile Number", key: "mobile_number" },
    { displayName: "Email Address", key: "email_address" },
  ];
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      const responseData = await propertyApi.fetchData(pageNumber);
      console.log("your service", responseData);
      setData(responseData.data.contactUss);
      setTotalPages(responseData.data.totalPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPropertyData(currentPage);
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Leads", link: "/contact-us", active: true },
  ];
  const handleClick = (id) => {
    secureLocalStorage.setItem("leadId", id);
  };
  return (
    <>
      <Notification />
      <div className="bread-crumb">
        <Container>
          <Col md={8}>
            <BreadCrumb items={breadcrumbItems} />
          </Col>
        </Container>
      </div>
      {/* <div className="content-body"> */}
      <Container>
        {/* Delete Alert */}
        {/* <BreadCrumb items={breadcrumbItems} /> */}
        {/* <BreadCrumb data="Leads" /> */}
        <Alert
          show={showDeleteAlert}
          variant="danger"
          onClose={handleDeleteAlertClose}
          dismissible
        >
          <Alert.Heading>Confirm Delete</Alert.Heading>
          <p>Are you sure you want to delete this user?</p>
          <div className="d-flex justify-content-end">
            <Button
              onClick={handleDeleteAlertClose}
              variant="outline-danger"
              className="me-3"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="danger">
              Delete
            </Button>
          </div>
        </Alert>

        <Row>
          {/* <Col md={2}>
              <AdminHeader />
            </Col> */}
          <Col md={12} className="mb-5">
            {data.length === 0 ? (
              // <AllMessage title="No Contact Us Query Found" />

              <Tr
                columns={columns}
                initialValues={initialValues}
                title="No Leads  Found"
                titleHeader="Leads"
                btn={false}
              />
            ) : (
              <>
                <div className="mt-3">
                  {/* <Row> */}
                  <Col md={12}>
                    <Row>
                      <Col md={10}>
                        <h2>Leads</h2>
                      </Col>
                      <Col md={2}>
                        {console.log(userType)}
                        {userType === "SUPER_ADMIN" ||
                        userType === "ALL_HOME_DESIRE" ? (
                          <></>
                        ) : (
                          // <Link
                          //   className="btn btn-primary"
                          //   to={"/post/add-post"}
                          // >
                          //   Create
                          // </Link>
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {/* </Row> */}

                  {data &&
                    data.map((singleData) => (
                      <Row className="mainCard mb-3">
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          className="mainCardAreaInside "
                        >
                          <div className="mb-5 bg-card">
                            <Row className="p-3">
                              <Col md={10} sm={11}>
                                <Col md={12}>
                                  <div className="cardTop">
                                    <div className="nameSection mt-2">
                                      <p>{singleData?.name}</p>
                                    </div>
                                    {/* <div className="badgebootstrap">
                                        {singleData?.purpose?.name === "Buy" ? (
                                          <Badge bg="success">
                                            {singleData?.purpose?.name}
                                          </Badge>
                                        ) : singleData?.purpose?.name ===
                                          "Sell" ? (
                                          <Badge bg="danger">
                                            {singleData?.purpose?.name}
                                          </Badge>
                                        ) : singleData?.purpose?.name ===
                                          "Rent" ? (
                                          <Badge bg="secondary">
                                            {singleData?.purpose?.name}
                                          </Badge>
                                        ) : singleData?.purpose?.name ===
                                          "Investment" ? (
                                          <Badge bg="primary">
                                            {singleData?.purpose?.name}
                                          </Badge>
                                        ) : (
                                          <></>
                                        )}
                                      </div> */}
                                    <div className="timeAgo">
                                      <TimeAgo
                                        timestamp={singleData?.created_on}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col md={12}>
                                  <Row>
                                    <Col md={12} className="mt-1">
                                      <div className="allCard">
                                        <Row>
                                          <Col md={6} sm={6} className="mt-2">
                                            <div className="smallCard">
                                              <p>
                                                Email:{" "}
                                                {singleData?.email_address}{" "}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col md={6} sm={6} className="mt-2">
                                            <div className="smallCard">
                                              <p>
                                                Mobile Number:{" "}
                                                {singleData?.mobile_number}
                                              </p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <p> {parse(`${singleData?.message}`)}</p>
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <div className="comment-section">
                                        {" "}
                                        <Link
                                          to={`/contact-us/${singleData?.id}`}
                                        >
                                          <FaComment
                                            className="faComment"
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                            }}
                                          />
                                          <span className="comment-count">
                                            10
                                          </span>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Col>
                              <Col md={2} sm={2}>
                                <div className="menu">
                                  {/* <FaEllipsisV /> */}
                                  <DropdownButton
                                    id="dropdown-basic-button"
                                    title={<FaEllipsisV />}
                                    variant="Secondary"
                                    style={{ color: "#fff", zIndex: 1000 }}
                                  >
                                    {/* <Dropdown.Item>
                                      <Link to={`/post/${singleData?.id}`}>
                                        View
                                      </Link>
                                    </Dropdown.Item> */}

                                    {(singleData?.user?.id === userId ||
                                      userType === "SUPER_ADMIN") && (
                                      <>
                                        {/* <Dropdown.Item>
                                            <Link
                                              to={`/contact-us/${singleData?.id}`}
                                            >
                                              View
                                            </Link>
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#/action-2">
                                            <Link
                                              to={`/contact-us/${singleData?.id}/edit`}
                                            >
                                              Edit
                                            </Link>
                                          </Dropdown.Item> */}
                                        <Dropdown.Item>
                                          <Link
                                            to={``}
                                            onClick={() =>
                                              handleDelete(singleData?.id)
                                            }
                                          >
                                            Delete
                                          </Link>
                                        </Dropdown.Item>
                                      </>
                                    )}
                                  </DropdownButton>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ))}
                </div>
              </>
            )}
          </Col>
        </Row>
        <div className="mt-5">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
      {/* </div> */}
    </>
  );
};

export default ContactUs;
