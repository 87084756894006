import React from "react";
import PartnerForm1 from "./PartnerForm1";
import PartnerForm2 from "./PartnerForm2";
import { useSelector } from "react-redux";

import ProgressBar from "../../admin/dashboard/property/PropertyProgressBar";
import { Container } from "react-bootstrap";
import UploadDocument from "../../components/afterPartnerRegistration/UploadDocument";
import Payment from "../Payment";
import ThankYou from "../../components/afterPartnerRegistration/ThankYou";
import secureLocalStorage from "react-secure-storage";
const MainPartnerRegistration = () => {
  const currentStep = useSelector((state) => state.partner.currentStep);

  const steps = [
    { title: "Partner Registration" },
    { title: "Document Upload" },

    { title: "Payment" },

    { title: "Share Payment Details" },

    { title: "Thanks " },
    // Add more steps if needed
  ];
  return (
    <>
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <ProgressBar currentStep={currentStep} steps={steps} />
        <hr />

        {currentStep === 1 && <PartnerForm1 />}
        {currentStep === 2 && <UploadDocument />}
        {currentStep === 3 && <Payment />}
        {currentStep === 4 && <PartnerForm2 />}
        {currentStep === 5 && <ThankYou />}
      </Container>
    </>
  );
};

export default MainPartnerRegistration;
