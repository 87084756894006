import React, { useState, useEffect } from "react";
import Api from "../../../admin/components/api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FloatingLabel,
} from "react-bootstrap";

import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../../admin/components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../../admin/components/breadcrumb/BreadCrumb";
import AdminHeader from "../../../admin/common/Header";

const EditNetworkManagement = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const authToken = loggedInUser?.token;
  const [isStateError, setIsStateError] = useState(false);

  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [allPurpose, setAllPurpose] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState({});
  const [contactDetails, setContactDetails] = useState(null);
  const [fieldCountry, setFieldCountry] = useState("");
  const [fieldState, setFieldState] = useState("");
  const [fieldCity, setFieldCity] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { networkId } = useParams();

  const getContactUsApi = Api({
    url: `user/network/id/${networkId}`, // Endpoint to fetch contact details by ID
  });
  const updateContactUsApi = Api({
    url: `user/network/edit`, // Endpoint to fetch contact details by ID
  });
  const getCountryApi = Api({
    url: `admin/countries`,
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    address: "",
    address1: "",
    street: "",
    apartment: "",
    zip_code: "",
    area_name: "",
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch contact details by ID
        const contactResponse = await getContactUsApi.fetchData();
        if (contactResponse.data.statusType === "SUCCESS") {
          setContactDetails(contactResponse.data.network);
          setFieldState(contactResponse?.data?.network?.state_name);
          setFieldCity(contactResponse?.data?.network?.city_name);
          setFieldCountry(contactResponse?.data?.network?.country_name);

          console.log(contactResponse.data.network);
        } else {
          showToast("Failed to fetch contact details", {
            type: "error",
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        console.log(error);
        showToast("Error Occurred", {
          type: "error",
        });
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchStates("India");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      id: +networkId,
      country_name: fieldCountry,
      state_name: fieldState,
      city_name: fieldCity,
      area_name: "",
    };
    formData.forEach((value, key) => {
      data[key] = value;
    });

    console.log(data);

    try {
      setIsLoading(true);
      // Logic to update contact details
      const responseData = await updateContactUsApi.editData(data);
      setIsLoading(false);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Network details updated successfully", {
          type: "success",
        });
        setTimeout(() => {
          navigate("/network");
        }, 3000);
      } else {
        showToast("Failed to update contact details", {
          type: "error",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      showToast("Error Occurred", {
        type: "error",
      });
      console.error("Error updating contact details:", error);
    }
  };
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countryData = await getCountryApi.fetchData();
        const data = await countryData?.data;
        if (data.statusType === "SUCCESS") {
          setCountries(data.countries);
          console.log("your data", data);
          await fetchStates("India", data.countries);
        } else {
          setCountries([]);
        }
      } catch (error) {
        console.log(error);
        showToast("Failed to fetch countries!", "error");
      }
    };

    fetchCountries();
  }, []);
  useEffect(() => {
    fetchCities();
  }, [fieldState, states]);
  // Function to handle country change
  const handleCountryChange = (e) => {
    const selectedCountryId = e.target.value;
    console.log(e.target.value);
    console.info("Selected country id >> " + selectedCountryId);
    setSelectedCountry(selectedCountryId);
    setFieldCountry(selectedCountryId);
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
      setIsCityDisabled(true);
    } else {
      setSelectedState("");
      setSelectedCity("");
      setIsStateError(false);
      setIsCityDisabled(true);
    }
  };

  const fetchStates = async (countryId, countriesData) => {
    // Receive countriesData
    if (!countryId || !countriesData) {
      // Check if countriesData is defined
      return;
    }
    console.log("your country id is", countryId);
    console.log(countriesData); // Use countriesData instead of countries
    const fetchCountryId = countriesData.filter(
      (country) => country.name === countryId
    );
    const countryIds = await fetchCountryId[0]?.id;
    console.log("hello world", fetchCountryId);
    axios
      .get(`${baseUrl}/admin/states/${countryIds}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(async (response) => {
        const data = await response.data;
        if (data.statusType === "SUCCESS") {
          setStates(data.states);
        } else {
          console.error("Error fetching states data:", data.text);
          setStates([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching state list:", error);
        setStates([]);
      });
  };
  const fetchCities = async () => {
    console.log(states, "your states");
    if (!states) {
      return;
    }
    const getSingleState = await states.filter(
      (state) => state.name == fieldState
    );
    console.log("single state", getSingleState);
    const getStateId = await getSingleState[0]?.id;
    console.log("your state id is", getSingleState, getStateId);
    axios
      .get(`${baseUrl}/admin/cities/${getStateId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.statusType === "SUCCESS") {
          console.log(cities);
          setCities(data.cities);
        } else {
          console.error("Error fetching city data:", data.text);
          setCities([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching state list:", error);
        setCities([]);
      });
  };

  // Function to handle state change
  const handleStateChange = (e) => {
    const selectedStateName = e.target.value;
    console.log("Selected state name:", selectedStateName);
    setSelectedState(selectedStateName);
    setSelectedCity("");
    setFieldState(selectedStateName);
    setIsLoading(true);
    const getSingleState = states.filter(
      (state) => state.name == selectedStateName
    );
    console.log(getSingleState);
    const getStateId = getSingleState[0]?.id;
    console.log(getStateId);
    axios
      .get(`${baseUrl}/admin/cities/${getStateId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusType === "SUCCESS") {
          console.log(res, "your response");
          const options = res.data.cities.map((city) => ({
            value: city.id,
            label: city.name,
          }));
          setCities(options); // Set cities options in the state
        } else {
          showToast("Failed to fetch cities!", "error");
        }
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to false in case of an error
        showToast("Failed to fetch cities!", "error");
      });
  };
  // Function to handle city change
  const handleCityChange = (e) => {
    console.log(e.target.value);
    setSelectedCity(e.target.value);
    setFieldCity(e.target.value);
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Network Management", link: "/network", active: true },
    { label: "Edit Network Details", link: "", active: true },
  ];
  return (
    <>
      <Notification />

      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Edit Contact"
              description="Edit the contact details section"
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="mb-3">
                  <FloatingLabel controlId="firstName" label="First Name">
                    <Form.Control
                      type="text"
                      name="first_name"
                      defaultValue={contactDetails?.first_name || ""}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-3">
                  <FloatingLabel controlId="lastName" label="Last Name">
                    <Form.Control
                      type="text"
                      name="last_name"
                      defaultValue={contactDetails?.last_name || ""}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <FloatingLabel controlId="email" label="Email Address">
                    <Form.Control
                      type="email"
                      name="email_address"
                      defaultValue={contactDetails?.email_address || ""}
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-3">
                  <FloatingLabel controlId="mobile" label="Mobile Number">
                    <Form.Control
                      type="text"
                      name="mobile_number"
                      defaultValue={contactDetails?.mobile_number || ""}
                    />
                  </FloatingLabel>
                </Col>
                {/* <Col md={4} className="mb-3">
              <FloatingLabel controlId="address" label="Address">
                <Form.Control
                  type="text"
                  name="address"
                  defaultValue={contactDetails?.address || ""}
                />
              </FloatingLabel>
            </Col>
            <Col md={4} className="mb-3">
              <FloatingLabel controlId="address1" label="Address 1">
                <Form.Control
                  type="text"
                  name="address1"
                  defaultValue={contactDetails?.address1 || ""}
                />
              </FloatingLabel>
            </Col> */}
              </Row>

              <Row>
                {/* <Col md={4} className="mb-3">
              <FloatingLabel controlId="street" label="Street">
                <Form.Control
                  type="text"
                  name="street"
                  defaultValue={contactDetails?.street || ""}
                />
              </FloatingLabel>
            </Col>
            <Col md={4} className="mb-3">
              <FloatingLabel controlId="apartment" label="Apartment">
                <Form.Control
                  type="text"
                  name="apartment"
                  defaultValue={contactDetails?.apartment || ""}
                />
              </FloatingLabel>
            </Col> */}
                {/* <Col md={4} className="mb-3">
              <FloatingLabel controlId="country" label="Country">
                <Form.Control
                  as="select"
                  value={fieldCountry}
                  onChange={handleCountryChange}
                  name="country_name"
                  required
                >
                  {countries.map((country) => (
                    <option key={country.id} value={country?.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
              </FloatingLabel>
            </Col> */}
              </Row>
              {/* <Row>
            <Col md={4} className="mb-3">
              <FloatingLabel controlId="state" label="State">
                <Form.Control
                  as="select"
                  value={fieldState}
                  onChange={handleStateChange}
                  name="state)name"
                  required
                >
                  {states.map((state) => (
                    <option key={state.id} value={state?.name}>
                      {state?.name}
                    </option>
                  ))}
                </Form.Control>
              </FloatingLabel>
            </Col>
            <Col md={4} className="mb-3">
              <FloatingLabel controlId="city" label="City">
                <Form.Control
                  as="select"
                  value={fieldCity}
                  onChange={handleCityChange}
                  // disabled={isCityDisabled}
                  name="city_name"
                  required
                >
                  {cities.map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </Form.Control>
              </FloatingLabel>
            </Col>
            <Col md={4}>
              <FloatingLabel controlId="zipCode" label="Zip Code">
                <Form.Control
                  type="text"
                  name="zip_code"
                  defaultValue={contactDetails?.zip_code || ""}
                />
              </FloatingLabel>
            </Col>
          </Row> */}

              <Button
                type="submit"
                className="btn btn-primary mt-3 mb-5"
                disabled={isLoading}
              >
                {isLoading ? <Spinner loading={true} /> : "Update"}
              </Button>
              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-5 mt-3"
                to={"/network"}
              >
                Back
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditNetworkManagement;
