// features/form/formSlice.js

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  formData: {},
  isImageUploaded: false,
  isAttachmentUploaded: false,
  currentStep: 1,
  lead_id: 0,
  // Add other form-related state here
};
const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    saveLeadId(state, action) {
      state.lead_id = action.payload;
    },
    nextStep(state) {
      state.currentStep += 1;
    },
    prevStep(state) {
      state.currentStep -= 1;
    },
    resetStep(state) {
      state.currentStep = 1;
    },
    resetForm(state) {
      return initialState;
    },

    // Add other reducers as needed
  },
});

export const {
  setFormData,
  nextStep,
  prevStep,
  resetStep,
  resetForm,
  saveLeadId,
  reset,
} = formSlice.actions;

export default formSlice.reducer;
