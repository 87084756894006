import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const EditArea = () => {
  const navigate = useNavigate();
  const { stateId, areaId } = useParams();
  console.log(stateId);
  console.log(areaId);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [editData, setEditData] = useState(null);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const initialValues = {
    name: "",
    description: "",
    city: "",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const editArea = {
      id: +stateId,
      name: event.target.name.value,
      description: event.target.description.value,
      city: {
        id: +selectedCity,
      },
    };
    console.log(editArea);
    try {
      const response = await axios.put(`${baseUrl}/admin/editArea`, editArea, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusType === "SUCCESS") {
        showToast("Area  added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/area");
        }, 3000);
      } else {
        console.error("Error creating property:", response.data.error);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };
  // Here Something is different like stateId is area id and area id is state id !
  useEffect(() => {
    console.log(stateId, areaId);
    const fetchStatesAndEditData = async () => {
      try {
        const stateResponse = await axios.post(
          `${baseUrl}/admin/cities/${areaId}`,
          {
            page_number: 1,
            page_size: 100,
            order_by: "ASC",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateResponse);
        if (stateResponse.data.statusType === "SUCCESS") {
          setCityOptions(stateResponse.data.cities);
          setStates(stateResponse.data.cities);
          setIsCityDisabled(false);
        } else {
          console.error(
            "Error fetching states data:",
            stateResponse.data.error
          );
          setStates([]);
        }

        const editResponse = await axios.get(
          `${baseUrl}/admin/area/id/${stateId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (editResponse.data.statusType === "SUCCESS") {
          console.log(editResponse);
          setEditData(editResponse.data.area);

          console.log(editResponse?.data?.area?.city?.name);
          setSelectedCity(editResponse.data.area.city.name);
        } else {
          console.error("Error fetching area data:", editResponse.data.error);
        }
      } catch (error) {
        console.error("Error fetching states and edit data:", error);
        setIsError(true);
      }
    };
    fetchStatesAndEditData();
  }, [stateId, areaId, token, baseUrl]);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Areas", link: "/area", active: true },
    { label: "Edit Area Item", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Edit Area Details"
              description="Edit area details section"
            />
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="card mb-4">
                  <div className="card-body">
                    {/* <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Current City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="currentCity"
                    placeholder="City"
                    defaultValue={editData?.city?.name}
                  />
                </div> */}

                    {/* <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Select City
                  </label>
                  <select className="form-control" value={selectedCity}>
                    <option value={selectedCity}>{selectedCity}</option>
                  </select>
                </div> */}

                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter name"
                        name="name"
                        defaultValue={editData ? editData.name : ""}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        defaultValue={editData ? editData.description : ""}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mb-5">
                  Update
                </button>
                <Link
                  style={{ marginLeft: "1rem" }}
                  className="btn btn-primary mb-5"
                  to={"/area"}
                >
                  Back
                </Link>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditArea;
