import React, { useState, useEffect } from "react";

import { Col, Container, Row, Button } from "react-bootstrap";
import AdminHeader from "../../common/Header";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/common/spinner/Spinner";
import Error from "../../../components/common/error/Error";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import FileUploadField from "../../../components/fileUpload/FIleUploadField";
import { useDispatch, useSelector } from "react-redux";
import { setFormData, nextStep } from "../../../features/formData/formSlice";
import Api from "../../components/api/Api";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import AddPropertyInformation from "../property/AddPropertyInformation";
import AddProperty from "../property/AddProperty";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";

const PostBasicPropertyForm = ({ showThings, isPostFromDashboard }) => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.user);
  const formData = useSelector((state) => state.form.formData);

  const token = loggedInUser?.token;
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertySubtypes, setPropertySubtypes] = useState([]);

  const [statusList, setStatusList] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedPropertyPurpose, setSelectedPropertyPurpose] = useState("");
  const [showBasicPropertyForm, setShowBasicPropertyForm] = useState(false);
  const [uploadedImageNames, setUploadedImageNames] = useState([
    formData?.images,
  ]);

  const [selectedState, setSelectedState] = useState(formData?.state || "");
  const [selectedCountry, setSelectedCountry] = useState(
    formData?.country || ""
  );
  const [textData, setTextData] = useState(formData?.textData || "");

  const [isError, setIsError] = useState(false);

  const initialValues = {
    propertyType: formData?.propertyType || "",
    propertySubtype: formData?.propertySubtype || "",

    country: formData?.country || "",
    state: formData?.state || "",
    city: formData?.city || "",
    description: formData?.description || "",
    images: formData?.images,
    propertyPurpose: "",
    userType: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const state = states.find((state) => state.id === +values.state);
    const city = cities.find((city) => city.id === +values.city);
    const propertyType = propertyTypes.find(
      (type) => type.id === +values.propertyType
    );

    let images;

    if (uploadedImageNames?.imagePaths?.length >= 1) {
      images = await Promise.all(
        uploadedImageNames.imagePaths.map(async (image_url) => image_url)
      );
    } else if (formData?.images?.length >= 1) {
      images = formData.images.map((image_url) => image_url);
    } else {
      images = [];
    }

    if (isPostFromDashboard) {
      setTimeout(() => {
        navigate("/dashboard/myspace");
      }, 3000);
    } else {
      setTimeout(() => {
        navigate("/post");
      }, 3000);
    }
    const formDataInside = {
      ...formData,
      ...values,
      textData,
      images,

      propertyTypeName: propertyType,
      stateName: state,
      cityName: city,
      countryName: "India",
    };

    dispatch(setFormData(formDataInside));
    dispatch(nextStep());

    // Additional logic for navigating to the next step
    setSubmitting(false);
  };
  const postData = {
    page_number: 1,
    page_size: 20,
    order_by: "ASC",
  };
  const fetchCountryResponse = Api({
    url: "/admin/countries",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await fetchCountryResponse.fetchData();
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }

        const purposeResponse = await axios.post(
          `${baseUrl}/property/purposes`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          setPropertyPurposes(purposeResponse.data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.post(
          `${baseUrl}/property/types`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }

        const statusResponse = await axios.post(
          `${baseUrl}/property/statusList`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (statusResponse.data.statusType === "SUCCESS") {
          setStatusList(statusResponse.data.propertyStatuss);
        } else {
          setStatusList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchSubtypes = async (propertyTypeId) => {
      if (!propertyTypeId) {
        setPropertySubtypes([]);
        return;
      }
      try {
        const response = await axios.post(
          `${baseUrl}/property/subtypes/${propertyTypeId}`,
          postData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.statusType === "SUCCESS") {
          setPropertySubtypes(response.data.propertySubtypes);
        } else {
          setPropertySubtypes([]);
        }
      } catch (error) {
        console.error("Error fetching property subtypes:", error);
        setPropertySubtypes([]);
      }
    };

    fetchSubtypes(selectedPropertyType);
  }, [selectedPropertyType, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          console.log("Your Country id is", countryId);
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/states/${countryId}`,
              postData,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/cities/${stateId}`,
              postData,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCountry, selectedState, loggedInUser?.token]);

  useEffect(() => {
    const fetchUserTypes = async (propertyPurposeId) => {
      if (!propertyPurposeId) return;

      try {
        const response = await axios.get(
          `${baseUrl}/user/types/${propertyPurposeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.statusType === "SUCCESS") {
          // Update your state or handle the response as needed
        } else {
          // Handle the failure
        }
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    fetchUserTypes(selectedPropertyPurpose);
  }, [selectedPropertyPurpose, token]);

  const validationSchema = Yup.object({
    propertyType: Yup.string().required("Property type is required"),
    propertySubtype: Yup.string().required("Property Subtype is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    propertyPurpose: Yup.string().required("Property Purpose is required"),
    userType: Yup.string().required("User Type is required"),
  });

  const uploadImages = async (values) => {
    const formData = new FormData();
    values.files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        `${baseUrl}/all/upload/files/images`,
        formData
      );

      if (response.data.statusType === "SUCCESS") {
        const imagePaths = response.data.propertyImages.map((image) => image);

        setUploadedImageNames({ imagePaths });
        setIsImageUploaded(true);
      }
    } catch (error) {
      console.log(error);
      alert("Please select one or more attachments to upload.");
    }
  };

  const selectOptions = [
    {
      label: "Properties",
      value: "property",
    },
  ];

  useEffect(() => {
    if (selectedValue === "property") {
      setShowBasicPropertyForm(!showBasicPropertyForm);
    } else if (selectedValue === "loan") {
      // fetchPostData();
    }
  }, [selectedValue]);

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Posts", link: "/post" },
    { label: "Add Post", link: "", active: true },
  ];

  return (
    <>
      <Container>
        <Row>
          {showThings && (
            <>
              <BreadCrumb items={breadcrumbItems} />
              <SingleDetailHeader
                header="Add Post"
                description="Add the post details section"
              />
            </>
          )}

          <div className="mb-3">
            <label htmlFor="mobile_number" className="form-label">
              Select Services
            </label>
            <Select
              options={selectOptions}
              value={selectOptions.find(
                (option) => option.value === selectedValue
              )}
              placeholder="Select Type"
              onChange={(selectedOption) =>
                setSelectedValue(selectedOption.value)
              }
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 0,
                }),
              }}
            />
          </div>
        </Row>
      </Container>
      {showBasicPropertyForm && (
        <>
          <AddProperty
            showThings={false}
            isPropertyFromDashboard={true}
            isPost={true}
            hideData={true}
            callSubmitFunc={"yes"}
          />
        </>
      )}
    </>
  );
};

PostBasicPropertyForm.propTypes = {
  showThings: PropTypes.bool,
  isPostFromDashboard: PropTypes.bool,
};

PostBasicPropertyForm.defaultProps = {
  showThings: true,
  isPostFromDashboard: false,
};

export default PostBasicPropertyForm;
