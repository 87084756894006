import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Nav, Button } from "react-bootstrap";
import PropertyImg4 from "../assets/images/property-4.jpg";
// import Offer from "../components/homepage/Offer";
// import Type from "../components/homepage/Type";
// import AboutUs from "../components/homepage/AboutUs";
// import AboutUsTwo from "../components/homepage/AboutUs2";
// import AddedToday from "../components/homepage/AddedToday";
// import HowItWorks from "../components/homepage/HowItWorks";
// import InfoBar from "../components/homepage/InfoBar";
import cityImg1 from "../assets/images/city-1.jpg";
import cityImg2 from "../assets/images/city-2.jpg";
import cityImg3 from "../assets/images/city-3.jpg";
import cityImg4 from "../assets/images/city-4.jpg";
import IconAbout1 from "../assets/images/icon-about-1.svg";
import IconAbout2 from "../assets/images/icon-about-2.svg";
import IconAbout3 from "../assets/images/icon-about-3.svg";
import IconAbout4 from "../assets/images/icon-about-4.svg";
import RightContImg from "../assets/images/intecontact.png";

import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import StatCounter from "../components/homepage/StatCounter";
import Notification, {
  showToast,
} from "../components/common/notification/Notification";
import Api from "../admin/components/api/Api";

function InteriorDesign() {
  const formRef = useRef(null);
  const loggedInUser = useSelector((state) => state.auth.user);
  const formData = useSelector((state) => state.form.formData);
  const token = loggedInUser?.token;

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertySubtypes, setPropertySubtypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [selectedPropertyPurpose, setSelectedPropertyPurpose] = useState("");
  const [selectedState, setSelectedState] = useState(formData?.state || "");
  const [selectedCountry, setSelectedCountry] = useState("");

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [defaultStatusId, setDefaultStatusId] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const fetchCountryResponse = Api({
    url: "/all/countries",
  });

  const initialValues = {
    propertyPurpose: "",
    featuredProperty: "",
    propertyType: "",
    propertySubtype: "",
    budget: "",
    title: "",
    country: "India",
    state: "",
    city: "",
    area: "",
    description: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("hello");
    const filterState = states.filter(
      (singleState) => singleState.id === +values.state
    );
    const filterCity = cities.filter(
      (singleCity) => singleCity.id === +values.city
    );
    const data = {
      title: "",
      description: values.description,
      budget: values.budget,
      purpose_id: values.propertyPurpose,
      type_id: +values.propertyType,
      sub_type_id: +values.propertySubtype,
      featured_property: values.featuredProperty,
      city_name: filterCity[0].name,
      state_name: filterState[0].name,
      country_name: "India",
      area_name: values.area,
    };
    const response = await axios.post(`${baseUrl}/all/lead/add`, data);

    if (response.data.statusType === "SUCCESS") {
      console.log("Query created successfully:", response);
      showToast("Query added successfully", {
        type: "success",
      });
    } else {
      console.error("Error creating property:", response.text);
    }
    console.log(data);
    console.log(filterState, filterCity);
    console.log("your values are as follows", values);

    setSubmitting(false);
  };

  const postData = {
    page_number: 1,
    page_size: 20,
    order_by: "ASC",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;
        const purposeResponse = await axios.get(
          `${baseUrl}/all/property/purposes`
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          setPropertyPurposes(purposeResponse.data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.get(`${baseUrl}/all/property/types`);
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSubtypes = async (propertyTypeId) => {
      if (!propertyTypeId) {
        setPropertySubtypes([]);
        return;
      }
      try {
        const response = await axios.get(
          `${baseUrl}/all/property/sub/types/${propertyTypeId}`
        );
        console.log(response);
        if (response.data.statusType === "SUCCESS") {
          setPropertySubtypes(response.data.propertySubTypes);
        } else {
          setPropertySubtypes([]);
        }
      } catch (error) {
        console.error("Error fetching property subtypes:", error);
        setPropertySubtypes([]);
      }
    };

    fetchSubtypes(selectedPropertyType);
  }, [selectedPropertyType, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here

        const countryResponse = await axios.get(`${baseUrl}/all/countries`);
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }

        const purposeResponse = await axios.get(
          `${baseUrl}/all/property/purposes`
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          setPropertyPurposes(purposeResponse.data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.get(`${baseUrl}/all/property/types`);
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          console.log("Your Country id is", countryId);
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.get(
              `${baseUrl}/all/states/${countryId}`
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          console.log("your stateid is ", stateId);
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.get(
              `${baseUrl}/all/cities/${stateId}`
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCountry, selectedState, loggedInUser?.token]);

  const validationSchema = Yup.object({
    propertyType: Yup.string().required("Property type is required"),
    propertyPurpose: Yup.string().required("Property Purpose is required"),
    userType: Yup.string().required("User Type is required"),
  });

  return (
    <>
      <Notification />
      <div className="home-page">
        <section className="page-header parallaxie">
          <Container>
            <Row>
              <Col>
                <div className="page-header-box">
                  <h1 className="text-anime">Bringing Life</h1>
                  <nav className="wow fadeInUp">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Bringing Life To Interior
                      </li>
                    </ol>
                  </nav>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="property-by-city">
          <Container>
            <Row>
              <Col md={12}>
                <div className="section-title">
                  <h3 className="">Bringing Life To Interior</h3>
                  <h2 className="text-anime">Properties by Interior</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg1} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Office Interior</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg2} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Home Interior</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg3} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Commercial Interior</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="location-item">
                  <div className="location-image">
                    <figure>
                      <img src={cityImg4} alt="" />
                    </figure>
                  </div>
                  <div className="location-content">
                    <div className="location-header">
                      <h3>Jakarta</h3>
                      <p>22 Properties</p>
                    </div>

                    <div className="location-footer">
                      <Link to="#" className="btn-default">
                        See More
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="why-choose-us no-bg">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} orderLg={2}>
                <div className="why-choose-us-image">
                  <figure className="reveal image-anime">
                    <img src={PropertyImg4} alt="" />
                  </figure>
                </div>
              </Col>
              <Col lg={6} orderLg={1}>
                <div className="why-choose-us-content">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Our Benefits </h3>
                    <h2 className="text-anime">
                      Provide services to fulfill customers desire in terms of
                      property buy, sell & rent
                    </h2>
                  </div>

                  <div className="why-bosy">
                    <div className="about-content-body">
                      <ul>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout1} alt="" />
                          </div>
                          <span>
                            High quality interior design with low cost
                          </span>
                        </li>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout2} alt="" />
                          </div>
                          <span>
                            Dedicated interior architect align with customer
                          </span>
                        </li>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout3} alt="" />
                          </div>
                          <span>Full support after completion of work</span>
                        </li>
                        <li>
                          <div className="icon-box">
                            <img src={IconAbout4} alt="" />
                          </div>
                          <span>Experienced team</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <StatCounter />
        <section className="why-choose-us no-bg" ref={formRef}>
          <Container>
            <Row className="align-items-center">
              <Col lg={6} orderLg={1}>
                <div className="why-choose-us-content">
                  <div className="section-title">
                    <h3 className="wow fadeInUp">Share your query</h3>
                    <h2 className="text-anime">
                      Share your query with us, we will accomplish hassle-free
                    </h2>
                  </div>

                  <div className="why-bosy p-3">
                    <div className="property-inquiry-form">
                      <Formik
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ isSubmitting, setFieldValue, values }) => (
                          <Form>
                            <Row>
                              <label
                                htmlFor="title"
                                className="form-label mt-1 mb-2"
                              >
                                I Wish To:
                              </label>
                              <FieldArray name="propertyPurpose">
                                {() => (
                                  <>
                                    {propertyPurposes.map((purpose) => (
                                      <Col key={purpose.id}>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            id={`purpose-${purpose.id}`}
                                            value={purpose.id}
                                            checked={
                                              values.propertyPurpose ===
                                              purpose.id
                                            }
                                            onChange={() => {
                                              setFieldValue(
                                                "propertyPurpose",
                                                purpose.id
                                              );
                                              setSelectedPropertyPurpose(
                                                purpose.id
                                              );
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`purpose-${purpose.id}`}
                                          >
                                            {purpose.name}
                                          </label>
                                        </div>
                                      </Col>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                              <ErrorMessage
                                name="propertyPurpose"
                                component="div"
                                className="text-danger"
                              />
                            </Row>

                            <Row className="mt-3">
                              <Col md={6}>
                                <label htmlFor="budget" className="form-label">
                                  Budget
                                </label>
                                <Field
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="budget"
                                  placeholder="Enter Budget"
                                  name="budget"
                                  style={{
                                    height: "2.3rem",
                                    padding: ".375rem 2.25rem .375rem .75rem",
                                    backgroundColor: "#fff",
                                  }}
                                />
                                <ErrorMessage
                                  name="budget"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                              <Col md={6}>
                                <label
                                  htmlFor="description"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <Field
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="description"
                                  placeholder="Enter Description"
                                  name="description"
                                  style={{
                                    height: "2.3rem",
                                    padding: ".375rem 2.25rem .375rem .75rem",
                                    backgroundColor: "#fff",
                                  }}
                                />
                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                              <Col md={6} className="mt-3">
                                <label
                                  htmlFor="propertyType"
                                  className="form-label"
                                >
                                  Property Type
                                </label>
                                <Field
                                  as="select"
                                  className="form-select"
                                  id="propertyType"
                                  name="propertyType"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "propertyType",
                                      e.target.value
                                    );
                                    setSelectedPropertyType(e.target.value);
                                  }}
                                >
                                  <option
                                    value=""
                                    label="Select property type"
                                  />
                                  {propertyTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="propertyType"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>

                              <Col md={6} className="mt-3">
                                <label
                                  htmlFor="propertySubtype"
                                  className="form-label"
                                >
                                  Property Subtype
                                </label>
                                <Field
                                  as="select"
                                  className="form-select"
                                  id="propertySubtype"
                                  name="propertySubtype"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "propertySubtype",
                                      e.target.value
                                    );
                                  }}
                                  disabled={!selectedPropertyType}
                                >
                                  <option value="">
                                    Select Property Subtype
                                  </option>
                                  {propertySubtypes.map((subtype) => (
                                    <option key={subtype.id} value={subtype.id}>
                                      {subtype.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="propertySubtype"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3 mt-3">
                              <Col md={6}>
                                <label htmlFor="state" className="form-label">
                                  State
                                </label>
                                <Field
                                  as="select"
                                  className="form-select"
                                  id="state"
                                  name="state"
                                  onChange={(e) => {
                                    const stateId = e.target.value;
                                    setFieldValue("state", stateId);
                                    setSelectedState(stateId);
                                    setFieldValue("city", "");
                                    setCities([]);
                                  }}
                                  value={values.state}
                                >
                                  <option value="">Select state</option>
                                  {states.map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="state"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                              <Col md={6}>
                                <label htmlFor="city" className="form-label">
                                  City
                                </label>
                                <Field
                                  as="select"
                                  className="form-select"
                                  id="city"
                                  name="city"
                                  disabled={isCityDisabled}
                                  value={values.city}
                                >
                                  <option value="">Select city</option>
                                  {cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                      {city.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                              <Col md={6} className="mt-3">
                                <label htmlFor="area" className="form-label">
                                  Area
                                </label>
                                <Field
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="area"
                                  placeholder="Enter area"
                                  name="area"
                                  style={{
                                    height: "2.3rem",
                                    padding: ".375rem 2.25rem .375rem .75rem",
                                    backgroundColor: "#fff",
                                  }}
                                />
                                <ErrorMessage
                                  name="area"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                              <Col md={6} className="mt-3">
                                <label
                                  htmlFor="featuredProperty"
                                  className="form-label"
                                >
                                  Featured Property
                                </label>
                                <Field
                                  as="select"
                                  className="form-select"
                                  id="featuredProperty"
                                  name="featuredProperty"
                                >
                                  <option value="">Featured Property</option>
                                  <option value="true">Yes</option>
                                  <option value="false">No</option>
                                </Field>
                                <ErrorMessage
                                  name="featuredProperty"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-end mt-3">
                              <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} orderLg={2}>
                <div className="why-choose-us-image">
                  <figure className="reveal image-anime">
                    <img src={RightContImg} alt="" />
                  </figure>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default InteriorDesign;
