import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaEnvelope, FaRegTrashAlt, FaUpload, FaWhatsapp } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import photo from "./photo.png";
import pdf from "./pdf.png";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { nextStep,prevStep } from "../../features/partnerRegistration/partnerSlice";
import Notification, { showToast } from "../common/notification/Notification";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const UploadDocument = () => {
  const dispatch = useDispatch();
  const partnerData = useSelector((state) => state.partner.formData);
  const partner_id = secureLocalStorage.getItem("partner_id");
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  // Removed allDocumentsUploaded state
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Required document types
  const requiredDocumentTypes = ["aadhar", "pan", "reraregistration"];

  // Handle file drop
  const onDrop = (acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) => ({
      file,
      documentType: "",
      uploaded: false,
    }));
    setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  // Remove file from list
  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  // Handle change in document type selection
  const handleDocumentTypeChange = (index, newType) => {
    const updatedFiles = [...files];
    updatedFiles[index].documentType = newType;
    setFiles(updatedFiles);
  };

  // Function to upload a file
  const uploadFile = async (file, documentType, index) => {
    const formData = new FormData();
    formData.append("name", documentType);
    partnerData?.id !== undefined
      ? formData.append("user_id", partnerData?.id)
      : formData.append("user_id", partner_id);

    formData.append("file", file);

    try {
      const response = await axios.post(
        `${baseUrl}/all/user/document/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.statusType === "SUCCESS") {
        const updatedFiles = [...files];
        updatedFiles[index].uploaded = true; // Mark as uploaded
        setFiles(updatedFiles);

        // Update uploaded files status
        setUploadedFiles((prevUploadedFiles) => {
          const newUploadedFiles = {
            ...prevUploadedFiles,
            [documentType]: true,
          };

          // Save uploaded files to local storage
          secureLocalStorage.setItem(
            "uploadedFiles",
            JSON.stringify(newUploadedFiles)
          );

          return newUploadedFiles;
        });

        showToast(`${response?.data?.document?.name} uploaded successfully`, {
          type: "success",
        });
      }
    } catch (error) {
      showToast("Error Uploading Document", {
        type: "danger",
      });
    }
  };

  // Load previously uploaded files from local storage on mount
  useEffect(() => {
    const storedUploadedFiles = secureLocalStorage.getItem("uploadedFiles");
    console.log(storedUploadedFiles);
    if (storedUploadedFiles) {
      setUploadedFiles(JSON.parse(storedUploadedFiles));
    }
  }, []);

  // Get the appropriate icon for a file based on its extension
  const getFileIcon = (file) => {
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension === "pdf") {
      return <img src={pdf} alt="PDF" style={{ width: "3rem" }} />;
    } else if (
      extension === "jpg" ||
      extension === "png" ||
      extension === "jpeg"
    ) {
      return <img src={photo} alt="Image" style={{ width: "3rem" }} />;
    } else {
      // Handle other file types if necessary
      return null;
    }
  };

  // Initialize the dropzone
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Check if at least one required document has been uploaded
  const checkAnyDocumentsUploaded = () => {
    // Return true if at least one document type is present in uploadedFiles
    return requiredDocumentTypes.some(
      (docType) => uploadedFiles[docType] === true
    );
  };

  // Update the state to determine if the "Next" button should be enabled
  const isNextButtonEnabled = checkAnyDocumentsUploaded();

  // Handle the "Next" button click
  const handleNextButton = () => {
    dispatch(nextStep());
  };

  const handlePrevious = () => {
    dispatch(prevStep())
  }
  return (
    <>
      <Notification />
      <Row className="mb-5">
        <Col md={12}>
          <div
            style={{
              margin: "3rem auto",
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "#f7f7f7",
            }}
          >
            <div>
              <h4>Required Documents</h4>
              {requiredDocumentTypes.map((docType, index) => (
                <span
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="mb-0">
                    {docType === "reraregistration" ? (
                      <>
                        {index + 1}.{" "}
                        {docType
                          .replace(/([A-Z])/g, " $1") // Insert a space before uppercase letters
                          .replace(/rera/gi, "RERA ") // Replace "rera" with "RERA "
                          .replace(/registration/gi, "Registration") // Ensure "registration" is formatted as "Registration"
                          .trim()
                          .toLocaleUpperCase()}{" "}
                        Certificate
                      </>
                    ) : (
                      <>
                        {index + 1}.{" "}
                        {docType
                          .replace(/([A-Z])/g, " $1")
                          .trim()
                          .toLocaleUpperCase()}{" "}
                        Card
                      </>
                    )}
                    {/* {index + 1}.{" "}
                    {docType
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toLocaleUpperCase()}{" "}
                    Card */}
                  </p>
                  {uploadedFiles[docType] ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <span style={{ color: "red" }}>Not Uploaded</span>
                  )}
                </span>
              ))}
            </div>
            <div className="mt-3">
              <h4>Upload Documents</h4>
              {/* Note: Please upload at least one required file in PDF format or PNG or JPEG. */}
              <p
                style={{ color: "red", fontStyle: "italic", fontSize: "14px" }}
              >
                Note: Please upload at least one required file in PDF format,
                PNG, or JPEG.
              </p>
              <div
                {...getRootProps({ className: "dropzone" })}
                style={{
                  border: "2px dashed #007bff",
                  padding: "20px",
                  textAlign: "center",
                  borderRadius: "8px",
                  backgroundColor: "#ffffff",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
              >
                <input {...getInputProps()} />
                <p>
                  <span style={{ color: "#007bff" }}>Upload </span>
                  or drag and drop files here
                </p>
              </div>
            </div>

            {files.length > 0 && (
              <div
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  padding: "10px",
                  marginTop: "1rem",
                }}
              >
                {files.map((fileWrapper, index) => (
                  <React.Fragment key={index}>
                    <Row style={{ marginBottom: "10px", alignItems: "center" }}>
                      <Col md={1} sm={2}>
                        {getFileIcon(fileWrapper.file)}
                      </Col>
                      <Col
                        md={5}
                        sm={3}
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        <span>{fileWrapper.file.name}</span>
                      </Col>
                      <Col lg={4} md={3} sm={4}>
                        <Form.Select
                          value={fileWrapper.documentType}
                          onChange={(e) =>
                            handleDocumentTypeChange(index, e.target.value)
                          }
                          style={{ width: "100%" }}
                        >
                          <option value="">Select document type</option>
                          <option value="aadhar">Aadhar Card</option>
                          <option value="pan">PAN Card</option>
                          <option value="reraregistration">
                            Rera Registration
                          </option>
                        </Form.Select>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={3}
                        className="buttonBlock"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="outline-success"
                          className="upload_button"
                          size="sm"
                          onClick={() =>
                            uploadFile(
                              fileWrapper.file,
                              fileWrapper.documentType,
                              index
                            )
                          }
                          disabled={
                            !fileWrapper.documentType || fileWrapper.uploaded
                          } // Disable upload if no type is selected or already uploaded
                        >
                          <span>
                            <FaUpload />
                          </span>
                          <span style={{ marginLeft: "5px" }}>Upload</span>
                        </Button>

                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => removeFile(index)}
                          disabled={fileWrapper.uploaded} // Disable remove if uploaded
                        >
                          <FaRegTrashAlt />
                        </Button>
                      </Col>
                    </Row>
                    <hr className="mt-3" />
                  </React.Fragment>
                ))}
              </div>
            )}
            <Col md={12} className="d-flex justify-content-end mb-3">
              <Button
                className="btn btn-primary"
                onClick={handleNextButton}
                disabled={!isNextButtonEnabled} // Enable "Next" if at least one document is uploaded
              >
                Next
              </Button>
            </Col>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UploadDocument;
