import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  InputGroup,
  Row,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import parse from "html-react-parser";
import { FaEllipsisV, FaComment } from "react-icons/fa";

import Api from "../../components/api/Api";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import { useSelector } from "react-redux";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import PostComment from "../post/PostComment";
import AdminHeader from "../../common/Header";
const QueryItem = () => {
  const { queryId } = useParams();
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [userDetail, setUserDetails] = useState();
  const [singleData, setSingleData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `admin/desiredQuery/${queryId}`,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchDataBySingleId();
        console.log("your response data is", responseData);
        setUserDetails(responseData.data.desiredQuery);
        setSingleData(responseData.data.desiredQuery);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Queries", link: "/query" },
    { label: "Query Detail", link: "", active: true },
  ];
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/admin/deleteDesiredQuery/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        showToast("Query deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        // <Container style={{ marginTop: "2rem" }}>
        //   <BreadCrumb items={breadcrumbItems} />
        //   <SingleDetailHeader
        //     header="Query Details"
        //     description="See the query details section"
        //   />

        //   <Row>
        //     <Col>
        //       <SingleDetail
        //         label="Service"
        //         userDetail={userDetail?.service.name}
        //       />
        //     </Col>
        //     <Col>
        //       <SingleDetail
        //         label="Service Description"
        //         userDetail={userDetail?.service.description}
        //       />
        //     </Col>
        //   </Row>
        //   <Row>
        //     <Col>
        //       <SingleDetail label="Name" userDetail={userDetail?.name} />
        //     </Col>

        //     <Col>
        //       <SingleDetail
        //         label="Mobile Number"
        //         userDetail={userDetail?.mobile_number}
        //       />
        //     </Col>
        //   </Row>
        //   <Row>
        //     <Col>
        //       <SingleDetail
        //         label="Email Address"
        //         userDetail={userDetail?.email_address}
        //       />
        //     </Col>
        //   </Row>
        //   <Row>
        //     <Col className={Style.col} md={12}>
        //       <label className={Style.label}>Query</label>
        //       <textarea className={Style.textarea1}>
        //         {userDetail?.query}
        //       </textarea>{" "}
        //     </Col>
        //   </Row>
        //   <div className={Style.btnCol}>
        //     <Button className={`btn btn-primary`}>Edit User Details</Button>
        //   </div>
        // </Container>
        <>
          <Notification />
          <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
            <BreadCrumb items={breadcrumbItems} />
            <Row>
              <Col lg={3} md={4} sm={12}>
                <AdminHeader />
              </Col>
              <Col lg={9} md={8} sm={12}>
                <Row className="mainCard mt-3 ">
                  <Col lg={12} md={12} sm={12}>
                    <SingleDetailHeader
                      header="Query Detail"
                      description="See the query detail section"
                    />
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mainCardAreaInside">
                    <div className="card mb-5 ">
                      <Row className="">
                        <Col
                          md="auto"
                          sm="auto"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                        >
                          <div className="profilePic">
                            <img src="https://plus.unsplash.com/premium_photo-1661964217492-70800dc09cac?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzM3x8fGVufDB8fHx8fA%3D%3D" />
                          </div>
                        </Col>
                        <Col md={10} sm={8}>
                          <Col md={12}>
                            <div
                              className="cardTop"
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="nameSection">
                                <p>{singleData?.name}</p>
                              </div>
                              <div className="badgebootstrap ">
                                {singleData?.service?.name === "Buy" ? (
                                  <Badge bg="success">
                                    {singleData?.service?.name}
                                  </Badge>
                                ) : singleData?.service?.name === "Sell" ? (
                                  <Badge bg="danger">
                                    {singleData?.service?.name}
                                  </Badge>
                                ) : singleData?.service?.name === "Rent" ? (
                                  <Badge bg="secondary">
                                    {singleData?.service?.name}
                                  </Badge>
                                ) : singleData?.service?.name ===
                                  "Interior Design" ? (
                                  <Badge bg="primary">
                                    {singleData?.service?.name}
                                  </Badge>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="timeAgo ">
                                <TimeAgo
                                  timestamp={singleData?.created_on || null}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md={12}>
                            <Row>
                              <Col md={12} className="mt-1">
                                <div className="allCard">
                                  <Row>
                                    <Col md={4} sm={6} className="mt-2">
                                      <div className="smallCard">
                                        <p>
                                          State: {singleData?.city?.state?.name}{" "}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col md={4} sm={6} className="mt-2">
                                      <div className="smallCard">
                                        <p>City: {singleData?.city?.name}</p>
                                      </div>
                                    </Col>
                                    <Col md={4} sm={6} className="mt-2">
                                      <div className="smallCard">
                                        <p>Area: {singleData?.area?.name} </p>
                                      </div>
                                    </Col>
                                    <Col md={4} sm={6} className="mt-2">
                                      <div className="smallCard extraSmall">
                                        <p>
                                          {" "}
                                          Email: {
                                            singleData?.email_address
                                          }{" "}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col md={4} sm={6} className="mt-2">
                                      <div className="smallCard extraSmall">
                                        <p>
                                          {" "}
                                          Mobile Number:{" "}
                                          {singleData?.mobile_number}{" "}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={12} className="mt-3">
                                <p> {parse(`${singleData?.query}`)}</p>
                              </Col>
                              {/* <Col md={12} className="mt-3">
                            <div className="comment-section">
                              {" "}
                              <Link to={`/query/${singleData?.id}`}>
                                <FaComment
                                  className="faComment"
                                  style={{
                                    backgroundColor: "#f5f5f5",
                                  }}
                                />
                                <span className="comment-count">10</span>
                              </Link>
                            </div>
                          </Col> */}
                            </Row>
                          </Col>
                        </Col>
                        <Col md={1} sm={2}>
                          <div className="menu">
                            {/* <FaEllipsisV /> */}
                            <DropdownButton
                              id="dropdown-basic-button"
                              title={<FaEllipsisV />}
                              variant="Secondary"
                              style={{ color: "#fff" }}
                            >
                              {/* <Dropdown.Item>
                    <Link to={`/post/${singleData?.id}`}>
                      View
                    </Link>
                  </Dropdown.Item> */}
                              {console.log(
                                "singleData id is ",
                                singleData?.user?.id
                              )}
                              {(singleData?.user?.id === userId ||
                                userType === "SUPER_ADMIN") && (
                                <>
                                  <Dropdown.Item>
                                    <Link
                                      to={``}
                                      onClick={() =>
                                        handleDelete(singleData?.id)
                                      }
                                    >
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                </>
                              )}
                            </DropdownButton>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12}>
                    <Row style={{ marginTop: "2rem" }}>
                      <div>
                        {/* <Button className={`btn btn-primary`}>Edit</Button> */}
                        <Link
                          // style={{ marginLeft: "1rem" }}
                          className="btn btn-primary"
                          to={"/query"}
                        >
                          Back
                        </Link>
                      </div>
                    </Row>
                  </Col>
                  <Col lg={12} md={12} sm={12}>
                    <div className="card">
                      <Row className="">
                        <PostComment />
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default QueryItem;
