import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Col, Container, Row, Button } from "react-bootstrap";
import * as Yup from "yup";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserProfile from "../userProfile/UserProfile";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";

const MainPassowd = () => {
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "User", link: "/user" },
    { label: "Change Password", link: "", active: true },
  ];
  return (
    <>
      <>
        <Notification />
        <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
          <Row>
            <BreadCrumb items={breadcrumbItems} />
            <Col md={3}>
              <UserProfile />
            </Col>
            <Col md={9}>
              <SingleDetailHeader
                header="Change Password"
                description="Change User Password details section"
              />
              <ChangePassword />
            </Col>
          </Row>
        </Container>
      </>
    </>
  );
};
const ChangePassword = () => {
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const changePassword = Api({
    url: "admin/changePassword",
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = {
        userId: +partnerId,
        password: values.confirmPassword,
      };
      console.log(data);
      const responseData = await changePassword.editData(
        data,
        "multipart/form-data"
      );

      if (responseData.data.statusType === "SUCCESS") {
        showToast("Password Changes Successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/partners");
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Notification />
      <Container>
        <Row>
          <Col md={12}>
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object({
                newPassword: Yup.string()
                  .required("New Password is required")
                  .min(6, "Password must be at least 6 characters"),
                confirmPassword: Yup.string()
                  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                  .required("Confirm Password is required"),
              })}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row>
                    <Col>
                      <div>
                        <label htmlFor="newPassword">New Password</label>
                        <Field
                          type="password"
                          name="newPassword"
                          className="form-control"
                        />
                        <ErrorMessage name="newPassword" component="div" />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                        />
                        <ErrorMessage name="confirmPassword" component="div" />
                      </div>
                    </Col>
                  </Row>
                  <button
                    type="submit"
                    className="btn btn-primary mt-5 mb-5"
                    disabled={isSubmitting}
                  >
                    Change Password
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { MainPassowd, ChangePassword };
