import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import image from "./image.png";
import "./style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { prevStep,resetForm } from "../../features/partnerRegistration/partnerSlice";
import secureLocalStorage from "react-secure-storage";

const ThankYou = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNextButton = () => {
    dispatch(resetForm());
    secureLocalStorage.clear();
    navigate("/");
  };
  const handlePrevious = () => {
    dispatch(prevStep())
  }

  return (
    <>
      <div>
        <Row>
          <div className="content">
            <Col>
              <img src={image} className="successImg" />
            </Col>
            <Col>
              <div className="word-content">
                <h1 className="gretting">Thank You</h1>
                <p className="wordTag">
                  Thank you for promptly submitting the required documents. We
                  appreciate your cooperation and diligence in completing this
                  step. Your application is currently under review. We will send
                  you a confirmation email shortly with your registration
                  details once your application is approved
                </p>
              </div>
            </Col>
            <Col md={12} className="d-flex justify-content-between mb-3">
              <button className="btn btn-primary" onClick={handlePrevious}>
               Previous
              </button>
              <button className="btn btn-primary" onClick={handleNextButton}>
                Next
              </button>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
};

export default ThankYou;
