import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  ProgressBar,
  Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Api from "../../../admin/components/api/Api";
import BreadCrumb from "../../../admin/components/breadcrumb/BreadCrumb";
import AdminHeader from "../../../admin/common/Header";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import List from "../../../admin/components/listing/List";
import Tr from "../../../admin/components/tr/Tr";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../admin/components/pagination/Pagination";

const VendorContactUs = () => {
  const [data, setData] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const propertyApi = Api({
    url: "user/contact/all",
  });
  const navigate = useNavigate();
  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: (acceptedFiles) => {
      setCsvFile(acceptedFiles[0]);
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/user/contact/delete/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("User deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };

  const handleApiCall = async () => {
    if (csvFile) {
      try {
        setUploading(true);

        const formData = new FormData();
        formData.append("file", csvFile);

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            setUploadProgress(percentCompleted);
          },
        };

        const response = await axios.put(
          `${baseUrl}/user/contact/upload`,
          formData,
          config
        );

        console.log("API Response:", response.data);
        if (response?.data?.statusType === "SUCCESS") {
          showToast("User Details Uploaded successfully", {
            type: "success",
          });
          setUploading(false);
          fetchPropertyData();
        } else {
          showToast("Cannot Add Same Data Everytime", {
            type: "error",
          });
        }
        // Handle response
      } catch (error) {
        console.error("API Error:", error);
        // Handle error
      }
    } else {
      console.error("No CSV file selected");
    }
  };
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      const responseData = await propertyApi.fetchData(pageNumber);
      setData(responseData.data.contacts);
      console.log(responseData.data.contacts);
      setTotalPages(responseData.data.totalPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPropertyData(currentPage);
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "First Name", key: "first_name" },
    { displayName: "Mobile Number", key: "mobile_number" },
    { displayName: "Email Address", key: "email_address" },
  ];

  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Customer Management", link: "/contact", active: true },
  ];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Notification />
      <div className="content-body">
        <Container fluid style={{ padding: "0rem 2rem" }}>
          {/* Delete Alert */}
          <BreadCrumb items={breadcrumbItems} />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>
            <Col lg={9} md={8} sm={12}>
              {data.length === 0 ? (
                <>
                  <Tr
                    columns={columns}
                    initialValues={initialValues}
                    title="No Contact Found"
                    buttonName="Create"
                    btnLink="/contact/add-contact"
                    titleHeader="Customer Management"
                    btnTwo="Bulk Upload"
                    handleShow={handleShow}
                  />
                </>
              ) : (
                <>
                  <div className=" mt-3  mb-4">
                    <List
                      title="Customer Management"
                      data={data}
                      columns={columns}
                      button="Create"
                      btnTwo="Bulk Upload"
                      handleShow={handleShow}
                      to="/contact/add-contact"
                      route="contact"
                      edit="edit"
                      handleDelete={handleDelete}
                      role={"admin"}
                      user={false}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          style={{ zIndex: 99999999 }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="container" style={{ padding: "0" }}>
              <div
                {...getRootProps({ className: "dropzone" })}
                style={{
                  border: "3px dotted black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "10rem",
                }}
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <aside className="mt-3">
                {files.length > 0 && (
                  <>
                    <h4>Files</h4>
                    <ul>{files}</ul>
                  </>
                )}
              </aside>
              {uploading && (
                <ProgressBar
                  variant="success"
                  now={uploadProgress}
                  animated
                  label={`${uploadProgress}%`}
                />
              )}
              <button
                className="btn btn-primary mt-3 btn-lg mb-4"
                onClick={handleApiCall}
                disabled={uploading}
              >
                {uploading ? "Uploading..." : "Upload Bulk Data"}
              </button>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default VendorContactUs;
