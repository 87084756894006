import React, { useState, useEffect } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import Style from "../../../admin/UserStyle.module.css";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Api from "../../../admin/components/api/Api";
import SingleDetailHeader from "../../../admin/components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../../admin/components/singleDetail/SingleDetail";
import BreadCrumb from "../../../admin/components/breadcrumb/BreadCrumb";
import AdminHeader from "../../../admin/common/Header";

const ContactUsItem = () => {
  const { contactId } = useParams();
  const [userDetail, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `user/contact/id/${contactId}`,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData();

        setUserDetails(responseData.data.contact);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Customer Management", link: "/contact" },
    { label: "Customer Details", link: "", active: true },
  ];
  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
          <BreadCrumb items={breadcrumbItems} />
          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>
            <Col lg={9} md={8} sm={12}>
              <SingleDetailHeader
                header="Customer Details"
                description="See the Customer details section"
              />

              <Row>
                <Col>
                  <SingleDetail
                    label="First Name"
                    userDetail={userDetail?.first_name}
                  />
                </Col>
                <Col>
                  <SingleDetail
                    label="Last Name"
                    userDetail={userDetail?.last_name}
                  />
                </Col>
                <Col>
                  <SingleDetail
                    label="Email Address"
                    userDetail={userDetail?.email_address}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SingleDetail
                    label="Mobile Number"
                    userDetail={userDetail?.mobile_number}
                  />
                </Col>
                <Col>
                  <SingleDetail
                    label="Country"
                    userDetail={userDetail?.country_name}
                  />
                </Col>

                <Col>
                  <SingleDetail
                    label="State"
                    userDetail={userDetail?.state_name}
                  />
                </Col>

                {/* <Col>
              <SingleDetail
                label="Address 1"
                userDetail={userDetail?.address1}
              />
            </Col> */}
              </Row>
              {/* <Row>
            <Col>
              <SingleDetail label="Street" userDetail={userDetail?.street} />
            </Col>
         
          </Row> */}
              <Row>
                <Col>
                  <SingleDetail
                    label="City"
                    userDetail={userDetail?.city_name}
                  />
                </Col>
                <Col>
                  <SingleDetail
                    label="Area"
                    userDetail={userDetail?.area_name}
                  />
                </Col>
                {/* <Col>
              <SingleDetail
                label="Apartment"
                userDetail={userDetail?.apartment}
              />
            </Col> */}
                <Col>
                  <SingleDetail
                    label="Zip Code"
                    userDetail={userDetail?.zip_code}
                  />
                </Col>
              </Row>

              <div className={Style.btnCol}>
                <Link
                  className={`btn btn-primary`}
                  to={`/contact/${contactId}/edit/`}
                >
                  Edit
                </Link>

                <Link
                  style={{ marginLeft: "1rem" }}
                  className="btn btn-primary "
                  to={"/contact"}
                >
                  Back
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ContactUsItem;
