import React, { useState, useEffect } from "react";
import {
  Col,
  Badge,
  Card,
  Carousel,
  Tooltip,
  OverlayTrigger,
  Alert,
  Button,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import "../Property/property.style.css";
import Api from "../api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import axios from "axios";
import Image from "../../../assets/images/noimage.jpg";
import { FaEye, FaEyeSlash, FaPlus } from "react-icons/fa";
import { setFormData } from "../../../features/formData/formSlice";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const PropertyLikePostCard = ({ data, assetUrl, fetchPropertyData }) => {
  const { userDetails, token } = useSelector((state) => state.auth.user);
  const userId = userDetails?.id;
  const dispatch = useDispatch();
  const userType = userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [itemIdToPublish, setItemIdToPublish] = useState(null);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [datas, setDatas] = useState([]);
  const [propertyData, setPropertyData] = useState(data);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handlePublishModalClose = () => setShowPublishModal(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = (itemId) => {
    setShowDeleteAlert(true);
    setItemIdToDelete(itemId);
  };
  const handlePublishAlertClose = () => setShowPublishAlert(false);
  const handlePublishAlertShow = (singleObject) => {
    setShowPublishAlert(true);
    setItemIdToPublish(singleObject);
  };
  const handlePublishModalShow = (singleObject) => {
    setShowPublishModal(true);
    setItemIdToPublish(singleObject);
  };

  const handleDeleteModalShow = (itemId) => {
    setShowDeleteModal(true);
    setItemIdToDelete(itemId);
  };
  const togglePropertyVisibility = () => {
    handleHideData(itemIdToPublish);
  };

  useEffect(() => {
    const filteredData = data.filter(
      (singleData) => singleData?.customer_id === userId
    );
    setPropertyData(filteredData);
  }, [data]);

  const addFavouriteApi = Api({ url: `property/favorite/add` });

  const handleFollowOption = async (id, isFavorite) => {
    const dataId = { lead_id: +id };
    if (isFavorite) {
      // Unfollow action
      try {
        const removeFavourite = await axios.delete(
          `${baseUrl}/property/favorite/delete/${isFavorite}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (removeFavourite.data.statusType === "SUCCESS") {
          showToast("Property Removed From Favourite", { type: "success" });
          const updatedData = propertyData.map((singleData) =>
            singleData.id === +id
              ? {
                  ...singleData,
                  is_favorite: !singleData.is_favorite,
                  favorite_id: null,
                }
              : singleData
          );
          setPropertyData(updatedData);
        } else {
          showToast("Error Removing Property From Favourite", {
            type: "error",
          });
        }
      } catch (error) {
        showToast("Error Removing Property From Favourite", { type: "error" });
      }
    } else {
      // Follow action
      try {
        const addFavourite = await addFavouriteApi.postData(dataId);
        console.log(addFavourite);
        if (addFavourite.data.statusType === "SUCCESS") {
          const updatedData = propertyData.map((singleData) =>
            singleData.id === +id
              ? {
                  ...singleData,
                  is_favorite: !singleData.is_favorite,
                  favorite_id: addFavourite.data.favorite.id,
                }
              : singleData
          );
          setPropertyData(updatedData);
          showToast("Property Added To Favourite", { type: "success" });
        } else {
          showToast("Error Adding Property To Favourite", { type: "error" });
        }
      } catch (error) {
        showToast("Error Adding Property To Favourite", { type: "error" });
      }
    }
  };

  const getBadgeColor = (purpose) => {
    switch (purpose) {
      case "Buy":
        return "success";
      case "Sell":
        return "danger";
      case "Rent":
        return "secondary";
      default:
        return "primary";
    }
  };

  const handleHideData = async (item) => {
    try {
      console.log(item);
      const response = await axios.put(
        `${baseUrl}/property/publish/status`,
        {
          lead_id: item.id,
          is_publish: !item.is_published,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.statusType === "SUCCESS") {
        showToast("Post published successfully", {
          type: "success",
        });
        handlePublishModalClose();
        await fetchPropertyData();
      }
      console.log("your response for updating status", response);
    } catch (error) {
      console.error("Error 'updating ' item:", error);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/property/lead/delete/${itemIdToDelete}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusType === "SUCCESS") {
        setPropertyData(propertyData.filter((item) => item.id !== itemId));
        showToast("Post deleted successfully", {
          type: "success",
        });
        // handlePublishAlertClose();
        handleDeleteModalClose();
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };

  const addFormData = (id) => {
    const filterData = data.filter((singleData) => singleData?.id === +id);
    console.log(filterData);
    // const propertyType = propertyTypes.find(
    //   (type) => type.id === +values.propertyType
    // );
    // const propertyPurpose = propertyPurposes.find(
    //   (purpose) => purpose.id === +values.propertyPurpose
    // );
    console.log(filterData);
    const formData = {
      propertyPurpose: filterData[0].purpose_id,
      propertyType: filterData[0].type_id,
      city: filterData[0]?.city_id,
      state: filterData[0]?.state_id,
      area: filterData[0]?.area_name,
      lead_id: filterData[0]?.id,
      name: filterData[0]?.title,
      price: filterData[0]?.budget,
      description: filterData[0]?.description,
      country_name: 1,
      propertySubtype: +filterData[0]?.sub_type_id,
      featured_property: filterData[0].featured_property,
      images: filterData[0].images,
    };
    console.log(formData);
    dispatch(setFormData(formData));
  };

  return (
    <>
      <Notification />
      <Modal
        show={showDeleteModal}
        onHide={handleDeleteModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this property?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleDeleteModalClose}
            variant="outline-danger"
            className="me-3"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Publish Modal */}
      <Modal
        show={showPublishModal}
        onHide={handlePublishModalClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Publish</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to publish this property?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handlePublishModalClose}
            variant="outline-secondary"
            className="me-3"
          >
            Cancel
          </Button>
          <Button onClick={togglePropertyVisibility} variant="success">
            {console.log(itemIdToPublish)}
            {itemIdToPublish?.is_published ? "Un Publish" : "Publish"}
          </Button>
        </Modal.Footer>
      </Modal>
      {propertyData?.map((singleData) => (
        <Col lg={6} md={12} sm={12} key={singleData.id}>
          <div
            className={`mainPropertyCard ${getBadgeColor(
              singleData?.purpose_name
            )}`}
            style={{ border: "1px solid" }}
          >
            {/* {singleData?.status_name === "New" && (
              <div className="c-ribbon c-ribbon--top c-ribbon--left">
                {" "}
                {singleData?.status_name}
              </div>
            )} */}
            <div className="mainPropertyCardFirst">
              <div className="d-flex justify-content-start align-items-center topNavbar">
                <Badge
                  bg={
                    singleData?.purpose_name === "Buy"
                      ? "secondary"
                      : singleData?.purpose_name === "Sell"
                      ? "secondary"
                      : singleData?.purpose_name === "Rent"
                      ? "secondary"
                      : "secondary"
                  }
                  style={{ fontSize: "14px" }}
                >
                  {singleData?.purpose_name}
                </Badge>
                <Badge
                  bg="secondary"
                  style={{ fontSize: "14px", marginLeft: "1rem" }}
                >
                  {singleData?.type_name}
                </Badge>
                <Badge
                  bg="secondary"
                  style={{ fontSize: "14px", marginLeft: "1rem" }}
                >
                  {singleData?.sub_type_name}
                </Badge>
                {/* <Badge
                  bg="danger"
                  style={{ fontSize: "14px", marginLeft: "1rem" }}
                >
                  {singleData?.status?.name}
                </Badge> */}
                {singleData?.is_favorite && (
                  <Badge
                    bg="secondary"
                    style={{ fontSize: "14px", marginLeft: "1rem" }}
                  >
                    Followed
                  </Badge>
                )}
              </div>
            </div>
            <div className="mainPropertyCardSecond">
              <div className="mainPropertySecondFirst">
                <div className="swiper-image">
                  {singleData.images?.length > 0 ? (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="swiper"
                    >
                      {singleData?.images?.map((singleImage) => (
                        <SwiperSlide key={singleImage.image_url}>
                          {singleImage?.image_url?.includes("mp4") ? (
                            <video
                              autoPlay
                              muted
                              loop
                              controls
                              className="main-video"
                            >
                              <source
                                src={`https://images.allhomedesire.in/${singleImage.image_url}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              className="main-image"
                              src={`https://images.allhomedesire.in/${singleImage.image_url}`}
                              alt="Property"
                            />
                          )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="swiper"
                    >
                      <SwiperSlide>
                        <img
                          src={Image}
                          alt="no image"
                          className="main-image"
                        />
                      </SwiperSlide>
                    </Swiper>
                  )}
                </div>
                <div className="property-details  ms-3">
                  <div className="property-location">
                    <div className="badge-like ">
                      <span className="location-label">State:</span>
                      <span className="location-value">
                        {singleData?.state_name}
                      </span>
                    </div>
                    <div className="badge-like middleBadge">
                      <span className="location-label">City:</span>
                      <span className="location-value">
                        {singleData?.city_name}
                      </span>
                    </div>
                    <div className="badge-like">
                      <span className="location-label">Area:</span>
                      <span className="location-value">
                        {singleData?.area_name}
                      </span>
                    </div>
                  </div>
                  <h4 className="property-title">₹{singleData?.budget}</h4>
                  <h4 className="property-title">{singleData?.title}</h4>
                  <p className="property-description">
                    {singleData?.description.length > 100 ? (
                      <>
                        {parse(
                          `${singleData?.description?.substring(0, 200)}...`
                        )}
                        <Link
                          variant="link"
                          className="p-0 text-primary fw-bold"
                          to={`/post/${singleData?.id}`}
                        >
                          More
                        </Link>
                      </>
                    ) : (
                      parse(singleData?.description)
                    )}
                  </p>
                </div>
              </div>

              <div
                className="actions-btn"
                style={{
                  gap: "0.5rem",
                }}
              >
                {singleData?.customer_id !== userId && (
                  <Link
                    variant="outline-success"
                    className="btn btn-success"
                    onClick={() =>
                      handleFollowOption(
                        singleData?.id,
                        singleData?.favorite_id
                      )
                    }
                    title={singleData?.is_favorite ? "Unfollow" : "Follow"}
                  >
                    <i
                      className={`fa-solid fa-user-${
                        singleData?.is_favorite ? "minus" : "plus"
                      }`}
                    ></i>
                  </Link>
                )}
                <Link
                  variant="outline-primary"
                  className="btn btn-primary mb-2"
                  to={`/post/${singleData?.id}`}
                >
                  <i class="fa-solid fa-expand"></i>
                </Link>

                {(singleData?.customer_id === userId ||
                  userType === "SUPER_ADMIN") && (
                  <>
                    <Button
                      bg={singleData?.is_published ? "success" : "danger"}
                      onClick={() => handlePublishModalShow(singleData)}
                    >
                      {singleData?.is_published || singleData?.published ? (
                        <FaEyeSlash />
                      ) : (
                        <FaEye />
                      )}
                      {/* {singleData?.is_published || singleData?.published
                    ? "Unpublish"
                    : "Publish"} */}
                    </Button>
                    {singleData?.property_id != 0 &&
                    singleData?.property_id != null ? (
                      // <Link
                      //   className="btn btn-primary"
                      //   to={`property/${singleData?.property_id}`}
                      // >
                      //   View Property
                      // </Link>
                      <></>
                    ) : (
                      <div
                        className="btn btn-primary text-white"
                        onClick={() => {
                          addFormData(singleData?.id);
                        }}
                      >
                        <Link
                          className="text-white"
                          to={`/property/add-property`}
                        >
                          <FaPlus />
                        </Link>
                      </div>
                    )}
                    <Link
                      variant="outline-secondary"
                      className="btn btn-warning mb-2"
                      to={`/post/${singleData?.id}/edit`}
                    >
                      <i className="fa fa-edit"></i>
                    </Link>
                    <Link
                      variant="outline-danger"
                      className="btn btn-danger mb-2"
                      // to={`/property/${singleData?.id}/edit`}
                    >
                      <i
                        class="fa-solid fa-trash"
                        onClick={() => handleDeleteModalShow(singleData?.id)}
                      ></i>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default PropertyLikePostCard;
