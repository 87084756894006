import React from "react";
import { useSelector } from "react-redux";

const PartnerProtected = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  if (
    user &&
    user.userDetails &&
    user.statusType === "SUCCESS" &&
    user.userDetails.userType === "PARTNER" &&
    user.userDetails.roles.includes("ROLE_ADMIN")
  ) {
    return children;
  }

  return null;
};

export default PartnerProtected;
