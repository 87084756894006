import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  InputGroup,
  Row,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import { useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import parse from "html-react-parser";
import { FaEllipsisV, FaComment } from "react-icons/fa";

import PostComment from "./PostComment";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import TabMenu from "../homepage/TabMenu";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
const PostItem = () => {
  const { postId } = useParams();
  const [userDetail, setUserDetails] = useState();
  const [singleData, setSingleData] = useState();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const userType = loggedInUser?.userDetails?.userType;
  // const userId = loggedInUser?.userDetails?.id;
  const [assetUrl, setAssetUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const getDetailsApi = Api({
    url: `property/lead/${postId}`,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchDataBySingleId();
        console.log(responseData);
        setAssetUrl("https://images.allhomedesire.in/");
        setUserDetails(responseData?.data?.lead);
        setSingleData(responseData?.data?.lead);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Posts", link: "/post" },
    { label: "Post Detail", link: "", active: true },
  ];
  const handleClick = (id) => {
    secureLocalStorage.setItem("leadId", id);
  };
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/admin/deleteDesiredRequest/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        // setData(data.filter((item) => item.id !== itemId));
        showToast("Post deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Notification />
          <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
            {/* <BreadCrumb items={breadcrumbItems} /> */}
            <TabMenu />
            {/* <Row>
              <Col>
                <SingleDetail
                  label="Name"
                  userDetail={userDetail?.user?.first_name}
                />
              </Col>

              <Col>
                <SingleDetail label="Title" userDetail={userDetail?.title} />
              </Col>
              <Col>
                <SingleDetail
                  label="Purpose Name"
                  userDetail={userDetail?.purpose?.name}
                />
              </Col>
            </Row>

            <Row>
              <Col className={Style.col} md={6}>
                <label className={Style.label}>Description</label>
                <textarea
                  className={Style.textarea1}
                  style={{ padding: "1rem 2rem" }}
                >
                  {userDetail?.description}
                </textarea>
              </Col>
              <Col md={6} style={{ marginTop: "1.5rem" }}>
                {" "}
                <h4>All City</h4>
                {userDetail?.cities.map((singleCity) => (
                  <ListGroup>
                    <ListGroup.Item>{singleCity?.name}</ListGroup.Item>
                  </ListGroup>
                ))}
              </Col>
            </Row> */}

            <Row className="mainCard mt-3">
              <Col lg={12} md={12} sm={12}>
                <SingleDetailHeader
                  header="Post Detail"
                  description="See the post detail section"
                />
              </Col>

              <Col lg={12} md={12} sm={12} className="mainCardAreaInside">
                <div className="card">
                  <Row className="">
                    <Col
                      md="auto"
                      sm="auto"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <div className="profilePic">
                        <img src="https://plus.unsplash.com/premium_photo-1661964217492-70800dc09cac?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzM3x8fGVufDB8fHx8fA%3D%3D" />
                      </div>
                    </Col>
                    <Col md={10} sm={8}>
                      <Col md={12}>
                        <div className="cardTop">
                          <div className="nameSection">
                            <p>{singleData?.user_name}</p>
                          </div>
                          <div className="badgebootstrap">
                            {singleData?.purpose_name === "Buy" ? (
                              <Badge bg="secondary">
                                {singleData?.purpose_name}
                              </Badge>
                            ) : singleData?.purpose_name === "Sell" ? (
                              <Badge bg="secondary">
                                {singleData?.purpose_name}
                              </Badge>
                            ) : singleData?.purpose_name === "Rent" ? (
                              <Badge bg="secondary">
                                {singleData?.purpose_name}
                              </Badge>
                            ) : singleData?.purpose_name === "Investment" ? (
                              <Badge bg="secondary">
                                {singleData?.purpose_name}
                              </Badge>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="badgebootstrap">
                            <Badge bg="secondary">
                              {singleData?.type_name}
                            </Badge>
                          </div>
                          <div className="badgebootstrap">
                            <Badge bg="secondary">
                              {singleData?.sub_type_name}
                            </Badge>
                          </div>
                          <div className="timeAgo">
                            <p>
                              <TimeAgo timestamp={singleData?.created_on} />
                            </p>
                          </div>
                        </div>
                      </Col>

                      <Col md={12}>
                        <Row>
                          <Col md={4} sm={4} className="mt-2">
                            <div className="smallCard">
                              <p>State: {singleData?.state_name}</p>
                            </div>
                          </Col>
                          <Col md={4} sm={4} className="mt-2">
                            <div className="smallCard">
                              <p>City: {singleData?.city_name}</p>
                            </div>
                          </Col>
                          <Col className="mt-2">
                            <div className="smallCard">
                              <p>Area: {singleData?.area_name}</p>
                            </div>
                          </Col>
                          {/* {singleData?.areas?.map((singleArea) => (
                            <Col
                              md={4}
                              sm={6}
                              className="mt-2"
                              key={singleArea?.name}
                            >
                              <div className="smallCard">
                                <p>Area: {singleArea?.name}</p>
                              </div>
                            </Col>
                          ))} */}
                          <Col md={12} className="mt-3">
                            <h3> {singleData?.title}</h3>
                          </Col>
                          <Col md={12} className="mt-3">
                            <h3> ₹{singleData?.budget}</h3>
                          </Col>
                          <Col md={12}>
                            <div className="slider-container">
                              <Swiper
                                spaceBetween={50}
                                // slidesPerView={4}
                                breakpoints={{
                                  576: {
                                    width: 576,
                                    slidesPerView: 2,
                                  },
                                  768: {
                                    width: 768,
                                    slidesPerView: 3,
                                  },
                                  1100: {
                                    width: 768,
                                    slidesPerView: 3,
                                  },
                                }}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                              >
                                {singleData?.images?.map((singleImage) => (
                                  <SwiperSlide>
                                    {singleImage?.image_url?.includes(
                                      ".mp4"
                                    ) ? (
                                      <video
                                        autoPlay
                                        muted
                                        loop
                                        controls
                                        style={{
                                          width: "100%",
                                          height: "15rem",
                                          objectFit: "cover",
                                        }} // Optional styling for responsive videos
                                      >
                                        <source
                                          src={`${assetUrl}/${singleImage.image_url}`}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    ) : (
                                      <img
                                        src={`${assetUrl}/${singleImage.image_url}`}
                                        style={{
                                          height: "15rem",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                          objectFit: "cover",
                                          borderRadius: "0.35rem",
                                        }}
                                      />
                                    )}
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </Col>

                          <Col md={12} className="mt-3">
                            <p> {parse(`${singleData?.description}`)}</p>
                          </Col>
                          <Col md={12} className="mt-3">
                            <div className="comment-section">
                              {" "}
                              {/* New comment section */}
                              <Link to={`/post/${singleData?.id}`}>
                                <FaComment
                                  className="faComment"
                                  style={{
                                    backgroundColor: "#f5f5f5",
                                  }}
                                />
                                <span className="comment-count text-success mb-5">
                                  {singleData?.comment_count}
                                </span>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                    <Col md={1} sm={2}>
                      {singleData?.customer_id === userId ||
                        (userType === "SUPER_ADMIN" && (
                          <>
                            <div className="menu">
                              {/* <FaEllipsisV /> */}
                              <DropdownButton
                                id="dropdown-basic-button"
                                title={<FaEllipsisV />}
                                variant="Secondary"
                                style={{ color: "#fff" }}
                              >
                                {/* <Dropdown.Item>
                                      <Link to={`/post/${singleData?.id}`}>
                                        View
                                      </Link>
                                    </Dropdown.Item> */}
                                <Dropdown.Item href="#/action-2">
                                  <Link to={`/post/${singleData?.id}/edit`}>
                                    Edit
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link
                                    to={``}
                                    onClick={() => handleDelete(singleData?.id)}
                                  >
                                    Delete
                                  </Link>
                                </Dropdown.Item>
                                {singleData?.property_id != 0 &&
                                singleData?.property_id != null ? (
                                  <Dropdown.Item href="#/action-3">
                                    <Link
                                      to={`property/${singleData?.property_id}`}
                                    >
                                      View Property
                                    </Link>
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    onClick={() => handleClick(singleData?.id)}
                                  >
                                    <Link to={`/add-property`}>
                                      Add Property
                                    </Link>
                                  </Dropdown.Item>
                                )}
                              </DropdownButton>
                            </div>
                          </>
                        ))}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12}>
                <Row style={{ marginTop: "2rem" }}>
                  <div>
                    {/* <Button className={`btn btn-primary`}>Edit</Button> */}
                    {userType === "PARTNER" || userType === "CUSTOMER" ? (
                      <Link
                        className="btn btn-primary"
                        to={"/dashboard/myspace"}
                      >
                        Back
                      </Link>
                    ) : (
                      <Link
                        // style={{ marginLeft: "1rem" }}
                        className="btn btn-primary"
                        // to={"/post"}
                        to={"/dashboard/myspace"}
                      >
                        Back
                      </Link>
                    )}
                  </div>
                </Row>
              </Col>
              <Col lg={12} md={12} sm={12}>
                <div className="card mt-3 mb-3">
                  <Row className="">
                    <PostComment />
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default PostItem;
