import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import { Link } from "react-router-dom";

const Tr = ({
  columns,
  initialValues,
  title,
  buttonName,
  btnLink,
  titleHeader,
  btn,
  btnTwo,
  handleShow,
}) => {
  console.log(btnLink);
  return (
    <>
      <div
        className="card"
        // style={{
        //   zIndex: -1,
        // }}
      >
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title">{titleHeader}</h4>
          <div>
            {btnTwo && (
              <Link
                className="btn btn-primary"
                onClick={handleShow}
                style={{ marginRight: "2rem" }}
              >
                {btnTwo}
              </Link>
            )}
            {btn === false ? (
              <></>
            ) : (
              <Link className="btn btn-primary" to={btnLink}>
                {buttonName}
              </Link>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-responsive-md rimary-table-bordered">
              <thead>
                <tr className="table-sub-header">
                  {columns.map((column, index) => (
                    <th key={index}>{column.displayName}</th>
                  ))}
                  <th className="text-center">Actions</th>
                </tr>
              </thead>

              <tbody>
                <td colSpan={columns.length + 1}>
                  {" "}
                  {/* Use colSpan to span the whole row */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      zIndex: 1,
                    }}
                  >
                    <AllMessage title={title} />
                    {/* <Link to={btnLink} className="btn btn-primary mb-5">
                      {buttonName}
                    </Link> */}
                  </div>
                </td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tr;
