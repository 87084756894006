import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

const BreadCrumb = ({ items }) => {
  return (
    <Breadcrumb>
      {items?.map((item, index) => (
        <Breadcrumb.Item
          key={index}
          active={item.active}
          linkAs={item.active ? "span" : Link}
          linkProps={item.active ? {} : { to: item.link }}
        >
          <Link to={item.link}>
            {" "}
            {item.icon && <i className={`${item.icon} mr-1`}></i>}
            {item.label}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumb;
