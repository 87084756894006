import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import PropertyImg4 from "../assets/images/property-4.jpg";

function About() {
  return (
    <>
      <section className="page-header parallaxie">
        <Container>
          <Row>
            <Col md={12}>
              <div className="page-header-box">
                <h1 className="text-anime">About Us</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link href="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About us
                    </li>
                  </ol>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="why-choose-us">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} orderLg={2}>
              <div className="why-choose-us-image">
                <figure className="reveal image-anime">
                  <img src={PropertyImg4} alt="" />
                </figure>
              </div>
            </Col>
            <Col lg={6} orderLg={1}>
              <div className="why-choose-us-content">
                <div className="section-title">
                  {/* <h3 className="wow fadeInUp">All Home Desire</h3> */}
                  <h2 className="text-anime">All Home Desire</h2>
                </div>

                <div className="why-choose-us-body">
                  <p>
                    Exceptional Real Estate and Interior Design Solutions from
                    All Home Desire All Home Desire is a reputable real estate
                    platform that specializes in providing customers the latest
                    construction and property deal information in India. We also
                    offer satisfying interior design services at reasonable
                    costs.
                  </p>
                  <p>
                    Our well-equipped properties are surrounded by international
                    schools, convenient transportation, shopping centers, and
                    gardens, catering to all your needs. We assist with property
                    research, loans, investments, and EMI calculations. Staying
                    ahead of trends, we help customers design their spaces with
                    cutting-edge interior solutions tailored to their
                    preferences.
                  </p>
                  <p>
                    Whether you're buying, selling, or renting, our
                    comprehensive real estate platform and expert team ensure a
                    seamless experience in India.
                  </p>

                  {/* <Button href="#" className="btn-default">
                    Read More
                  </Button> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-section">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <div className="card card-pro p-4">
                <div className="card-body">
                  <div className="card-icon">
                    <i class="fa fa-bullseye" aria-hidden="true"></i>
                  </div>
                  <h2 className="card-title">Mission</h2>
                  <p className="card-text fs-sm">
                    Our mission is to guide you to real estate success by
                    exceeding expectations and delivering exceptional service,
                    turning your dream property into a happy reality.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="card card-pro p-4">
                <div className="card-body">
                  <div className="card-icon">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </div>
                  <h2 className="card-title">Vision</h2>
                  <p className="card-text fs-sm">
                    Empowering Dream Homes, Globally.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="how-it-works">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                {/* <h3>HOW IT WORKS</h3> */}
                <h2 className="text-anime">Why Choose Us?</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={PropertyHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Find Property</h3> */}
                <p>
                  Comprehensive Real Estate Solutions to Fulfill Your Desires
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={RetailerHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Meet Retailer</h3> */}
                <p>Affordable Services Without Compromising Quality.</p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={DocumentsHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Documents</h3> */}
                <p>
                  Personalized Guidance from Dedicated Real Estate Consultants.
                </p>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="how-it-work-item item-work">
                <div className="icon-box">
                  <i class="fa fa-hashtag" aria-hidden="true"></i>

                  {/* <img src={keysHomeIcon} alt="" /> */}
                </div>

                {/* <h3>Take the keys</h3> */}
                <p>Commitment to Protecting Your Private Data.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default About;
