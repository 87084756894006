import React from "react";
import { StyledTypeCard } from "./Style";
import Button from "../../../common/button/Button";
import { Link } from "react-router-dom";

const TypeCard = ({ title, description, imageUrl, btnText }) => (
  <StyledTypeCard>
    <Link to="#" className="card card-pro">
      <img src={imageUrl} alt="" className="d-block mx-auto my-3" />
      <div className="card-body">
        <h2 className="card-title">{title}</h2>
        <p className="card-text fs-sm">{description}</p>
      </div>
      <div className="card-footer pt-0 border-0">
       
          <Link     className="btn btn-outline-primary stretched-link" to={'/login'}>
          {btnText}
          </Link>
    
      </div>
    </Link>
  </StyledTypeCard>
);

export default TypeCard;
