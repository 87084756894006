import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert, Tab, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import Api from "../../components/api/Api";
import Style from "../../UserStyle.module.css";
import Spinner from "react-bootstrap/Spinner";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import Profile from "./Profile";
import { logout } from "../../../features/auth/authActions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

const UserProfile = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const id = loggedInUser?.userDetails?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path
  const [userDetail, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;

  const logoutHandler = async () => {
    try {
      dispatch(logout());
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const getDetailsApi = Api({
    url: `admin/user/id/${userId}`,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData();

        setUserDetails(responseData?.data?.user);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div>
            <div className="all-section" style={{ padding: "0 0" }}>
              <Container>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="profile"
                >
                  <Row>
                    <Col
                      md={12}
                      sm={12}
                      className="sidebar widget widget-dashboard mb-lg-0"
                      style={{ backgroundColor: "white", color: "white" }}
                    >
                      {/* <h2 className="text-uppercase">Profile</h2> */}
                      <Nav
                        variant="pills"
                        className="nav nav-tabs list flex-column mb-0"
                      >
                        <Nav.Item>
                          <Nav.Link
                            as={Link}
                            to="/user/profile"
                            className={
                              location.pathname === "/user/profile"
                                ? "active"
                                : ""
                            }
                          >
                            Profile
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            as={Link}
                            to="/user/edit-profile"
                            className={
                              location.pathname === "/user/edit-profile"
                                ? "active"
                                : ""
                            }
                          >
                            Edit Profile
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            as={Link}
                            to={`/changepassword/${id}`}
                            className={
                              location.pathname === `/user/${id}/changepassword`
                                ? "active"
                                : ""
                            }
                          >
                            Change Password
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            onClick={logoutHandler}
                            style={{ cursor: "pointer" }}
                          >
                            Logout
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                </Tab.Container>
              </Container>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserProfile;
