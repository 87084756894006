import React, { useState, useEffect } from "react";
import Api from "../../../admin/components/api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../../admin/components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../../admin/components/breadcrumb/BreadCrumb";
import AdminHeader from "../../../admin/common/Header";

const AddNetworkManagement = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const addPostApi = Api({
    url: `user/network/add`,
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.mobile_number,
        email_address: values.email_address,
        address: "",
        address1: "",
        street: "",
        apartment: "",
        country_name: "",
        state_name: "",
        city_name: "",
        zip_code: "",
        area_name: "",
      };

      const responseData = await addPostApi.postData(data);
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Network added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/network");
        }, 3000);
      }
    } catch (error) {
      setIsError(true);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Network Management", link: "/network", active: true },
    { label: "Add Network", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Add Network"
              description="Add the network details section"
            />
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <label htmlFor="first_name" className="form-label">
                          First Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="first_name"
                          aria-describedby="emailHelp"
                          placeholder="Enter First Name"
                          name="first_name"
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>

                    <Col>
                      <div className="mb-3">
                        <label htmlFor="last_name" className="form-label">
                          Last Name
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="last_name"
                          aria-describedby="emailHelp"
                          placeholder="Enter Last Name"
                          name="last_name"
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email address
                        </label>
                        <Field
                          type="email"
                          className="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          name="email_address"
                        />
                        <ErrorMessage
                          name="email_address"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <label htmlFor="mobile_number" className="form-label">
                          Mobile Number
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="mobile_number"
                          aria-describedby="emailHelp"
                          placeholder="Enter Phone Number"
                          name="mobile_number"
                        />
                        <ErrorMessage
                          name="mobile_number"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  <button
                    type="submit"
                    className="btn btn-primary mt-5"
                    style={{ marginBottom: "10rem" }}
                    disabled={isSubmitting}
                    // disabled={true}
                  >
                    {isSubmitting ? <Spinner loading={true} /> : "Add Network "}
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddNetworkManagement;
